//Native package
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";


//amplify package(for using auth only)
//import { withAuthenticator } from '@aws-amplify/ui-react';
import { Amplify} from 'aws-amplify';
import {currentConfig} from './amplifyConfig';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

//import awsExports from './aws-exports';
//Amplify.configure(awsExports);

//相対パッケージ
//開発用オンリー　最終的には消す
import { Page1 } from './pages/Page1';
import { Page2 } from './pages/Page2';
import { PageApiExplain } from './pages/PageApiExplain';
import { PagePlayground } from './pages/PagePlayground';
import { PageHatakeyama } from './pages/PageHatakeyama'; 
import { PagePlayground_Shiozawa } from './pages/PagePlayground_Shiozawa';
import {FileUploaderPage} from './pages/FileUploaderPage';
import {PageMyPlayground_shota} from './pages/PageMyPlayground_shota';

//実際に使用
//楽譜関係
import {PageTop} from './pages/PageTop';
import {PageMusicRegistration} from './pages/PageMusicRegistration';
import {PageMusicEdit} from './pages/PageMusicEdit';
import {PageScoreList} from './pages/PageScoreList';
import {PageScoreDetail} from './pages/PageScoreDetail';

//バンド関係
import { PageBandAdmin } from './pages/PageBandAdmin';
import { PageBandEdit } from './pages/PageBandEdit';
import { PageBandCreate } from './pages/PageBandCreate';

//個人関係
import { PageMypage } from './pages/PageMypage';

//その他
import { PageNotFound } from './pages/PageNotFound';
import { PageReleaseNote} from './pages/PageRelaseNote';

//Auth
Amplify.configure(currentConfig);


function App({ signOut, user }) {
  return (
    <Authenticator hideSignUp={true}>
      {({ signOut, user }) => ( //signOut, userはAuthenticatorから提供されるやつ
        <BrowserRouter>
          <Routes>
            {/*アルファでコメントアウトする */}
            {/*
            <Route path="/shota" element={<PageMyPlayground_shota/>} />
            <Route path="/playground" element={<PagePlayground user={user} signOut={signOut}/>} />
            <Route path="/shiozawa" element={<PagePlayground_Shiozawa user={user} signOut={signOut}/>} />
            <Route path="/api_explain" element={<PageApiExplain/>} />
            <Route path="/page2" element={<Page2/> }/>
            <Route path="/htk" element={<PageHatakeyama/> }/>
            <Route path="/fileuploadtest" element={<FileUploaderPage />} />
            */}
            {/* トップページのためのルーティング */}
            <Route path="/" element={<PageTop/>} />
            <Route path="/home" element={<PageTop/>} />

            {/*楽譜登録のルーティング*/}
            <Route path="/score_registration" element={<PageMusicRegistration/>} />
            {/*楽譜編集のルーティング */}
            {/*<Route path="/score_edit" element={<PageMusicEdit/>} /> */}
            <Route path="/score_edit/:score_id" element={<PageMusicEdit/>} />
            {/*楽譜一覧ページのルーティング*/}
            <Route path="/search" element={<PageScoreList/>} />

            {/*楽譜詳細ページのルーティング*/}
            <Route path="/score/:score_id" element={<PageScoreDetail/>}/>

            {/*バンド管理ページのルーティング*/}
            <Route path="/band_admin" element={<PageBandAdmin/>}/>
            <Route path="/band_edit" element={<PageBandEdit/>}/>
            <Route path="/band_create" element={<PageBandCreate/>}/>

            {/*設定ページへのルーティング */}
            <Route path="/mypage" element={<PageMypage/>}/>

            {/*リリースノートページへのルーティング */}
            <Route path="/release_note" element={<PageReleaseNote/>}/>

            <Route path="/not_found" element={<PageNotFound />} />
            {/* マッチしないすべてのURLに対して404ページを表示 */}
            <Route path="*" element={<PageNotFound />} />

          </Routes>
      </BrowserRouter>
      )}
    </Authenticator>
    
  );
}
export default App;
