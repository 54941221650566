import React, { useState, useEffect } from 'react';
import styles from './DropDown.module.css'; // CSSモジュールのインポート

const DropDownForBandList = ({ selectKey, defaultValue, banditems, onChange}) => {
  const [selectedValue, setSelectedValue] = useState("");
  useEffect(() => {
    //console.log("defaultValue changed:",defaultValue);
    setSelectedValue(defaultValue || "");
  }, [defaultValue]);  // defaultValueが変わった時にのみ効果を再実行
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <select
      key={selectKey}
      className={styles.dropDown}
      value={selectedValue}
      onChange={handleChange}
    >
      <option value="">
        バンドを選択
      </option>
      {banditems.sort((a,b)=>a.bandname?.S.localeCompare(b.bandname?.S)).map((item) => (
        <option key={item.band_id?.S} value={item.band_id?.S}>
          {item.bandname?.S}
        </option>
      ))}
    </select>
  );
};

export default DropDownForBandList;