import React from 'react';
import Button from '../components/Utils/Button/Button';

import Header from '../components/Utils/CommonHeader/CommonHeader'
import Dev from '../components/MusicRegistration/Dev';

export function PageMusicRegistration() {

    return (
        <div>
            <header>
                <Header/>
            </header>
            <main>
            
                <h1>楽譜登録(新規)
                </h1>
                <Dev/>
            </main>
        </div>
        
    );
}

export default PageMusicRegistration;