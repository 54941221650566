import React, { useState, useEffect } from 'react';
import styles from './CheckBoxs.module.css'; // CSSモジュールのインポート

const CheckBoxs = ({ options, onChange }) => {
  const [checkedItems, setCheckedItems] = useState(
    options.reduce((acc, option) => {
      acc[option.value] = option.isChecked || false; // isChecked を使用して初期値を設定
      return acc;
    }, {})
  );

  const handleCheckboxChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.value]: event.target.checked
    });

    if (onChange) {
      onChange({
        ...checkedItems,
        [event.target.value]: event.target.checked
      });
    }
  };

  useEffect(()=>{
    setCheckedItems(options.reduce((acc, option) => {
      acc[option.value] = option.isChecked || false; // isChecked を使用して初期値を設定
      return acc;
    }, {}));
  },[options]);

  return (
    <div className={styles.checkBoxesContainer}>
      {options.map((option, index) => (
        <label key={index} className={styles.checkBoxLabel}>
          <input
            type="checkbox"
            value={option.value}
            checked={checkedItems[option.value]}
            onChange={handleCheckboxChange}
            className={styles.checkBox}
          />
          {option.label}
        </label>
      ))}
    </div>
  );
};

export default CheckBoxs;
