import React from 'react';
import styles from './Button.module.css';

function Button({type, child, onClick, disabled=false}) {
    const style = styles.console.error;
    return (
        <button 
            className = {`
                ${styles.button}
                ${type === "primary" && styles.primary}
                ${type === "primary2" && styles.primary2}
                ${type === "primary3" && styles.primary3}
                ${type === "normal" && styles.normal}
                ${type === "console.error" && styles.console.error}
                ${(type === "disabled" || disabled ) && styles.disabled}`} 
            disabled={disabled}
            onClick={onClick}
        >
            {child}
        </button>
    );
}

export default Button;