import React, { useState } from 'react';
import styles from './CommentCard.module.css'; // CSSモジュールのインポート

const CommentCard = ({ comment}) => {
      //時刻表示変換(ごり押し、二度手間？)
      const date = new Date(comment.createdAt); 
      date.setHours(date.getHours() - 9);
    return (
    <div className={styles.card}>
        <div className={styles.header}>
            <h3>{comment.posted_user_name}</h3>
            <span>{date.toLocaleString()}</span>
        </div>
        <p className={styles.comment}>{comment.content}</p>
    </div>
    );
};

export default CommentCard;