import React from 'react';
import styles from './PageTop.module.css'; //流用
import CommonHeader from '../components/Utils/CommonHeader/CommonHeader';
const API_PREFIX = process.env.REACT_APP_API_PREFIX;

export const PageReleaseNote = () => {
    return (
        <div className={styles.basic}>
            <CommonHeader key="pageTop" />
            <main>
                <h1>リリースノート</h1>
                    <section>
                        <h2>バージョン 1.0.0 ~ 1.0.3</h2>
                        <p>2024年9月26日</p>
                        <ul>
                            <li>リリース前の不具合修正</li>
                        </ul>
                    </section>
                    <section>
                        <h2>バージョン 1.0.4 （初版リリース）</h2>
                        <p>2024年9月28日</p>
                        <ul>
                            <li>うったべ2024にて公開</li>
                        </ul>
                    </section>
                    
                    <section>
                        <h2>バージョン 1.0.5 (バグ修正)</h2>
                        <p>2024年10月1日</p>
                        <ul>
                            <li>不具合修正</li>
                            <ul>
                                <li>限定公開の楽譜がバンドメンバーに公開されない問題を修正</li>
                                <li>バンドメンバーに楽譜の編集権限がない問題を修正</li>
                                <li>ファイル差し替えで作成されるバージョンアップの楽譜が編集できなくなることがある問題を修正</li>
                                <li>バンドメンバー編集時にチェックボックスを外してないのにメンバーが消えてしまうことがある問題を修正</li>
                                <li>ダウンロードされるpdfがページビューではなく連続ビューのものになってしまうことがある問題を修正</li>
                                <li>アレンジャーを未登録に変更することができない問題を修正</li>
                                <li>一部UIの調整</li>
                            </ul>
                            <li>その他</li>
                            <ul>
                                <li>ホームページのブックマークを登録日時が新しい順に並ぶようにしました</li>
                                <li>タグの最大文字数を設定しました（20文字）</li>
                            </ul>
                        </ul>
                    </section>
                    <section>
                        <h2>バージョン 1.1.0 (機能拡張など)</h2>
                        <p>2024年10月8日</p>
                        <ul>
                            <li>機能拡張</li>
                            <ul>
                                <li>曲名やアーティスト名で検索する際に表記揺れしていても検索できるようにしました</li>
                                    <ul>
                                        <li>boyと検索してBOYを検索できたり、青空のラプソティで青空のラプソディを検索できます</li>
                                    </ul>
                                <li>検索結果を表示するまでの応答速度を改善しました</li>
                            </ul>
                            <li>不具合修正</li>
                            <ul>
                                <li>楽譜の登録、編集時のバンド選択時にソートされていなかったのを修正</li>
                            </ul>
                            <li>その他</li>
                            <ul>
                                <li>説明文やコメントなどに最大文字数を設定しました</li>
                                <li>楽譜登録に失敗したときのメッセージを追記しました</li>
                                <li>検索結果の並び替えプルダウンのデザインを修正しました</li>
                            </ul>
                        </ul>
                    </section>
            </main>
        </div>
    )

}

export default PageReleaseNote;
