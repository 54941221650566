import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
//import Loading from '../components/Utils/Loading/Loading';
//import ScoreCard from '../components/Utils/ScoreCard/ScoreCard';
import CommonHeader from '../components/Utils/CommonHeader/CommonHeader';
import Button from '../components/Utils/Button/Button';
//import DropDown from '../components/Utils/Ui/DropDown/DropDown';
import DropDownForUserList from '../components/Utils/Ui/DropDown/DropDownForUserList';
import TextInput from '../components/Utils/Ui/TextInput/TextInput';

import styles from './PageBandAdmin.module.css'

import { useCallback } from 'react';
import { useNavigate } from 'react-router';
//import { MouseEvent } from 'react';
import { Auth } from 'aws-amplify';
import { BsChevronRight } from "react-icons/bs";

const API_PREFIX = process.env.REACT_APP_API_PREFIX;



export const PageBandAdmin = (props) => {
	//userID取得
	//cognitoID用
	const [userId, setUserId] = useState('');
	const [loading, setLoading] = useState(false);
	const [myCognito, setMyCognito] = useState('init');
	const [userItems, setUseritems] = useState([]);

	const fetchUserId = async () => {
	  setLoading(true);
	  const requestOptions = {
		method: 'GET',
	  };
	  try {
		// ログイン中のユーザー情報を取得
		const currentUserInfo = await Auth.currentAuthenticatedUser();
		const cognitoId = currentUserInfo.signInUserSession.idToken.payload.sub;
		setMyCognito(cognitoId);
  
		// APIエンドポイントにクエリパラメータとしてcognitoidを付けてリクエストを送る
		const response = await fetch(`${API_PREFIX}/api/user/whoami/${cognitoId}`);
		if (!response.ok) {
		  throw new Error('Failed to fetch user ID');
		}
		const data = await response.json();
		setUserId(data.userId);
		const response_useritems = await fetch(`${API_PREFIX}/api/user/${data.userId}`, requestOptions);
		if (response_useritems.status === 200) {
		  const data_useritems = await response_useritems.json(); // APIからのレスポンスデータをJSONとしてパース
		  setUseritems(data_useritems); // パースしたデータを状態にセット
		  //console.log(data_useritems);
		} else {
			console.error('Failed to get item.');
		}
	  } catch (error) {
		console.error('Error fetching user ID:', error);
		setUserId('Error fetching user ID');
		return false;
	  } finally {
		setLoading(false);
		return true;
	  }
	};

	//所属バンド情報取得
	const [banditems, setBanditems] = useState([]);

	const getBandList = async () => {
		const requestOptions = {
			method: 'GET',
		};
		try {
			//const response = await fetch('http://localhost:3001/api/band/bandlist', requestOptions);
			const API_PREFIX = process.env.REACT_APP_API_PREFIX;
			const response = await fetch(`${API_PREFIX}/api/band/bandlist`, requestOptions);
			if (response.status === 200) {
				const data = await response.json(); // APIからのレスポンスデータをJSONとしてパース
				setBanditems(data); // パースしたデータを状態にセット
				//console.error('band Item successfully got.');
			} else {
				console.error('Failed to get band item.');
			}
		} catch (error) {
			console.error('There was an error!', error);
		}
		return;
	}

	//モーダル用
	const [isModalState, setIsModalState] = useState(false);
	const handleYesClick = () => {
        // 「はい」が選択されたときの処理をここに記述する
        //console.log('はいが選択されました');
        // 例えば、何らかの処理を実行したり、モーダルを閉じたりする
        setIsModalState(false);
    };

	//編集ページ遷移用
	const navigate = useNavigate();
	const onClickToLinkEdit = useCallback((e, item) => {
		e.preventDefault();
		//navigate("/band_edit/"+bandId)
		navigate("/band_edit", { state: { banditem: item } })
	}, [navigate])
	const onClickToLinkCreate = useCallback((e) => {
		e.preventDefault();
		//navigate("/band_edit/"+bandId)
		navigate("/band_create");
	}, [navigate])

	useEffect(() => {
		//getUserList();
		fetchUserId();
		getBandList();
		//console.log(userItems);
		//console.log(selectedScoreMetadata);
	}, []);

	// バンドソート用
	const userBelongsToBand = (banditem) => {
		if (banditem.bandmembers.SS.includes(userId) || banditem.bandmaster.S == userId){
			return 1
		} else {
			return 0
		}
	}

	return (
		<div className={styles.page}>
			{isModalState && (
                <div className={styles.modal}>
                    <div className={styles.modal_content}>
                        <h2>確認</h2>
                        <p>このバンドは編集できません</p>
                        <button onClick={handleYesClick}>はい</button>
                    </div>
                </div>

            )}
			<CommonHeader />
			<main>
				<div>
					<h2 className={styles.pageTitle}>バンド管理ページ</h2>
					{/*<p>nickname:{userName},id:{userId}</p>*/}
					<div className={styles.container}>
					<div className={styles.bandListWrapper}>
						<ul className={styles.bandList}>
							{banditems.sort((a,b) => {
									if(userBelongsToBand(a) == userBelongsToBand(b)){
										return a.bandname.S.localeCompare(b.bandname.S, 'ja');
									}else{
										return userBelongsToBand(b) - userBelongsToBand(a)
									}	
								}).map((item) => (
									<li key={item.band_id.S} className={styles.band}>
										<button onClick={(e) => onClickToLinkEdit(e, item)}>
											<div className={styles.listIcon}><BsChevronRight/></div>
											　
											<div className={styles.bandName}>{userBelongsToBand(item) ? '*' + item.bandname.S : item.bandname.S}</div>

										</button>
									</li>
								))}
						</ul>
					</div>
					</div>
					<button className={styles.floatingButton} onClick={(e) => onClickToLinkCreate(e)}>+ new band</button>
				</div>
			</main>


		</div>
	)

}

export default PageBandAdmin;
