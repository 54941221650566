import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router";

import CommonHeader from '../components/Utils/CommonHeader/CommonHeader';

import Button from '../components/Utils/Button/Button';
import TextInput from '../components/Utils/Ui/TextInput/TextInput';
import DropDownForUserList from '../components/Utils/Ui/DropDown/DropDownForUserList';

import styles from './PageBandEdit.module.css';

import { Auth } from 'aws-amplify';
const API_PREFIX = process.env.REACT_APP_API_PREFIX;

export const PageBandEdit = () => {
    const location = useLocation();
    const banditem = location.state.banditem;
    const navigate = useNavigate();

    //所属バンド情報取得
	const [banditems, setBanditems] = useState([]);

	const getBandList = async () => {
		const requestOptions = {
			method: 'GET',
		};
		try {
            const API_PREFIX = process.env.REACT_APP_API_PREFIX;
			const response = await fetch(`${API_PREFIX}/api/band/bandlist`, requestOptions);
			if (response.status === 200) {
				const data = await response.json(); // APIからのレスポンスデータをJSONとしてパース
				setBanditems(data); // パースしたデータを状態にセット
				//console.error('band Item successfully got.');
			} else {
				console.error('Failed to get band item.');
			}
		} catch (error) {
			console.error('There was an error!', error);
		}
		return;
	}

    const [bandname, setBandname] = useState(banditem.bandname.S)
    const [bandmaster, setBandmaster] = useState(banditem.bandmaster.S);
    const [bandmembers, setBandmembers] = useState(banditem.bandmembers.SS);

    const BandMasterChange = (value) => {
        const bandmembers_cp = bandmembers;
        if(!bandmembers_cp.includes(bandmaster) && bandmaster.length > 0){
            bandmembers_cp.push(bandmaster)
        }
        const bandmembers_cp2 = bandmembers_cp.filter(member => (member.length > 0) && (member!==value));
        //console.log(bandmembers_cp2,value);
        setBandmaster(value);
        setBandmembers(bandmembers_cp2);
    }
    const [isModalState, setIsModalState] = useState(false);

    const deleteBand = async () => {
        const requestOptions = {
            method: 'DELETE',
        };
        try {
            const response = await fetch(`${API_PREFIX}/api/band/${banditem.band_id.S}`, requestOptions);
            {/*if (response.status === 200) {
                //console.log("Successfully deleted");
            } else {
                console.error('Failed to delete item.');
            }*/}
            if (response.status !== 200) {
                console.error('Failed to delete item.');
            }
        } catch (error) {
            console.error('There was an error!', error);
        }
    }

    const [isCannotUpdateBandModalState, setIsCannotUpdateBandModalState] = useState(false);
    const handleClick = () => {
        if(!(banditem.bandmembers.SS.includes(userId) || banditem.bandmaster.S===userId || userItem.user_level === 2 )){
            setIsCannotUpdateBandModalState(true);
        }
    }
    const updateBand = async () => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ bandname: bandname, bandmaster: bandmaster, bandmembers: bandmembers })
        };
        try {
            if(!(banditem.bandmembers.SS.includes(userId) || banditem.bandmaster.S===userId || userItem.user_level === 2 )){
                setIsCannotUpdateBandModalState(true);
            }else{
            const response = await fetch(`${API_PREFIX}/api/band/${banditem.band_id.S}`, requestOptions);
            if (response.status === 200) {
                console.error('Successfully updated band');
                navigate('/band_admin');
            } else {
                console.error('Failed to update banditem.');
            }}
        } catch (error) {
            console.error('There was an error!', error);
        }
    }

    //checkbox用
    const getNewMembers = (userId, checked) => {
        if (checked) {
            return [...bandmembers.filter(id => id !== userId), userId]
        } else {
            if(bandmaster === userId){
                setBandmaster("");
            }
            return bandmembers.filter(id => id !== userId)
        }
    }
    const handleCheckboxChange = (userId, checked) => {
        // checkboxが変更されたときの処理をここに追加する
        const newBandMembers = getNewMembers(userId, checked);
        //console.log(newBandMembers, checked);
        setBandmembers(newBandMembers);
        //console.log(bandmaster,bandmembers);
    };

    const [useritems, setUseritems] = useState([]);
    const [userkiList, setUserkiList] = useState([]);

    const getUserList = async () => {
        const requestOptions = {
            method: 'GET',
        };
        try {
            const response = await fetch(`${API_PREFIX}/api/user/userlist`, requestOptions);
            if (response.status === 200) {
                const data = await response.json(); // APIからのレスポンスデータをJSONとしてパース
                setUseritems(data); // パースしたデータを状態にセット
                //console.error('Item successfully got.');
                data.map((item)=>{
					if(!userkiList.includes(item.ki.N)){
						userkiList.push(item.ki.N);
					}
				})
				setUserkiList(userkiList.sort((a,b)=>Number(a)-Number(b)))
            } else {
                console.error('Failed to get user item.');
            }
        } catch (error) {
            console.error('There was an error!', error);
        }
        return;
    }

    //userID取得
	//cognitoID用
	const [userId, setUserId] = useState('');
	const [loading, setLoading] = useState(false);
	const [myCognito, setMyCognito] = useState('init');
	const [userItem, setUseritem] = useState([]);

	const fetchUserId = async () => {
	  setLoading(true);
	  const requestOptions = {
		method: 'GET',
	  };
	  try {
		// ログイン中のユーザー情報を取得
		const currentUserInfo = await Auth.currentAuthenticatedUser();
		const cognitoId = currentUserInfo.signInUserSession.idToken.payload.sub;
		setMyCognito(cognitoId);
  
		// APIエンドポイントにクエリパラメータとしてcognitoidを付けてリクエストを送る
		const response = await fetch(`${API_PREFIX}/api/user/whoami/${cognitoId}`);
		if (!response.ok) {
		  throw new Error('Failed to fetch user ID');
		}
		const data = await response.json();
        //console.log(data);
		setUserId(data.userId);
		const response_useritem = await fetch(`${API_PREFIX}/api/user/${data.userId}`, requestOptions);
		if (response_useritem.status === 200) {
		  const data_useritem = await response_useritem.json(); // APIからのレスポンスデータをJSONとしてパース
		  setUseritem(data_useritem); // パースしたデータを状態にセット
		  //console.log(data_useritem);
		} else {
			console.error('Failed to get item.');
		}
	  } catch (error) {
		console.error('Error fetching user ID:', error);
		setUserId('Error fetching user ID');
		return false;
	  } finally {
		setLoading(false);
		return true;
	  }
	};


    //管理ページ遷移用

    const handleToBandAdmin = () => {
        navigate("/band_admin");
    }

    const handleYesClick = () => {
        // 「はい」が選択されたときの処理をここに記述する
        //console.log('はいが選択されました');
        // 例えば、何らかの処理を実行したり、モーダルを閉じたりする
        deleteBand();
        setIsModalState(false);
        navigate("/band_admin");
    };

    const handleNoClick = () => {
        // 「いいえ」が選択されたときの処理をここに記述する
        //console.log('いいえが選択されました');
        // 例えば、モーダルを閉じる
        setIsModalState(false);
    };


    useEffect(() => {
        //getBandInfo();
        fetchUserId();
        //console.log(userId);
        //console.log(banditem);
        getUserList();
        getBandList();
    }, []);

    const [bandmemberNum, setbandmemberNum] = useState(0);
    useEffect(()=>{
        if(bandmaster==="" || bandmembers.includes(bandmaster)){
            setbandmemberNum(bandmembers.length);
        }else{
            setbandmemberNum(bandmembers.length+1);
        }
    }, [bandmaster,bandmembers]);

    return (
        <div>
            {isModalState && (
                <div className={styles.modal}>
                    <div className={styles.modal_content}>
                        <h2>確認</h2>
                        <p>この操作を実行しますか？</p>
                        <button onClick={handleYesClick}>はい</button>
                        <button onClick={handleNoClick}>いいえ</button>
                    </div>
                </div>

            )}
            {isCannotUpdateBandModalState && (
                <div className={styles.modal}>
                    <div className={styles.modal_content}>
                        <h2>注意</h2>
                        <p>あなたはこのバンドの情報を更新することができません。</p>
                        <button onClick={() => setIsCannotUpdateBandModalState(false)}>OK</button>
                    </div>
                </div>

            )}
            <CommonHeader />
            <main>
                <div className={styles.container}>
                <div>
                    <Button type="normal" child="バンド管理ページに戻る" onClick={handleToBandAdmin}/>
                    <h2>バンド編集ページ</h2>
                    <div>
                        <h3>バンド名</h3>
                        <TextInput
                            placeholder="バンド名を入力してください"
                            value={bandname}
                            onChange={(e) => setBandname(e.target.value)}
                            onClick={(e) => handleClick()}
                            useAPI=""
                            suggestions={[]}
                            width="M"
                            disabled={!(bandmembers.includes(userId) || bandmaster===userId || userItem.user_level === 2 )}
                            maxLength={50}
                        />
                        {banditems.filter((item) => item.bandname.S===bandname & item.band_id.S !== banditem.band_id.S).length>0? <p className={styles.bandname_console.error}>このバンド名は既に使われています</p> : <p className={styles.bandname_console.error}></p>}
                    </div>

                    <br></br>

                    <div>
                        <h3>バンドメンバー登録({bandmemberNum}人)</h3>
							<div>
								{userkiList.map((ki)=>(
									<div key={ki}>
									    <h3>{ki}期</h3>
										{useritems.filter((user) => user.ki.N === ki).sort((a,b) => a.nickname.S.localeCompare(b.nickname.S, 'ja')).map((user) => (
												<div key={user.user_id.S}>
													<input
														key={user.user_id.S}
														type="checkbox"
														id={user.user_id.S}
                                                        checked={bandmembers.includes(user.user_id.S) || bandmaster===user.user_id.S}
														onChange={(e) => handleCheckboxChange(user.user_id.S, e.target.checked)}
                                                        disabled={!(banditem.bandmembers.SS.includes(userId) || banditem.bandmaster.S===userId || userItem.user_level === 2 )}
													/>
													<label htmlFor={user.user_id.S}>{user.nickname.S}</label>
												</div>
											)
										)}
									</div>
									)
								)}
							</div>
                    </div>

                    <br></br>
                    <div>
                        <h3>バンドマスター登録</h3>
                        <DropDownForUserList selectKey={0} defaultValue={banditem.bandmaster.S} useritems={useritems.filter(item => bandmembers.includes(item.user_id.S) || item.user_id.S === bandmaster)} onChange={BandMasterChange} disabled={!(banditem.bandmembers.SS.includes(userId) || banditem.bandmaster.S===userId || userItem.user_level === 2 )}/>
                        <br></br>
                    </div>
                    <Button type="primary3" child="バンド更新" onClick={updateBand}/>
                    
                </div>
                </div>
            </main>


        </div>
    )

}

export default PageBandEdit;