import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import Header from '../components/Utils/CommonHeader/CommonHeader';
import Loading from '../components/Utils/Loading/Loading';
import ScoreCard from '../components/Utils/ScoreCard/ScoreCard';
import { Link } from 'react-router-dom';
import styles from './PageMypage.module.css';

const API_PREFIX = process.env.REACT_APP_API_PREFIX;

export const PageMypage = () => {
  const [amIadmin, setAmIadmin] = useState(false);
  const [userId, setUserId] = useState('');
  const [listedBookmarkIds, setListedBookmarkIds] = useState([]);
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // ログイン中のユーザー情報を取得
        const currentUserInfo = await Auth.currentAuthenticatedUser();
        const cognitoId = currentUserInfo.signInUserSession.idToken.payload.sub;

        // Cognito IDからユーザーIDを取得
        const userIdResponse = await axios.get(`${API_PREFIX}/api/user/whoami/${cognitoId}`);
        const userId = userIdResponse.data.userId;
        setUserId(userId);
        // ユーザーIDからユーザー情報を取得
        const userInfoResponse = await axios.get(`${API_PREFIX}/api/user/${userId}`);
        const userLevel = userInfoResponse.data.user_level?.N;

        // user_levelが2なら管理者として設定
        if (userLevel === '2') {
          setAmIadmin(true);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  /*ユーザー関連*/
  //Create
  const [userNickname, setUserNickname] = useState("");
  const [userKi, setUserKi] = useState(-1);

  const createUser = async () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ nickname: userNickname, ki: userKi })
    };
    try {
      const response = await fetch(`${API_PREFIX}/api/user`, requestOptions);
      if (response.status === 200) {
        console.error('Successfully added');
      } else {
        console.error('Failed to add item.');
      }
    } catch (error) {
      console.error('There was an error!', error);
    }
  }

  /* 最近見た楽譜のための関数 */
  const [listedRecent, setListedRecent] = useState([]);
  const [loadingRecent, setLoadingRecent] = useState(true);
  const [loadingScores, setLoadingScores] = useState(true);
  const getListedRecent = async (user_id) => {
    const requestOptions = {
      method: 'GET',
    };
    try {
      //const _recent = await fetchUserId();
      const response = await fetch(`${API_PREFIX}/api/user/${user_id}/recent`, requestOptions);
      if (response.status === 200) {
        //console.error('Successfully get scores');
        const data = await response.json();
        //console.log("listedRecent:", data.recentScores);
        const getScoreData = async () => {
          axios.post(`${API_PREFIX}/api/scores`, { scoreIds: data.recentScores })
            .then(response => {
              setListedRecent(response.data);
            })
            .catch(error => {
              console.error('Error:', error);
            });
        };
        getScoreData();
      } else {
        console.error('Failed to get score item.');
      }
    } catch (error) {
      console.error('There was an error!', error);
      setListedRecent([]);
      return false;
    } finally {
      setLoadingRecent(false);
      return true;
    }
  }

  useEffect(() => {
    const fetchRecentScore = async (user_id) => {
      getListedRecent(user_id).catch((error) => {
        console.error(error);
      }).finally(() => {
        setLoadingScores(false);
      });
    }
    const getListedBookmark = async (user_id) => {
      const requestOptions = {
        method: 'GET',
      };
      try {
        //const _bookmark = await fetchUserId();
        const response = await fetch(`${API_PREFIX}/api/user/${user_id}/bookmarks`, requestOptions);
        if (response.status === 200) {
          //console.error('Successfully get scores');
          const data = await response.json();
          //console.log("listedBookmark:", data.bookmarks);
          setListedBookmarkIds(data.bookmarks);
        } else {
          console.error('Failed to get score item.');
        }
      } catch (error) {
        console.error('There was an error!', error);
        return false;
      } finally {
        return true;
      }
    }
    fetchRecentScore(userId);
    getListedBookmark(userId);
  }, [userId]);

  return (
    <div className={styles.basic}>
      <header>
        <Header />
      </header>
      <main>
        <h1 className={styles.pageText}>マイページ
        </h1>
        {/* PageTop.jsからお引越し */}
        <h2 className={styles.pageText}>最近見た楽譜</h2>
              <div className={styles.scoreCard}>
                {loadingRecent ? <Loading onloading={loadingRecent} loading_object_name={"最近見た楽譜"} /> :
                  listedRecent.length === 0 ? <p>最近見た楽譜はありません</p> :
                    listedRecent.map((result) => (
                      <div className={styles.scoreCardWrapper}>
                      <ScoreCard key={result.scoreId} score_id={result.scoreId} scoreData={result.data} myBookmarkList={listedBookmarkIds} />
                      </div>
                    ))
                }
              </div>
        {amIadmin &&
          (<><h1>管理者権限ユーザのみ表示</h1>
            <div>
              nickname=
              <input
                type="text"
                value={userNickname}
                onChange={(e) => setUserNickname(e.target.value)}
              />
              <br></br>ki=
              <input
                type="number"
                value={userKi}
                onChange={(e) => setUserKi(e.target.value)}
              />
              <p></p>
              <button className='button' onClick={createUser}>ユーザー作成</button>

              
            </div></>)}

      </main>
    </div>

  );
}

export default PageMypage;