import React, { useState, useEffect } from 'react';
import styles from './DropDown.module.css'; // CSSモジュールのインポート

const DropDownForTagList = ({ selectKey, defaultValue, tagitems, onChange}) => {
  const [selectedValue, setSelectedValue] = useState("");
  useEffect(() => {
    //console.log("defaultValue changed:",defaultValue);
    setSelectedValue(defaultValue || "");
  }, [defaultValue]);  // defaultValueが変わった時にのみ効果を再実行
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <select
      key={selectKey}
      className={styles.dropDown}
      value={selectedValue}
      onChange={handleChange}
    >
      <option value="">
        タグを選択
      </option>
      {tagitems.sort((a,b)=>a.content.localeCompare(b.content)).map((item) => (
        <option key={item.content} value={item.tagId}>
          {item.content}
        </option>
      ))}
    </select>
  );
};

export default DropDownForTagList;