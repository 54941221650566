import React, { useState, useLayoutEffect } from 'react';
import YouTube from 'react-youtube';
import styles from './YoutubeContainer.module.css';
//youtubeリンク整形
function extractYouTubeId(url) {
    try {
        let id = '';
        const regex = /(?:\/|v=)([A-Za-z0-9_-]{11})(?:\?|&|$)/;
        const match = url.match(regex);
        if (match) {
            id = match[1];
        }
        return id;
    } catch (error) {
        return '';
    }
}

function extractTimestamp(url) {
    const pattern = /[?&]t=(\d+)/;
    const match = url.match(pattern);
    //console.log(match ? match[1] : 0);
    return match ? match[1] : 0;
};


const YoutubeContainer = ({ ids }) => {
    //let ids2 = "https://www.youtube.com/watch?v=S_B3FYceL6o&ab_channel=%E8%A5%BF%E5%9C%92%E5%AF%BA,https://www.youtube.com/watch?v=_2T2MV13V6o&ab_channel=HKpic,https://www.youtube.com/watch?v=7S7GNzroHPo&ab_channel=%E5%B2%B8%E5%B1%B1%E7%94%B0"
    //youtubeレスポンシブ用
    const useYt_Opts = () => {
        const [yt_opts, setYt_Opts] = useState({
            playerVars: {
                autoplay: 0, // 自動再生
                mute: 0, // 音声をミュート
                playsinline: 1, // インライン再生
                loop: 0, // 動画を繰り返し再生
            }
        });
        useLayoutEffect(() => {
            const handleResize = () => {
                const yt_opts_cp = { ...yt_opts };
                yt_opts_cp.width = Math.min(window.innerWidth * 0.8, 350 / 9 * 16);
                yt_opts_cp.height = yt_opts_cp.width * 9 / 16;
                setYt_Opts(yt_opts_cp);
            };
            window.addEventListener('resize', handleResize);

            handleResize();


            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }, []);
        return yt_opts;
    };

    const yt_opts = useYt_Opts();


    return (
        <div className={styles.videoList}>
            {ids.split(',').map((id, index) => {
                const yt_opts_tmp = {...yt_opts};
                yt_opts_tmp.playerVars.start = extractTimestamp(id);
                return (
                    <div key={index} className={styles.YoutubeContainer}>
                        <YouTube videoId={extractYouTubeId(id)} className={styles.iframe} opts={yt_opts_tmp} />
                    </div>
                )
            })}
        </div>
    );
};

export default YoutubeContainer;