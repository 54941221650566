import React, { useState, useEffect } from 'react';
import Button from '../Utils/Button/Button';
import TextInput from '../Utils/Ui/TextInput/TextInput';
import styles from './Dev.module.css';
import FileDropzone from '../FileUploader/FileUploader';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import Modal from 'react-modal';
import { Link, useLocation, useNavigate, } from 'react-router-dom';
import DropDownForUserList from '../../components/Utils/Ui/DropDown/DropDownForUserList';
import DropDownForBandList from '../../components/Utils/Ui/DropDown/DropDownForBandList';
import LoadingInRegistration from '../../components/Utils/Loading/LoadingInRegistration';
import { Auth } from 'aws-amplify';
import { FaPlus, FaPlusCircle } from 'react-icons/fa';
import { MdDeleteForever } from "react-icons/md";

const API_PREFIX = process.env.REACT_APP_API_PREFIX;

const statusMsgMap = {
    'finished': "✅ (3/3)アップロード完了！ファイル解析完了！　",
    'processing': "🔄 (1/3)アップロード中・・・ ファイルをサーバーにアップロードしています。",
    'execed': "🔄 (2/3)アップロード完了！ファイル解析中・・・　ファイルを解析しています。",
    'waiting': "処理を待っています"
};


function Dev() {

    //楽譜の登録情報
    const [myScoreId, setMyScoreId] = useState('');
    const [myScoreId_w_version, setMyScoreId_w_version] = useState('');
    const [versionId, setVersionId] = useState('');
    const [scoreName, setScoreName] = useState('');
    const scoreNameMaxLength = 100;
    const [artistName, setArtistName] = useState('');
    const artistNameMaxLength = 100;
    const [difficulty, setDifficulty] = useState(1);
    const [duration_minute, setDuration_minute] = useState(0);
    const [duration_second, setDuration_second] = useState(0);
    const [tempo, setTempo] = useState(-1);
    const [tempo_str, setTempoStr] = useState("");
    const [partMale, setPartMale] = useState(0);
    const [partFemale, setPartFemale] = useState(0);
    const [haveVp, setHaveVp] = useState(false);
    const [needVp, setNeedVp] = useState(true);
    const [isZeintaikyoku, setIsZeintaikyoku] = useState(false);
    const [isChangeLead, setIsChangeLead] = useState(false);
    const [voiceRanges, setVoiceRanges] = useState('');
    const [arrangerId, setArrangerId] = useState('');
    const [selectedBands, setSelectedBands] = useState([{ band_id: '', bandname: '' }]);
    const [accesibility, setAccesibility] = useState(0);
    const [tags, setTags] = useState(['']);
    const tagStrMaxLength = 20;
    const [parentScoreid, setParentScoreid] = useState(null);
    const [childScoreid, setChildScoreid] = useState('');
    const [youtubeLinks, setYoutubeLinks] = useState(''); //実際にDBに登録するカンマ区切りの文字列
    const [youtubeLinksArr, setYoutubeLinksArr] = useState([]) //ページで使用する配列形式
    const [versionName, setVersionName] = useState('オリジナル版');
    const versionNameMaxLength = 30;
    const [description, setDescription] = useState('');
    const descriptionMaxLength = 410;
    const [registered_userid, setRegisterdUserId] = useState('');

    //ページの機能
    const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(true); //デバッグのためにはfalse
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [isUploadErrorMsgModalOpen, setIsUploadErrorMsgModalOpen] = useState(false);
    const [isUnFilledModalOpen, setIsUnFilledModalOpen] = useState(false);
    const [isSimpleErrorMsgModalOpen, setIsSimpleErrorMsgModalOpen] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    //useEffect(() => {
        //console.log(`isUploadErrorMsgModalOpen:${isUploadErrorMsgModalOpen}, isSimpleErrorMsgModalOpen:${isSimpleErrorMsgModalOpen}`)
    //}, [isUploadErrorMsgModalOpen, isSimpleErrorMsgModalOpen]);


    //ファイル状況チェック関数
    function useFileStatus(fileId) {
        const [statusMessage, setStatusMessage] = useState('waiting');
        const [resultData, setResultData] = useState(null);

        useEffect(() => {
            let statusInterval = null;

            if (fileId) {
                setStatusMessage('ファイルをアップロードしています...');
                const checkStatus = () => {
                    axios.get(`${API_PREFIX}/api/status/${fileId}`)
                        .then(res => {
                            const { status, data } = res.data;
                            setStatusMessage(status);
                            if (status === 'finished') {
                                clearInterval(statusInterval);
                            }
                        })
                        .catch(err => {
                            console.error('Status check failed:', err);
                            setStatusMessage('エラーが発生しました。');
                            clearInterval(statusInterval);
                            setIsUploadErrorMsgModalOpen(true);
                            setErrorMsg(err);
                        });
                };
                statusInterval = setInterval(checkStatus, 1000); //1秒ごと
            }
            return () => clearInterval(statusInterval);
        }, [fileId]);
        return [statusMessage, resultData];
    }

    const [fileId, setFileId] = useState(null); // アップロード中のファイルIDを保持するための状態
    const [statusMessage, resultData] = useFileStatus(fileId); //状態監視
    const [infoReceived, setInfoReceived] = useState(false); //デバッグのためtrue
    const [confirmOkPressed, setConfirmOkPressed] = useState(false);

    //タグサジェスト用
    const [allTags, setAllTags] = useState([]);

    const location = useLocation();
    const navigate = useNavigate();

    const getMyUserId = async () => {
        try {
            // ログイン中のユーザー情報を取得
            const currentUserInfo = await Auth.currentAuthenticatedUser();
            const cognitoId = currentUserInfo.signInUserSession.idToken.payload.sub;

            // Cognito IDからユーザーIDを取得
            const userIdResponse = await axios.get(`${API_PREFIX}/api/user/whoami/${cognitoId}`);
            const userId = userIdResponse.data.userId;
            setRegisterdUserId(userId);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    // 使用音域から男声女声を予想する関数
    const judgeMaleOrFemale = (lowestNote, highestNote, referenceNote = "C4") => {
        if (lowestNote === "-") {
            // VPはこれで来るはず
            return "vp";
        }

        // 音階の配列を動的に生成する
        const notes = [];
        const noteNames = ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"];
        for (let octave = 0; octave <= 8; octave++) {
            for (let i = 0; i < noteNames.length; i++) {
                notes.push(`${noteNames[i]}${octave}`);
            }
        }

        // フラットをシャープに変換する関数
        const convertFlatToSharp = (note) => {
            const flatToSharpMap = {
                "Db": "C#",
                "Eb": "D#",
                "Gb": "F#",
                "Ab": "G#",
                "Bb": "A#",
                "D♭": "C#",
                "E♭": "D#",
                "G♭": "F#",
                "A♭": "G#",
                "B♭": "A#"
            };
            const name = note.slice(0, -1);
            const octave = note.slice(-1);
            return (flatToSharpMap[name] || name) + octave;
        };

        // フラットをシャープに変換
        lowestNote = convertFlatToSharp(lowestNote);
        highestNote = convertFlatToSharp(highestNote);

        // 各音のインデックスを取得
        const refIndex = notes.indexOf(referenceNote);
        const lowIndex = notes.indexOf(lowestNote);
        const highIndex = notes.indexOf(highestNote);

        // 基準音に対する低い音と高い音の数を計算
        const lowerCount = refIndex - lowIndex;
        const higherCount = highIndex - refIndex;

        // 低い音の方が多ければ男性（male）、そうでなければ女性（female）と判断
        return lowerCount > higherCount ? "male" : "female";
    };

    const parseBody = (part) => {
        return judgeMaleOrFemale(part.lowest, part.highest, "C4");
    };


    //ファイル処理（musicxmlのパース）を受け取る
    const applyReturnedData = (data, apiEp) => {
        if (apiEp.includes("pdf") || apiEp.includes("musicxml")) {
            //msczでなければアップロードするだけなので
            setInfoReceived(true);
            setPdfParsed(true);
            return;
        }
        setScoreName(data.title);
        const song_duration = Number(data.duration);
        const song_tempo = Number(data.bpm);
        const bodyParts = Object.values(data.body).map(part => {
            return `${part.PartName} (${part.lowest}~${part.highest})`;
        }); //音域をわかりやすい形式へ
        const bodyPartsS3 = Object.values(data.body).map(part => {
            return {
                PartName: part.PartName,
                lowest: part.lowest,
                highest: part.highest
            };
        });
        // JSON文字列に変換
        const bodyPartsJson = JSON.stringify(bodyParts);
        setVoiceRanges(bodyPartsJson);

        const partParsed = Object.values(data.body).map(part => {
            return parseBody(part);
        });//["male","female","female","vp"];
        const countOccurrences = (array, value) => {
            return array.reduce((acc, current) => acc + (current === value ? 1 : 0), 0);
        };
        // femaleの数をカウント
        const femaleCount = countOccurrences(partParsed, "female");
        setPartFemale(femaleCount);
        // maleの数をカウント
        const maleCount = countOccurrences(partParsed, "male");
        setPartMale(maleCount);
        // vpが含まれているか確認
        const containsVP = partParsed.includes("vp");
        setHaveVp(containsVP);
        setDuration_minute(Math.floor(song_duration / 60));
        setDuration_second(Math.ceil(song_duration % 60));
        setTempo(song_tempo);
        //テンポを設定
        if (song_tempo < 90) {
            setTempoStr("ローテンポ");
        } else if (song_tempo < 140) {
            setTempoStr("ミドルテンポ");
        } else {
            setTempoStr("アップテンポ");
        }
        setInfoReceived(true);
        return;
    };

    //pdf処理が終わったか
    const [pdfParsed, setPdfParsed] = useState(false);

    //プレビューpdf
    const [previewPdfLink, setPreviewPdfLink] = useState(null);

    //ファイルアップロード時に発火する関数
    const onFileChange = (files) => {
        const fileId = uuidv4(); // UUIDを生成
        setFileId(fileId);
        setIsFileUploadModalOpen(false);
        const formData = new FormData();
        const originalFile = files[0];
        const fileExtension = originalFile.name.split('.').pop().toLowerCase();
        let apiEndpoint;

        switch (fileExtension) {
            case 'pdf':
                apiEndpoint = `${API_PREFIX}/api/upload/pdf`;
                break;
            case 'mscz':
                apiEndpoint = `${API_PREFIX}/api/upload/mscz`;
                break;
            default:
                //console.log('Unsupported file type.');
                return;
        }
        const newFile = new File([originalFile], encodeURIComponent(originalFile.name), {
            type: originalFile.type,
            lastModified: originalFile.lastModified
        });
        formData.append("file", newFile);
        formData.append("fileId", fileId); // フォームデータにfileIdを追加

        axios
            .post(apiEndpoint, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((res) => {
                //setStatusMessage('アップロード完了。処理を開始しています...');
                //console.log(res.data);
                if (apiEndpoint.includes("mscz")) {
                    applyReturnedData(res.data, apiEndpoint);
                    //第2ラウンド　pdf変換
                    const postProcessData = { fileId: fileId, backend_originalname: res.data.backend_originalname, backend_filename: res.data.backend_filename };
                    axios.post(`${API_PREFIX}/api/upload/file_transform`, postProcessData, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                        .then((res) => {
                            //console.log('2nd script processing end');
                            let pdf_url = res.data;
                            setPdfParsed(true);
                            setPreviewPdfLink(pdf_url);
                        })
                        .catch((err) => {
                            console.error('Error calling postprocess endpoint:', err);
                        });
                } else {
                    applyReturnedData("null", apiEndpoint);
                    setPdfParsed(true);
                }



            })
            .catch((err) => {
                //setStatusMessage('エラーが発生しました。');
                console.error(err);
                setErrorMsg(err);
                //setIsSimpleErrorMsgModalOpen(true); -> ファイルアップロードの方のモーダルで十分
            });
    };

    const [useritems, setUseritems] = useState([]);
    const arrangerChange = (value) => {
        setArrangerId(value);
    }
    /*
    const bandChange = (value) => {
        setBandId(value);
    }*/
    const setHaveNeedVp = (value) => {
        if (value == 0) {
            setNeedVp(true);
            setHaveVp(true);
        } else if (value == 1) {
            setNeedVp(true);
            setHaveVp(false);
        } else {
            setNeedVp(false);
            setHaveVp(false);
        }
    }
    const getUserList = async () => {
        const requestOptions = {
            method: 'GET',
        };
        try {
            const response = await fetch(`${API_PREFIX}/api/user/userlist`, requestOptions);
            if (response.status === 200) {
                const data = await response.json(); // APIからのレスポンスデータをJSONとしてパース
                data.sort((a, b) => {
                    if (a.ki.N === b.ki.N) {
                        // xが等しい場合はyで比較
                        return a.nickname.S - b.nickname.S;
                    }
                    // xで比較
                    return a.ki.N - b.ki.N;
                });
                setUseritems(data); // パースしたデータを状態にセット

            } else {
                console.error("userlist get failed");
            }
        } catch (error) {
            console.error('There was an error!', error);
        }
        return;
    }
    const [banditems, setBanditems] = useState([]);
    const getBandList = async () => {
        const requestOptions = {
            method: 'GET',
        };
        try {
            const response = await fetch(`${API_PREFIX}/api/band/bandlist`, requestOptions);
            if (response.status === 200) {
                const data = await response.json(); // APIからのレスポンスデータをJSONとしてパース
                setBanditems(data); // パースしたデータを状態にセット
            } else {
                console.error('Failed to get band item.');
            }
        } catch (error) {
            console.error('There was an error!', error);
        }
        return;
    }

    const getTagList = async () => {
        const requestOptions = {
            method: 'GET',
        };
        try {
            const response = await fetch(`${API_PREFIX}/api/tags`, requestOptions);
            if (response.status === 200) {

                const data = await response.json();
                const tag_contents = data.map(item => item.content);
                tag_contents.sort();
                setAllTags(tag_contents);
                //console.log("tag_contents:", tag_contents);
            } else {
                console.error(response.status, 'Failed to tag band item.');
            }
        } catch (error) {
            console.error('There was an error!', error);
        }
        return;
    };

    //バンドの登録周り
    const handleBandChange = (index, newBandId) => {
        let updatedBands = [...selectedBands];
        updatedBands[index] = banditems.find(band => band.band_id.S === newBandId) || { band_id: '', bandname: '' };

        // 入力が削除された場合はそのフィールドを削除
        if (newBandId === '') {
            updatedBands = updatedBands.filter((_, i) => i !== index);
            if (updatedBands.length === 0) {
                updatedBands.push({ band_id: '', bandname: '' }); // 全て削除された場合は新しい空フィールドを追加
            }
        } else if (index === selectedBands.length - 1 && selectedBands.length < 10) {
            // 最後のフィールドに入力がある場合は新しい空フィールドを追加
            updatedBands.push({ band_id: '', bandname: '' });
        }
        setSelectedBands(updatedBands);
    };
    //タグの登録周り
    const handleTagChange = (index, event) => {
        const userInput = event.target.value;
        let newTags = [...tags];
        newTags[index] = userInput;
        /*
        if (userInput.length === 0) {
          // 入力が0文字の場合はその入力フィールドを削除
            newTags = newTags.filter((_, i) => i !== index);
            if (newTags.length === 0) {
            // 全ての入力フィールドが削除された場合、新しい空のフィールドを追加
            newTags.push('');
            }
        } else if (index === tags.length - 1 && tags.length < 10) {
            // 最後の入力フィールドに文字がある場合、新しい空のフィールドを追加（最大10個まで）
            newTags.push('');
        }*/
        setTags(newTags);
    };
    const handleTagBoxAdd = (event) => {
        event.preventDefault();
        const newTags = [...tags];
        if (tags.length < 10) {
            newTags.push('');
        }
        setTags(newTags);
    }

    const handleTagBoxDelete = (event, index) => {
        event.preventDefault();
        const newTags = tags.filter((tag, i) => i !== index);
        setTags(newTags);
    }

    //YouTube動画の登録周り
    const [addedYts, setAddedYts] = useState([]);
    const [deletedYts, setDeletedYts] = useState([]);
    const YtEditHistory = (currentYtArr) => { //使わない関数　のちのち消す
        const newAddedYts = [];
        const newDeletedYts = [];
        /*initialYts.map((yt) => {
            if (!currentYtArr.includes(yt)) {
                newDeletedYts.push(yt);
            }
        });
        currentYtArr.map((yt) => {
            if (!initialYts.includes(yt)) {
                newAddedYts.push(yt);
            }
        });*/
        setAddedYts(newAddedYts);
        setDeletedYts(newDeletedYts);
        //console.log(`addedYts:${newAddedYts}, deletedYts:${newDeletedYts}`);
    }

    const handleYtChange = (index, event) => {

        const userInput = event.target.value;
        //console.log("userInput: ", userInput);
        const newYts = [...youtubeLinksArr];
        newYts[index] = userInput;
        //console.log("newYts, ");
        //console.log(newYts);
        /*
        if (userInput.length === 0) {
            // 入力が0文字の場合はその入力フィールドを削除
            newTags = newTags.filter((_, i) => i !== index);
            if (newTags.length === 0) {
                // 全ての入力フィールドが削除された場合、新しい空のフィールドを追加
                newTags.push('');
            }
        } else if (index === tags.length - 1 && tags.length < 10) {
            // 最後の入力フィールドに文字がある場合、新しい空のフィールドを追加（最大10個まで）
            newTags.push('');
        }*/
        setYoutubeLinksArr(newYts);
        YtEditHistory(newYts);
        //console.log(youtubeLinksArr);
        //console.log(`in handlechange, addedYts:${addedYts}, deletedYts:${deletedYts}`);
    };
    const handleYtBoxAdd = (event) => {
        event.preventDefault();
        const newYts = [...youtubeLinksArr];
        if (youtubeLinksArr.length < 3) {
            newYts.push('');
        } else {
            alert("登録できるのは3つまでです");
        }
        setYoutubeLinksArr(newYts);
        YtEditHistory(newYts);
    }

    const handleYtBoxDelete = (event, index) => {
        event.preventDefault();
        const newYts = youtubeLinksArr.filter((tag, i) => i !== index);
        setYoutubeLinksArr(newYts);

        YtEditHistory(newYts);
    }

    const MustFieldFilled = () => {
        //必須項目を埋めているか確認
        //埋めてない項目を見つけたらそれをメッセージとして
        if (versionName.length === 0) { return "バージョン名は必須項目です"; }
        if (scoreName.length === 0) { return "曲名は必須項目です"; }
        //if(artistName.length == 0){return "アーティスト名は必須項目です";}
        if (difficulty === -1) { return "難易度設定は必須項目です"; }
        if (!(duration_minute >= 0)) { return "曲尺に不正な値が入力されています" }
        if (!(duration_second >= 0)) { return "曲尺に不正な値が入力されています" }
        if (!(duration_minute * 60 + duration_second > 0)) { return "曲尺を（だいたいの長さでもいいので）埋めてください"; }
        if (partMale === -1 || partMale === -1) { return "パート数を設定してください"; }
        if (Number(partMale) + Number(partFemale) < 2) { return "パート数が少なすぎます"; }
        if (tempo_str === "ng") { return "テンポを選択してください"; }

        return "";
    }
    //最初の登録ボタン押下
    const [unFilledFields, setUnFilledFields] = useState("まだ情報が登録されていません");
    const onConfirmButtonFirst = () => {
        //曲尺を計算
        const unfilled = MustFieldFilled();
        setUnFilledFields(unfilled);
        if (unfilled.length === 0) {
            setIsConfirmModalOpen(true);
        } else {
            console.error(unfilled);
            setIsUnFilledModalOpen(true);
        }
    };
    //「登録しますか？→はい」
    const [registrationFailed, setRegistrationFailed] = useState(false);
    const [registrationStatus, setRegistrationStatus] = useState([]);
    const onConfirmButtonLast = async () => {
        let registration_failed = false;
        let registration_status = [];

        //setMyScoreId(scoreId);
        const scoreDataToCreate = {
            score_id: myScoreId,
            version_id: "_001",
            version_name: versionName,
            score_name: scoreName,
            artist_name: artistName,
            difficulty: Number(difficulty),
            duration: Number(duration_minute) * 60 + Number(duration_second),
            part_male: Number(partMale),
            part_female: Number(partFemale),
            tempo: tempo,
            tempo_str: tempo_str,
            is_changeLead: isChangeLead,
            have_vp: haveVp,
            need_vp: needVp,
            is_zentaikyoku: isZeintaikyoku,
            voice_ranges: voiceRanges,
            accesibility: Number(accesibility),
            parent_scoreid: parentScoreid,
            child_scoreid: null,
            youtube_links: youtubeLinksArr.join(","), //youtubeLinks, //ユーザーを信じてベタ入れベタだし　修正するなら→yt_link_preprocess(youtubeLinks),
            description: description,
            registered_userid: registered_userid
        };
        //ファイルアップロード
        const formData = new FormData();
        formData.append("fileId", fileId); // フォームデータにfileIdを追加
        formData.append("scoreId", myScoreId_w_version); // フォームデータにscoreIdを追加
        //formData.append("versionId", "_001"); // フォームデータにversionIdを追加
        axios
            .post(`${API_PREFIX}/api/file_register`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((res) => {
                //console.log(res.data);
            })
            .catch((err) => {
                console.error(err);
                registration_failed = true;
                registration_status.push("ファイルアップロードに失敗しました");
            });
        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(scoreDataToCreate)
            };
            const response = await fetch(`${API_PREFIX}/api/score`, requestOptions);
            if (response.status === 200) {
                const data = await response.json();
                setMyScoreId(data.scoreId);
            } else {
                console.error('楽譜登録に失敗');
            }
        } catch (error) {
            console.error('There was an error!', error);
            registration_failed = true;
            registration_status.push("楽譜情報登録に失敗しました");
        }
        //アレンジャー、演奏バンド、タグをrelのDBへ
        //アレンジャーが指定された場合
        if (arrangerId !== '') {
            try {
                const requestOptions = {
                    method: 'POST'
                };
                
                const response = await fetch(`${API_PREFIX}/api/arranged/user/${arrangerId}/score/${myScoreId_w_version}`, requestOptions);

                if (!response.ok) {
                    throw new Error(`アレンジャーID ${arrangerId} の送信に失敗しました: ${response.statusText}`);
                }
            

            } catch (error) {
                console.error('アレンジャー登録送信中のエラー:', error);
                registration_failed = true;
                registration_status.push("アレンジャー情報登録に失敗しました");
            }
        }

        //演奏バンド
        try {
            const requestOptions = {
                method: 'POST'
            };

            for (let i = 0; i < selectedBands.length; i++) {
                const curr_bandid = selectedBands[i]?.band_id?.S || "";
                if (curr_bandid.length > 0) {
                    const response = await fetch(`${API_PREFIX}/api/performed/band/${curr_bandid}/score/${myScoreId_w_version}`, requestOptions);

                    if (!response.ok) {
                        throw new Error(`バンドID ${curr_bandid} の送信に失敗しました: ${response.statusText}`);
                    }
                }
            }
        } catch (error) {
            console.error('バンドパフォーマンス送信中のエラー:', error);
            registration_failed = true;
            registration_status.push("バンド情報登録に失敗しました");
        }
        //タグ
        try {
            for (const tag of tags) {
                if (tag !== '') {
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ content: tag })
                    };
                    const response = await fetch(`${API_PREFIX}/api/score/${myScoreId_w_version}/tag`, requestOptions);

                    if (!response.ok) {
                        throw new Error(`タグ ${tag} の送信に失敗しました: ${response.statusText}`);
                    }
                }
            }
        } catch (error) {
            console.error('タグ送信中のエラー:', error);
            registration_failed = true;
            registration_status.push("タグ情報登録に失敗しました");
        }

        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
            };
            if (myScoreId_w_version) {
                const response = await fetch(`${API_PREFIX}/api/score/${myScoreId_w_version}/newcomers`, requestOptions);
                //console.log(`新着楽譜として登録しました`);
                if (!response.ok) {
                    throw new Error(`新着楽譜の登録に失敗しました: ${response.statusText}`);
                } //else {
                    //console.log(response);
                //}
            } else {
                console.error("score_idなし");
            }


        } catch (error) {
            console.error('新着楽譜の登録に失敗しました', error);
            registration_failed = true;
            registration_status.push("新着楽譜としての登録に失敗しました");
        }

        setConfirmOkPressed(true);
        if(registration_failed){
            setRegistrationFailed(true);
            setRegistrationStatus(registration_status);
        }else{
            setRegistrationFailed(false);
            setRegistrationStatus([]);
        }
    };
    //「登録しますか？→いいえ」
    const onConfirmButtonLastCancel = () => {
        setIsConfirmModalOpen(false);
    };
    //登録しました！の状態でトップページへ戻る
    const buttonReturnToTopPushed = () => {
        navigate('/home');
    };
    const buttonToUploadAnotherFile = () => {
        setErrorMsg("");
        setIsUploadErrorMsgModalOpen(false);
        setIsFileUploadModalOpen(true);
    };
    const buttonReturnToScoreDetail = () => {
        navigate(`/score/${myScoreId_w_version}`);
    };
    const buttonPressOk = () => {
        setIsUnFilledModalOpen(false);
        setIsSimpleErrorMsgModalOpen(false);
    };

    //pdfプレビュー
    const onPreviewPdf = () => {
        if (previewPdfLink) {
            window.open(previewPdfLink, '_blank');
        } else {
            console.error("PDF URL is not available");
        }
    };

    //youtubeのリンクを所定フォーマットに
    const yt_link_preprocess = (raw_input) => {
        // 区切り文字としてカンマや全角カンマ、スペース、タブなどを考慮します
        const separators = /[,\、\s]+/;

        // ユーザーの入力を分割して配列にします
        let links = raw_input.split(separators).filter(link => link.trim().length > 0);

        // リンク数が3個を超える場合はエラーメッセージを返します
        if (links.length > 3) {
            throw new Error("Error: You cannot enter more than 3 links.");
        }

        // リンクを連結
        let normalized_input = links.join(',');

        return normalized_input;
    };

    useEffect(() => {
        // ページ読み込み時
        getUserList();
        getBandList();
        getMyUserId();
        getTagList();
        //楽譜ID発行
        const _scoreId = uuidv4();
        setMyScoreId(_scoreId);
        setMyScoreId_w_version(_scoreId + "_001"); //このページは新規作成なので
    }, []);

    //文字数制限アラート用

    const maxLengthCheck = async (str, length) => {
        if(str?.length > length){
            alert(`文字数制限${length}を超えています`);
        }
    }

    useEffect(()=>{
        maxLengthCheck(scoreName, scoreNameMaxLength);
    },[scoreName])

    useEffect(()=>{
        maxLengthCheck(versionName, versionNameMaxLength);
    },[versionName])

    useEffect(()=>{
        maxLengthCheck(artistName, artistNameMaxLength);
    },[artistName])

    useEffect(()=>{
        maxLengthCheck(description, descriptionMaxLength);
    },[description])

    return (
        <div>
            {/*
            <p>バージョン（選択）</p>
            <select value={Version} onChange={e => setVersion(e.target.value)}>
                <option value="option1">既存</option>
                <option value="option2">新規</option>
            </select>
            */}
            <p>ステータス： {statusMsgMap[statusMessage] ? statusMsgMap[statusMessage] : statusMessage}.</p>
            {previewPdfLink ? (
                <Button
                    type='normal'
                    child='PDFを確認'
                    onClick={onPreviewPdf}
                />) : (<p></p>)}
            <h2>楽曲情報</h2>
            <div className={styles.info}>
                <form method="" action="">
                    <table>
                        <tr>
                            <th>バージョン名*</th>
                            <td><input
                                type="text"
                                placeholder="ex) オリジナル版"
                                value={versionName}
                                onChange={(e) => setVersionName(e.target.value)}
                                maxLength={versionNameMaxLength}
                            /></td>
                        </tr>
                        <tr>
                            <th>曲名*</th>
                            <td>{infoReceived ? (<input
                                type="text"
                                placeholder="ex) 光るなら"
                                value={scoreName}
                                onChange={(e) => setScoreName(e.target.value)}
                                maxLength={scoreNameMaxLength}
                            />) :
                                (<LoadingInRegistration
                                    onloading={!infoReceived}
                                    loading_object_name={"曲名"}
                                />)}</td>
                        </tr>
                        <tr>
                            <th>アーティスト</th>
                            <td><input
                                type="text"
                                placeholder="ex) Goose house"
                                value={artistName}
                                onChange={(e) => setArtistName(e.target.value)}
                                maxLength={artistNameMaxLength}
                            /></td>
                        </tr>
                    </table>
                </form>
            </div>

            <h2>アレンジ情報</h2>
            <div className={styles.info}>
                <form method="" action="">
                    <table>
                        <tr>
                            <th>アレンジャー</th>
                            <DropDownForUserList selectKey={0} useritems={useritems} onChange={arrangerChange} />
                        </tr>
                        <tr>
                            <th>全体曲</th>
                            <td><input
                                type="checkbox"
                                checked={isZeintaikyoku}
                                onChange={e => setIsZeintaikyoku(e.target.checked)}
                            /></td>
                        </tr>
                        <tr>
                            <th>男女構成(VP以外)*</th>
                            <td>{infoReceived ? (
                                <>
                                    <select value={partMale} onChange={e => setPartMale(e.target.value)} disabled={isZeintaikyoku}>
                                        <option value={0}>男0</option>
                                        <option value={1}>男1</option>
                                        <option value={2}>男2</option>
                                        <option value={3}>男3</option>
                                        <option value={4}>男4</option>
                                        <option value={5}>男5</option>
                                        <option value={6}>男6</option>
                                        <option value={7}>男7</option>
                                    </select>
                                    <select value={partFemale} onChange={e => setPartFemale(e.target.value)} disabled={isZeintaikyoku}>
                                        <option value={0}>女0</option>
                                        <option value={1}>女1</option>
                                        <option value={2}>女2</option>
                                        <option value={3}>女3</option>
                                        <option value={4}>女4</option>
                                        <option value={5}>女5</option>
                                        <option value={6}>女6</option>
                                        <option value={7}>女7</option>
                                    </select></>) : (<LoadingInRegistration
                                        onloading={!infoReceived}
                                        loading_object_name={"男女構成"}
                                    />)}</td>
                        </tr>
                        <tr>
                            {/*
                                <th>VP想定*</th>
                                <td><select value={needVp} onChange={e => {
                                    setNeedVp(e.target.value);
                                    setHaveVp(e.target.value === "true" ? "true" : "false"); // needVpがtrueの場合にはhaveVpもtrueに、そうでなければfalseに設定
                                    }}>
                                        <option value={true}>有り</option>
                                        <option value={false}>無し</option>
                                    </select></td>
                            </tr>
                            <tr>
                                <th>VP記載*</th>
                                <td>{infoReceived ? (
                                    <select value={haveVp} onChange={e => setHaveVp(e.target.value)} disabled={needVp!=="true"}> 
                                        <option value={true}>有り</option>
                                        <option value={false}>無し</option>
                                    </select>):(
                                        <LoadingInRegistration
                                        onloading={!infoReceived}
                                        loading_object_name={"VP記載"}
                                    />
                                    )</td>
                                */}
                            <th>VP有無*</th>
                            <td>
                                {infoReceived ? (
                                    <select value={haveVp ? 0 : needVp ? 1 : 2} onChange={e => setHaveNeedVp(Number(e.target.value))}>
                                        <option value={0}>VP譜あり</option>
                                        <option value={1}>VP譜なし（VPあり想定）</option>
                                        <option value={2}>VP譜なし（VPなし想定）</option>
                                    </select>
                                ) : <LoadingInRegistration
                                    onloading={!infoReceived}
                                    loading_object_name={"VP有無"}
                                />}
                            </td>
                        </tr>
                        <tr>
                            <th>曲尺*</th>
                            <td>{infoReceived ? (
                                <><div className={styles.inputDuration}>
                                    <input
                                        className={styles.inputDurationWidth}
                                        type="number"
                                        value={duration_minute}
                                        onChange={(e) => setDuration_minute(e.target.value)}
                                    /><p>分</p>
                                    <input
                                        className={styles.inputDurationWidth}
                                        type="number"
                                        value={duration_second}
                                        onChange={(e) => setDuration_second(e.target.value)}
                                    />
                                    <p>秒</p> </div></>) : (<LoadingInRegistration
                                        onloading={!infoReceived}
                                        loading_object_name={"曲尺"}
                                    />)}</td>
                        </tr>
                        <tr>
                            <th>テンポ*</th>
                            <td>{infoReceived ? (
                                <><select value={tempo_str} onChange={e => setTempoStr(e.target.value)}>
                                    <option value={"ng"}>テンポを選択</option>
                                    <option value={"ローテンポ"}>ローテンポ</option>
                                    <option value={"ミドルテンポ"}>ミドルテンポ</option>
                                    <option value={"アップテンポ"}>アップテンポ</option>
                                </select></>) : (<LoadingInRegistration
                                    onloading={!infoReceived}
                                    loading_object_name={"テンポ"}
                                />)}</td>
                        </tr>
                        <tr>
                            <th>難易度*</th>
                            <td><select value={difficulty} onChange={e => setDifficulty(e.target.value)}>
                                <option value={1}>☆1 (入門)</option>
                                <option value={2}>☆2 (簡単)</option>
                                <option value={3}>☆3 (普通)</option>
                                <option value={4}>☆4 (やや難)</option>
                                <option value={5}>☆5 (難しい)</option>
                            </select></td>
                        </tr>
                        <tr>
                            <th>リード回し</th>
                            <td><input
                                type="checkbox"
                                checked={isChangeLead}
                                onChange={e => setIsChangeLead(e.target.checked)}
                            /></td>
                        </tr>
                        {parentScoreid ? (<tr><th>親楽譜</th><td>hoge</td></tr>) : <p></p>}

                    </table>
                </form>
            </div>

            <h2>その他情報</h2>
            <div className={styles.info}>
                <form method="" action="">
                    <table>
                        <tr>
                            <th>公開範囲*</th>
                            <td><select value={accesibility} onChange={e => setAccesibility(e.target.value)}>
                                <option value={0}>非公開</option>
                                <option value={1}>限定公開</option>
                                <option value={2}>全体公開</option>
                            </select></td>
                        </tr>
                        <tr>
                            <th>演奏バンド</th>
                            <td>{selectedBands.map((selectedBand, index) => (
                                <DropDownForBandList
                                    key={index}
                                    selectKey={index.toString()}
                                    defaultValue={selectedBand.band_id.S}
                                    banditems={banditems}
                                    onChange={(value) => handleBandChange(index, value)}
                                />
                            ))}
                            </td>
                        </tr>
                        <tr>
                            <th>演奏動画URL</th>
                            <td>
                                {/*<input
                                type="text"
                                placeholder="https://www.youtube.com/watch?v=xxx, https://www.youtube.com/watch?v=yyy, https://www.youtube.com/watch?v=zzz"
                                value={youtubeLinks}
                                onChange={(e) => setYoutubeLinks(e.target.value)}
                            />*/}

                                <div className={styles.YtBox}>
                                    {youtubeLinksArr.map((ytlink, index) => (
                                        <div className={styles.YtInputBox}>
                                            <TextInput
                                                key={"forYt" + index.toString()}
                                                placeholder="https://www.youtube.com/watch?v=xxx"
                                                value={ytlink}
                                                onChange={(event) => {
                                                    // 新しいyoutubeLinksArrを作成
                                                    const newYoutubeLinksArr = [...youtubeLinksArr];
                                                    newYoutubeLinksArr[index] = event.target.value; // 値を変更
                                                    youtubeLinksArr[index] = event.target.value; // 値を変更
                                                    handleYtChange(index, event);
                                                }}
                                                useAPI={false}
                                                //suggestions={allTags}
                                                width="L"
                                            />
                                            <button className={`${styles.tagDeleteButton} ${styles.YtBoxButton}`} onClick={(e) => handleYtBoxDelete(e, index)}><MdDeleteForever className={styles.icon}/></button>
                                        </div>
                                    ))}
                                </div>
                                <div className={styles.tagAddButtonWrapper}>
                                    <p>演奏動画URLを追加する</p>
                                    <button className={`${styles.tagAddButton} ${styles.tagBoxButton}`} onClick={handleYtBoxAdd}><FaPlusCircle className={styles.icon}/></button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>説明文</th>
                            <td><textarea
                                value={description}
                                onChange={e => setDescription(e.target.value)}
                                maxlength={descriptionMaxLength}
                                placeholder="入力してください" /></td>
                        </tr>
                        <tr>
                            <th>タグ</th>
                            <td><div className={styles.tagBoxWrapper}>
                                <div className={styles.tagBox}>
                                    {/*placeholder, value, onChange, useAPI, suggestions, width */}
                                    {tags.map((tag, index) => (
                                        <div className={styles.tagInputBox}>
                                            <TextInput
                                                key={"forTag" + index.toString()}
                                                placeholder="タグ"
                                                value={tag}
                                                onChange={(event) => handleTagChange(index, event)}
                                                useAPI={false}
                                                suggestions={allTags}
                                                width="M"
                                                maxLength={tagStrMaxLength}
                                            />
                                            <button className={`${styles.tagDeleteButton} ${styles.tagBoxButton}`} onClick={(e) => handleTagBoxDelete(e, index)}><MdDeleteForever className={styles.icon}/></button>
                                        </div>
                                    ))}
                                </div>
                                <div className={styles.tagAddButtonWrapper}>
                                    <p>タグを追加する</p>
                                    <button className={`${styles.tagAddButton} ${styles.tagBoxButton}`} onClick={handleTagBoxAdd}><FaPlusCircle className={styles.icon}/></button>
                                </div>
                            </div>
                            </td>
                        </tr>
                    </table>
                </form>
            </div>
            <div className={styles.buttonContainer}>
                <Button
                    type='primary3'
                    child='登録'
                    onClick={onConfirmButtonFirst}
                    disabled={!pdfParsed}
                />
            </div>

            {/*最初に表示するモーダル */}
            {isFileUploadModalOpen && (<div className={styles.uploadModalContent}>
                <Modal
                    isOpen={isFileUploadModalOpen}
                    contentLabel="Analysis Results"
                    //onRequestClose={() => setIsFileUploadModalOpen(false)}
                    style={{
                        content: {
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            transform: 'translate(-50%, -50%)',
                            width: '80%', // またはコンテンツに合わせた具体的な値
                            height: 'auto', // コンテンツの高さに自動的に合わせる
                            overflow: 'auto', // コンテンツがオーバーフローする場合はスクロールを可能にする
                        }
                    }}
                >
                    <div className={styles.uploadContent}>
                        <FileDropzone onChange={onFileChange} />
                    </div>
                    <div className={styles.centering}>
                        <div>または</div><div><Button
                            type='normal'
                            child='TOPへ戻る'
                            onClick={buttonReturnToTopPushed} /></div>
                    </div>
                </Modal>
            </div>)}
            {/*登録時に表示するモーダル */}
            {isConfirmModalOpen && (
                <Modal
                    isOpen={isConfirmModalOpen}
                    //onRequestClose={() => setIsConfirmModalOpen(false)}
                    style={{
                        content: {
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            transform: 'translate(-50%, -50%)',
                            width: '80%', // またはコンテンツに合わせた具体的な値
                            maxHeight: '80vh', // コンテンツの高さに自動的に合わせる
                            overflow: 'auto', // コンテンツがオーバーフローする場合はスクロールを可能にする
                        }
                    }}
                >
                    <div className={styles.confirmContainer}>
                        {!confirmOkPressed ? (
                            <>
                                <h1>登録します。よろしいですか？</h1>
                                <Button
                                    type='primary3'
                                    child='登録'
                                    onClick={onConfirmButtonLast}
                                />
                                <Button
                                    type='normal'
                                    child='やめる'
                                    onClick={onConfirmButtonLastCancel}
                                />
                            </>) : registrationFailed ? (
                                <>
                                    <h1>一部の情報登録に失敗しました</h1>
                                    <ul>
                                        {
                                            registrationStatus.map((status)=>{
                                                return <li>{status}</li>
                                            })
                                        }
                                    </ul>
                                    <p>お問い合わせ用の楽譜ID：{myScoreId_w_version}</p>
                                    <Button
                                        type='normal'
                                        child='トップへ戻る'
                                        onClick={buttonReturnToTopPushed}
                                    />
                                    <Button
                                        type='normal'
                                        child='楽譜詳細ページへ'
                                        onClick={buttonReturnToScoreDetail}
                                    />
                                </>
                                ):(
                            <>
                                <h1>登録しました！</h1>
                                <Button
                                    type='normal'
                                    child='トップへ戻る'
                                    onClick={buttonReturnToTopPushed}
                                />
                                <Button
                                    type='normal'
                                    child='楽譜詳細ページへ'
                                    onClick={buttonReturnToScoreDetail}
                                />
                            </>
                            )}
                    </div>
                </Modal>
            )}
            {/*アップロード失敗時に出るモーダル */}
            {isUploadErrorMsgModalOpen && (
                <div className={styles.errorMsgModalContent}>
                    <Modal
                        isOpen={isUploadErrorMsgModalOpen}
                        //onRequestClose={() => setIsConfirmModalOpen(false)}
                        style={{
                            content: {
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                transform: 'translate(-50%, -50%)',
                                width: '60%', // またはコンテンツに合わせた具体的な値
                                maxHeight: '80vh', // コンテンツの高さに自動的に合わせる
                                overflow: 'auto', // コンテンツがオーバーフローする場合はスクロールを可能にする
                            }
                        }}
                    >
                        <div className={styles.errorMsgContent}>
                            <h1>アップロードに失敗しました</h1>
                            <div>
                                以下の項目を確認してみてください
                                <ul>
                                    <li>.mscz形式のファイルをアップロードしてください</li>
                                    <li>MuseScoreのバージョンは4.4.0以下のみ保障しております</li>
                                    {/*<li>ファイル名の拡張子以外の部分に'.'は入れないでください</li>*/}
                                </ul>

                                エラーメッセージ：{errorMsg.toString()}
                            </div>
                        </div>
                        <div className={styles.centering}>
                            <div><Button
                                type='normal'
                                child='TOPへ戻る'
                                onClick={buttonReturnToTopPushed} />
                                <Button
                                    type='normal'
                                    child='違うファイルをアップロードする'
                                    onClick={buttonToUploadAnotherFile} />
                            </div>
                        </div>
                    </Modal>
                </div>
            )}

            {/* 必須項目が埋められていない場合に表示するモーダル */}
            {isUnFilledModalOpen && (
                <div className={styles.errorMsgModalContent}>
                    <Modal
                        isOpen={isUnFilledModalOpen}
                        //onRequestClose={() => setIsConfirmModalOpen(false)}
                        style={{
                            content: {
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                transform: 'translate(-50%, -50%)',
                                width: '60%', // またはコンテンツに合わせた具体的な値
                                maxHeight: '80vh', // コンテンツの高さに自動的に合わせる
                                overflow: 'auto', // コンテンツがオーバーフローする場合はスクロールを可能にする
                            }
                        }}
                    >
                        <div className={styles.errorMsgContent}>
                            {unFilledFields}
                        </div>
                        <div className={styles.centering}>
                            <div><Button
                                type='normal'
                                child='OK'
                                onClick={buttonPressOk} />
                            </div>
                        </div>
                    </Modal>
                </div>
            )}

            {/* その他エラー時に表示するモーダル */}
            {isSimpleErrorMsgModalOpen && (
                <div className={styles.errorMsgModalContent}>
                    <Modal
                        isOpen={isSimpleErrorMsgModalOpen}
                        //onRequestClose={() => setIsConfirmModalOpen(false)}
                        style={{
                            content: {
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                transform: 'translate(-50%, -50%)',
                                width: '60%', // またはコンテンツに合わせた具体的な値
                                height: 'auto', // コンテンツの高さに自動的に合わせる
                                overflow: 'auto', // コンテンツがオーバーフローする場合はスクロールを可能にする
                            }
                        }}
                    >
                        <div className={styles.errorMsgContent}>
                            {errorMsg.toString()}
                        </div>
                        <div className={styles.centering}>
                            <div><Button
                                type='normal'
                                child='OK'
                                onClick={buttonPressOk} />
                            </div>
                        </div>
                    </Modal>
                </div>
            )}
        </div>

    );
}

export default Dev;