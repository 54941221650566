import React from 'react';
import Header from '../components/Utils/CommonHeader/CommonHeader'
import styles from './PageNotFound.module.css';
export const PageNotFound = () => {

  return (
    <div className={styles.basic}>
      <header>
        <Header/>
      </header>
      <main>
          <h1>404 not found
          </h1>
      </main>
    </div>
    
  );
}

export default PageNotFound;