import React from 'react';
import Button from '../components/Utils/Button/Button';

import Header from '../components/Utils/CommonHeader/CommonHeader'
import Dev from '../components/MusicEdit/Dev';
import { useParams } from 'react-router-dom';

export function PageMusicEdit() {
    let { score_id } = useParams();
    return (
        <div>
            <header>
                <Header/>
            </header>
            <main>
            
                <h1>楽譜編集
                </h1>
                <Dev score_id={score_id}/>
            </main>
        </div>
        
    );
}

export default PageMusicEdit;