import React, { useState, useEffect, useCallback } from 'react';
import { Auth } from 'aws-amplify';
import Loading from '../components/Utils/Loading/Loading';
import ScoreCard from '../components/Utils/ScoreCard/ScoreCard';
import { useLocation, Link } from 'react-router-dom';
import CommonHeader from '../components/Utils/CommonHeader/CommonHeader';
import styles from './PageTop.module.css';
import axios from 'axios';
const API_PREFIX = process.env.REACT_APP_API_PREFIX;

export const PageTop = () => {
    const location = useLocation();
    const [listedScoreIds, setListedScoreIds] = useState([]);
    const [loadingScores, setLoadingScores] = useState(true);
    const getScoreIds = async () => {
        const requestOptions = {
            method: 'GET',
        };
        try {
            const response = await fetch(`${API_PREFIX}/api/scores/list`, requestOptions);
            if (response.status === 200) {
                //console.error('Successfully get scores');
                const data = await response.json();
                setListedScoreIds(data); // IDのリストを状態にセット
            } else {
                console.error('Failed to get score item.');
            }
        } catch (error) {
            console.error('There was an error!', error);
        } finally {
            return 'finished';
        }
    }

    //cognitoID用
    const [userId, setUserId] = useState('');
    const [loading, setLoading] = useState(false);
    const [myCognito, setMyCognito] = useState('init');
    const fetchUserId = async () => {
        setLoading(true);
        setLoadingScores(true);
        try {
            // ログイン中のユーザー情報を取得
            const currentUserInfo = await Auth.currentAuthenticatedUser();
            const cognitoId = currentUserInfo.signInUserSession.idToken.payload.sub;
            setMyCognito(cognitoId);

            // APIエンドポイントにクエリパラメータとしてcognitoidを付けてリクエストを送る
            const response = await fetch(`${API_PREFIX}/api/user/whoami/${cognitoId}`);
            if (!response.ok) {
                throw new Error('Failed to fetch user ID');
            }
            const data = await response.json();
            setUserId(data.userId);
            return data.userId;
        } catch (error) {
            console.error('Error fetching user ID:', error);
            setUserId('Error fetching user ID');
        }
    };

    const [listedBookmark, setListedBookmark] = useState([]);
    const [listedBookmarkIds, setListedBookmarkIds] = useState([]);
    const [loadingBookmark, setLoadingBookmark] = useState(true);
    const getListedBookmark = useCallback(async () => {
        if (!userId) return;
        try {
            const response = await fetch(`${API_PREFIX}/api/user/${userId}/bookmarks`);
            if (response.status === 200) {
                const data = await response.json();
                setListedBookmarkIds(data.bookmarks);
                const scoreResponse = await axios.post(`${API_PREFIX}/api/scores`, { scoreIds: data.bookmarks });
                setListedBookmark(scoreResponse.data);
            } else {
                console.error('Failed to get bookmarks.');
            }
        } catch (error) {
            console.error('There was an error!', error);
            setListedBookmark([]);
        } finally {
            setLoadingBookmark(false);
        }
    }, [userId]);

    useEffect(() => {
        fetchUserId();
    }, []);

    useEffect(() => {
        if (userId) {
            getListedBookmark();
        }
    }, [userId, getListedBookmark]);

    useEffect(() => {
        if (listedBookmark.length > 0) {
            setListedBookmarkSliced(listedBookmark.slice(0, itemNumToLoad));
            setHasMore(listedBookmark.length > itemNumToLoad);
        }
    }, [listedBookmark]);

    const [listedBookmarkSliced, setListedBookmarkSliced] = useState([]);
    const [loadingSlice, setLoadingSlice] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const itemNumToLoad = 1000;
    const loadDelay = 3000;


    const loadMoreItems = useCallback(() => {
        //console.log("loadMoreItems");
        if (loadingSlice || !hasMore) return;
        setLoadingSlice(true);
        setTimeout(() => {
            const currentLength = listedBookmarkSliced.length;
            const nextLength = Math.min(currentLength + itemNumToLoad, listedBookmark.length);
            setListedBookmarkSliced(prevSliced => [...prevSliced, ...listedBookmark.slice(currentLength, nextLength)]);
            setHasMore(nextLength < listedBookmark.length);
            setLoadingSlice(false);
        }, loadDelay);
    }, [loadingSlice, hasMore, listedBookmarkSliced, listedBookmark]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 100) {
                loadMoreItems();
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [loadMoreItems]);


    return (
        <div className={styles.basic}>
            <CommonHeader key="pageTop" />
            <main>
                <h2 className={styles.pageText}>ブックマーク中の楽譜</h2>
                <div className={styles.scoreCard}>
                    {loadingBookmark ? (
                        <Loading onloading={loadingBookmark} loading_object_name={"ブックマーク中の楽譜"} />
                    ) : listedBookmarkSliced.length === 0 ? (
                        <p>ブックマーク中の楽譜はありません</p>
                    ) : (
                        listedBookmarkSliced.map((result) => (
                            <div className={styles.scoreCardWrapper}>
                            <ScoreCard key={result.scoreId} score_id={result.scoreId} scoreData={result.data} myBookmarkList={listedBookmarkIds} />
                            </div>
                        ))
                    )}
                </div>
                <div className={styles.scoreCard}>
                    {loadingSlice && !loadingBookmark ? (
                        <Loading onloading={loadingSlice} loading_object_name={"ブックマーク中の楽譜"} />
                    ) : (
                        !hasMore && !loadingBookmark && <p>全件をロードしました</p>
                    )}
                </div>
            </main>


        </div>
    )

}

export default PageTop;
