import React, { useState, useEffect } from 'react';
import Button from '../Utils/Button/Button';
import TextInput from '../Utils/Ui/TextInput/TextInput';
import styles from './Dev.module.css';
import FileDropzone from '../FileUploader/FileUploader';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import Modal from 'react-modal';
import DropDownForUserList from '../Utils/Ui/DropDown/DropDownForUserList';
import DropDownForBandList from '../Utils/Ui/DropDown/DropDownForBandList';
import LoadingInRegistration from '../Utils/Loading/LoadingInRegistration';
import { Link, useLocation, useNavigate, } from 'react-router-dom';
import { FaPlus, FaPlusCircle } from 'react-icons/fa';
import { MdDeleteForever } from "react-icons/md";
import { Auth } from 'aws-amplify';

const API_PREFIX = process.env.REACT_APP_API_PREFIX;

const statusMsgMap = {
    'finished': "✅ (3/3)アップロード完了！ファイル解析完了！　",
    'processing': "🔄 (1/3)アップロード中・・・ ファイルをサーバーにアップロードしています。",
    'execed': "🔄 (2/3)アップロード完了！ファイル解析中・・・　ファイルを解析しています。",
    'waiting': "ファイルの差し替えの場合ここに進捗が表示されます"
};


//function Dev({score_id}) {
function Dev({ score_id }) {
    //const score_id = "ef2d6a94-812a-4207-9ae8-1e21770f405b_001";
    //楽譜の登録情報
    const [myScoreId, setMyScoreId] = useState('');
    const [myScoreId_w_version, setMyScoreId_w_version] = useState('');
    const [versionId, setVersionId] = useState('');
    const [scoreName, setScoreName] = useState('');
    const scoreNameMaxLength = 100;
    const [artistName, setArtistName] = useState('');
    const artistNameMaxLength = 100;
    const [difficulty, setDifficulty] = useState(1);
    const [duration_minute, setDuration_minute] = useState(0);
    const [duration_second, setDuration_second] = useState(0);
    const [tempo, setTempo] = useState(-1);
    const [tempo_str, setTempoStr] = useState("");
    const [partMale, setPartMale] = useState(0);
    const [partFemale, setPartFemale] = useState(0);
    const [haveVp, setHaveVp] = useState(false);
    const [needVp, setNeedVp] = useState(true);
    const [isZeintaikyoku, setIsZeintaikyoku] = useState(false);
    const [isChangeLead, setIsChangeLead] = useState(false);
    const [voiceRanges, setVoiceRanges] = useState('');
    const [arrangerId, setArrangerId] = useState('');
    const [initarrangerId, setInitArrangerId] = useState('');
    const [selectedBands, setSelectedBands] = useState([{ band_id: { S: '' }, bandname: { S: '' } }]);
    const [initBands, setInitBands] = useState([{ band_id: { S: '' }, bandname: { S: '' } }]);
    const [accesibility, setAccesibility] = useState(0);
    const [tags, setTags] = useState(['']);
    const tagStrMaxLength = 20;
    const [initialTags, setInitialTags] = useState(['']);
    const [parentScoreid, setParentScoreid] = useState(null);
    const [childScoreid, setChildScoreid] = useState('');
    const [youtubeLinks, setYoutubeLinks] = useState(''); //実際にDBに登録するカンマ区切りの文字列
    const [youtubeLinksArr, setYoutubeLinksArr] = useState([]) //ページで使用する配列形式
    const [initialYts, setInitialYts] = useState(['']);
    const [versionName, setVersionName] = useState(null);
    const versionNameMaxLength = 30;
    const [initialVersionName, setInitialVersionName] = useState(null);
    const [description, setDescription] = useState('');
    const descriptionMaxLength = 410;
    const [registered_userid, setRegisterdUserId] = useState('')

    //ページの機能
    const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [isUploadErrorMsgModalOpen, setIsUploadErrorMsgModalOpen] = useState(false);
    const [isUnFilledModalOpen, setIsUnFilledModalOpen] = useState(false);
    const [isSimpleErrorMsgModalOpen, setIsSimpleErrorMsgModalOpen] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const getMyUserId = async () => {
        try {
            // ログイン中のユーザー情報を取得
            const currentUserInfo = await Auth.currentAuthenticatedUser();
            const cognitoId = currentUserInfo.signInUserSession.idToken.payload.sub;

            // Cognito IDからユーザーIDを取得
            const userIdResponse = await axios.get(`${API_PREFIX}/api/user/whoami/${cognitoId}`);
            const userId = userIdResponse.data.userId;
            setRegisterdUserId(userId);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    //ファイル状況チェック関数
    function useFileStatus(fileId) {
        const [statusMessage, setStatusMessage] = useState('waiting');
        const [resultData, setResultData] = useState(null);

        useEffect(() => {
            let statusInterval = null;

            if (fileId) {
                setStatusMessage('ファイルをアップロードしています...');
                const checkStatus = () => {
                    axios.get(`${API_PREFIX}/api/status/${fileId}`)
                        .then(res => {
                            const { status, data } = res.data;
                            setStatusMessage(status);
                            if (status === 'finished' || statusMessage === 'finished') {
                                clearInterval(statusInterval);
                            }
                        })
                        .catch(err => {
                            console.error('Status check failed:', err);
                            setStatusMessage('エラーが発生しました。');
                            setIsUploadErrorMsgModalOpen(true);
                            clearInterval(statusInterval);
                            setErrorMsg(err);
                            setInfoReceived(true);
                        });
                };
                statusInterval = setInterval(checkStatus, 1000); //1秒ごと
            }
            return () => clearInterval(statusInterval);
        }, [fileId]);
        return [statusMessage, resultData];
    }

    const checkInterval = 1000; //処理状況を確認する間隔（[ms]）
    const [fileId, setFileId] = useState(null); // アップロード中のファイルIDを保持するための状態
    const [infoReceived, setInfoReceived] = useState(true); //デバッグのためtrue
    const [statusMessage, resultData] = useFileStatus(fileId); //状態監視
    const [confirmOkPressed, setConfirmOkPressed] = useState(false);

    //タグサジェスト用
    const [allTags, setAllTags] = useState([]);

    const location = useLocation();
    const navigate = useNavigate();
    //変更点記録用
    const [changedPropertyList, setChangedPropertyList] = useState([]);

    const [selectedScoreMetadata, setSelectedScoreMetadata] = useState([]);
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const [isErrorOcurred, setIsErrorOcurred] = useState(false); //楽譜を更新しすぎ(999以降になった)

    const [arrangerName, setArrangerName] = useState("");


    //プレビューpdf
    const [pdfParsed, setPdfParsed] = useState(false);
    const [previewPdfLink, setPreviewPdfLink] = useState(null);

    //バージョンID更新関数
    async function getNextVersion(score_id) {
        // 文字列が"_(3桁の数字)"の形式に一致するか確認

        /*if (!/^_\d{3}$/.test(versionStr)) {
            throw new Error("Invalid version string format");
        }

        // アンダースコアを除いて数字部分のみを取得
        const numberPart = versionStr.slice(1); // 先頭のアンダースコアを削除

        // 数字部分を整数に変換
        let number = parseInt(numberPart, 10); */
        let number = await getVersions(score_id);
        //console.log("my number = ", number);
        // 数字が999ならエラーを投げる
        if (number === 999) {
            return null;
        }

        // 数字をインクリメント
        number++;
        //console.log("new number = ", number);

        // 新しいバージョン文字列を生成（ゼロ埋めして3桁に保つ）
        const newVersionStr = `_${number.toString().padStart(3, '0')}`;
        //console.log("new version str = ", newVersionStr);
        return newVersionStr;
    }

    //存在するバージョンの最大値
    const getVersions = async (score_id_x) => {
        try {
            const response = await axios.get(`${API_PREFIX}/api/score/versions/${score_id_x}`);
            const data = response.data;
            //console.log('versions content got:', response.data);

            if (data.length === 0) {
                return 0;
            }

            // バージョンIDの最大値を計算
            const maxVersionId = Math.max(...data.map(version => parseInt(version.versionId, 10)));
            return maxVersionId;

        } catch (error) {
            console.error('Error getting version:', error.response ? error.response.data : error.message);
            setErrorMsg(`Error getting version: ${error.response ? error.response.data : error.message}`);
            setIsSimpleErrorMsgModalOpen(true);
            return -1;
        }
    }

    const [banditems, setBanditems] = useState([]);
    const getScoreInfo = async () => {

        //先にバンド一覧
        const requestOptions0 = {
            method: 'GET',
        };
        let bandlist = []
        try {
            const response = await fetch(`${API_PREFIX}/api/band/bandlist`, requestOptions0);
            if (response.status === 200) {
                const data = await response.json(); // APIからのレスポンスデータをJSONとしてパース
                setBanditems(data); // パースしたデータを状態にセット
                bandlist = data;
            } else {
                console.error('Failed to get band item.');
            }
        } catch (error) {
            console.error('There was an error!', error);
            setErrorMsg(error);
            setIsSimpleErrorMsgModalOpen(true);
            return;
        }

        const requestOptions = {
            method: 'GET',
        };
        try {
            //仮置きしてるだけなので、あとでscore_id取ってくる形式に変更してください//
            //const score_id = "cd3f2b71-a1cd-43bf-b8e6-1b98ef2e8b37";
            //setMyScoreId(score_id);
            const response = await fetch(`${API_PREFIX}/api/score/${score_id}`, requestOptions);
            const data = await response.json();
            setSelectedScoreMetadata(data);
            //console.log(data);
            setScoreData(data);
            // アレンジャーの情報を取得
            const arrangersResponse = await fetch(`${API_PREFIX}/api/arranged/score/${score_id}/users`, requestOptions);
            const arrangersData = await arrangersResponse.json();
            //console.log("Arrangers: ", arrangersData);
            if (arrangersData.length > 0) {
                //console.log("length > 0");
                setArrangerId(arrangersData[0]);
                setInitArrangerId(arrangersData[0]);
            }

            // 演奏バンドの情報を取得
            const bandsResponse = await fetch(`${API_PREFIX}/api/performed/score/${score_id}/bands`, requestOptions);
            const bandsData = await bandsResponse.json();
            //console.log("Bands: ", bandsData, "bandlist: ", bandlist);
            // バンドデータを適切な形式に変換してからステートにセット
            if (bandsData.length > 0) {
                //console.log("band length > 0");
                const formattedBands = bandsData.map(data => bandlist.find(band => band.band_id.S === data));
                formattedBands.push({ band_id: { S: '' }, bandname: { S: '' } });
                setSelectedBands(formattedBands);
                setInitBands(formattedBands);
                //console.log(formattedBands);
            }


        } catch (error) {
            console.error('Failed to get score item.', error);
            setErrorMsg(error);
            setIsSimpleErrorMsgModalOpen(true);
        }

        //タグや演奏バンドなどの情報もゆくゆくは取得しなくてはいけない(2024.4.25)
    }

    function setScoreData(data) {// 楽譜情報をsetしていく
        //setMyScoreId(data.score_id || ""); これらはもっと前でやる？
        //setVersionId(data.version_id || "");
        //setMyScoreId_w_version(data.score_id + '_' + data.version_id || "");
        setVersionName(data.version_name || "");
        setInitialVersionName(data.version_name || "");
        setScoreName(data.score_name || "");
        setArtistName(data.artist_name || "");
        setDifficulty(Number(data.difficulty) || -1); //左辺が0で-1のときはエラーになるべき
        const duration_in_seconds = Number(data.duration);
        const duration_minutes = Math.floor(duration_in_seconds / 60);
        const duration_seconds = Math.ceil(duration_in_seconds % 60);
        setTempo(Number(data.tempo));
        setTempoStr(data.tempo_str);
        setDuration_minute(duration_minutes);
        setDuration_second(duration_seconds);
        setPartMale(Number(data.part_male) ?? -1); //エラーになれ
        setPartFemale(Number(data.part_female) ?? -1); //エラーになれ
        setHaveVp(data.have_vp === "true");
        setNeedVp(data.need_vp === "true");
        setIsZeintaikyoku(data.is_zentaikyoku === "true");
        setIsChangeLead(data.is_changeLead === "true");
        setVoiceRanges(data.voice_ranges || "");
        setAccesibility(Number(data.accesibility) ?? -1); //falseのときはエラーを吐くべき！！
        setParentScoreid(data.parent_scoreid || "");
        setChildScoreid(data.child_scoreid || "");
        setYoutubeLinks(data.youtube_links || "");
        setYoutubeLinksArr(data.youtube_links ? data.youtube_links.split(",").map(link => link.trim()) : []);
        setInitialYts(data.youtube_links ? data.youtube_links.split(",").map(link => link.trim()) : []);
        setDescription(data.description || "");
    }
    //使用音域から男声女声を予想する関数
    const judgeMaleOrFemale = (lowestNote, highestNote, referenceNote = "C4") => {
        if (lowestNote === "-") {
            //VPはこれで来るはず
            return "vp";
        }
        // 音階の配列を動的に生成する
        const notes = [];
        const noteNames = ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"];
        for (let octave = 0; octave <= 8; octave++) {
            for (let i = 0; i < noteNames.length; i++) {
                notes.push(`${noteNames[i]}${octave}`);
            }
        }
        // 各音のインデックスを取得
        const refIndex = notes.indexOf(referenceNote);
        const lowIndex = notes.indexOf(lowestNote);
        const highIndex = notes.indexOf(highestNote);
        // 基準音に対する低い音と高い音の数を計算
        const lowerCount = refIndex - lowIndex;
        const higherCount = highIndex - refIndex;
        // 低い音の方が多ければ男性（male）、そうでなければ女性（female）と判断
        return lowerCount > higherCount ? "male" : "female";
    };

    const parseBody = (part) => {
        return judgeMaleOrFemale(part.lowest, part.highest, "C4");
    };

    //ファイル処理（musicxmlのパース）を受け取る
    const applyReturnedData = (data, apiEp) => {
        if (apiEp.includes("pdf") || apiEp.includes("musicxml")) {
            //msczでなければアップロードするだけなので
            setInfoReceived(true);
            return;
        }

        //setScoreName(data.title); //バージョン変更でも曲名は変更不可にしたい
        const song_duration = Number(data.duration);
        const song_tempo = Number(data.bpm);
        const bodyParts = Object.values(data.body).map(part => {
            return `${part.PartName} (${part.lowest}~${part.highest})`;
        }); //将来に備えた文字列
        const partParsed = Object.values(data.body).map(part => {
            return parseBody(part);
        });//["male","female","female","vp"];
        const countOccurrences = (array, value) => {
            return array.reduce((acc, current) => acc + (current === value ? 1 : 0), 0);
        };
        // femaleの数をカウント
        const femaleCount = countOccurrences(partParsed, "female");
        setPartFemale(femaleCount);
        // maleの数をカウント
        const maleCount = countOccurrences(partParsed, "male");
        setPartMale(maleCount);
        // vpが含まれているか確認
        const containsVP = partParsed.includes("vp");
        setHaveVp(containsVP);

        setDuration_minute(Math.floor(song_duration / 60));
        setDuration_second(Math.ceil(song_duration % 60));
        setTempo(song_tempo);
        //テンポを設定

        if (song_tempo < 90) {
            setTempoStr("ローテンポ");
        } else if (song_tempo < 140) {
            setTempoStr("ミドルテンポ");
        } else {
            setTempoStr("アップテンポ");
        }
        setInfoReceived(true);
        setIsFileUploaded(true);
        return;
    };

    //ファイルアップロード時に発火する関数
    const onFileChange = (files) => {
        const fileId = uuidv4(); // UUIDを生成
        setIsFileUploadModalOpen(false);
        setVersionName(""); //バージョンが変わるので新しく書いてもらう
        setInitialVersionName("");
        setFileId(fileId);
        const formData = new FormData();
        const originalFile = files[0];
        const fileExtension = originalFile.name.split('.').pop().toLowerCase();
        let apiEndpoint;

        switch (fileExtension) {
            case 'pdf':
                apiEndpoint = `${API_PREFIX}/api/upload/pdf`;
                break;
            //case 'musicxml':
            //    apiEndpoint = `${API_PREFIX}/api/upload/musicxml`;
            //    break;
            case 'mscz':
                apiEndpoint = `${API_PREFIX}/api/upload/mscz`;
                break;
            default:
                //console.log('Unsupported file type.');
                return;
        }
        const newFile = new File([originalFile], encodeURIComponent(originalFile.name), {
            type: originalFile.type,
            lastModified: originalFile.lastModified
        });
        formData.append("file", newFile);
        formData.append("fileId", fileId); // フォームデータにfileIdを追加

        setInfoReceived(false);
        axios
            .post(apiEndpoint, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((res) => {
                //setStatusMessage('アップロード完了。処理を開始しています...');
                if (apiEndpoint.includes("mscz")) {
                    applyReturnedData(res.data, apiEndpoint);
                    //第2ラウンド　pdf変換
                    const postProcessData = { fileId: fileId, backend_originalname: res.data.backend_originalname, backend_filename: res.data.backend_filename };
                    axios.post(`${API_PREFIX}/api/upload/file_transform`, postProcessData, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                        .then((res) => {
                            //console.log('2nd script processing end');
                            let pdf_url = res.data;
                            setPdfParsed(true);
                            setPreviewPdfLink(pdf_url);
                        })
                        .catch((err) => {
                            console.error('Error calling postprocess endpoint:', err);
                            setErrorMsg(err);
                            setIsSimpleErrorMsgModalOpen(true);
                        });
                } else {
                    applyReturnedData("null", apiEndpoint);
                    setPdfParsed(true);
                }
            })
            .catch((err) => {
                //setStatusMessage('エラーが発生しました。');
                console.error(err);
                setErrorMsg(err);
                //setIsSimpleErrorMsgModalOpen(true); -> ファイルアップロードの方のモーダルで十分
            });
    };

    const [useritems, setUseritems] = useState([]);
    const arrangerChange = (value) => {
        setArrangerId(value);
        const changedPropertyList_cp = changedPropertyList.filter((changedProperty) => changedProperty.name != "編曲者");
        if (initarrangerId != value) {
            const arranger_from_data = useritems.find((user) => user.user_id.S === initarrangerId);
            //console.log("arranger_from_data :", arranger_from_data);
            const arranger_to_data = useritems.find((user) => user.user_id.S === value);
            //console.log("arranger_to_data", arranger_to_data);
            const arranger_from = arranger_from_data === undefined ? "ユーザー名未定" : `${arranger_from_data.ki.N}期_${arranger_from_data.nickname.S}`;
            const arranger_to = arranger_to_data === undefined ? "ユーザー名未定" : `${arranger_to_data.ki.N}期_${arranger_to_data.nickname.S}`;
            changedPropertyList_cp.push({
                name: "アレンジャー", dbname: "ignore", from: `${arranger_from}`, to: `${arranger_to}` //いったんIDに
            });
        }
        setChangedPropertyList(changedPropertyList_cp);
    }
    /*
    const bandChange = (value) => {
        setBandId(value);
    }*/
    const setHaveNeedVp = (value) => {
        if (value == 0) {
            setNeedVp(true);
            setHaveVp(true);
        } else if (value == 1) {
            setNeedVp(true);
            setHaveVp(false);
        } else {
            setNeedVp(false);
            setHaveVp(false);
        }
    }
    const getUserList = async () => {
        const requestOptions = {
            method: 'GET',
        };
        try {
            const response = await fetch(`${API_PREFIX}/api/user/userlist`, requestOptions);
            if (response.status === 200) {
                const data = await response.json(); // APIからのレスポンスデータをJSONとしてパース
                data.sort((a, b) => {
                    if (a.ki.N === b.ki.N) {
                        // xが等しい場合はyで比較
                        return a.nickname.S - b.nickname.S;
                    }
                    // xで比較
                    return a.ki.N - b.ki.N;
                });
                setUseritems(data); // パースしたデータを状態にセット

            } else {
                console.error('Failed to get user item.');
            }
        } catch (error) {
            console.error('There was an error!', error);
            setErrorMsg(error);
            setIsSimpleErrorMsgModalOpen(true);
        }
        return;
    }

    const getTagStrFromId = async (tag_id) => {
        const requestOptions = {
            method: 'GET',
        };
        try {
            const response = await fetch(`${API_PREFIX}/api/tags/${tag_id}`, requestOptions);
            const data = await response.json();
            //console.log("getTagStrFromId:", data);
            return data.content;
        } catch (error) {
            console.error("Cannot get tag string from ", tag_id);
        }
    }

    const getTagStrFromIdWrapper = async (tag_id_list) => {
        //console.log("tag_id_list.length:", tag_id_list.length);
        const tagStrList_init = [];
        for (var i = 0; i < Math.min(tag_id_list.length); i++) {
            const tag_str = await getTagStrFromId(tag_id_list[i]);
            //console.log("tag_str:", tag_str);
            tagStrList_init.push(tag_str);
        }
        setTags(tagStrList_init);
        setInitialTags(tagStrList_init);
        //console.log(tagStrList_init);
    }

    //scoreに紐づけされてる既存タグ取得
    const getTags = async () => {
        const requestOptions = {
            method: 'GET',
        };
        try {
            const response = await fetch(`${API_PREFIX}/api/score/${score_id}/tags`, requestOptions);
            if (response.status === 200) {

                const data = await response.json();
                getTagStrFromIdWrapper(data);
            } else {
                console.error(response.status, 'Failed to get tag item.');
            }
        } catch (error) {
            console.error('There was an error!', error);
        }
        return;
    };

    //既存タグ一覧取得
    const getTagList = async () => {
        const requestOptions = {
            method: 'GET',
        };
        try {
            const response = await fetch(`${API_PREFIX}/api/tags`, requestOptions);
            if (response.status === 200) {

                const data = await response.json();
                const tag_contents = data.map(item => item.content);
                tag_contents.sort();
                setAllTags(tag_contents);
            } else {
                console.error(response.status, 'Failed to tag band item.');
            }
        } catch (error) {
            console.error('There was an error!', error);
        }
        return;
    };
    //バンドの登録周り
    const [addedBands, setAddedBands] = useState([]);
    const [deletedBands, setDeletedBands] = useState([]);
    const BandEditHistory = (updatedBands) => {
        const newAddedBands = [];
        const newDeletedBands = [];
        const newselectedBands = updatedBands;

        //console.log("Initial bands:", initBands);
        //console.log("Selected bands:", newselectedBands);

        initBands.forEach((initBand) => {
            if (!newselectedBands.some(band => band.band_id.S === initBand.band_id.S) && !newDeletedBands.some(band => band.band_id.S === initBand.band_id.S) && initBand.band_id.S !== '') {
                newDeletedBands.push(initBand);
            }
        });

        newselectedBands.forEach((selectedBand) => {
            if (!initBands.some(band => band.band_id.S === selectedBand.band_id.S) && !newAddedBands.some(band => band.band_id.S === selectedBand.band_id.S) && selectedBand.band_id.S !== '') {
                newAddedBands.push(selectedBand);
            }
        });

        //console.log("New added bands:", newAddedBands);
        //console.log("New deleted bands:", newDeletedBands);

        setAddedBands(newAddedBands);
        setDeletedBands(newDeletedBands);
    }
    const handleBandChange = (index, newBandId) => {
        const subHandleBandChange = async () => {
            let updatedBands = [...selectedBands];
            updatedBands[index] = banditems.find(band => band.band_id.S === newBandId) || { band_id: { S: '' }, bandname: { S: '' } };

            // 入力が削除された場合はそのフィールドを削除
            if (newBandId === '') {
                updatedBands = updatedBands.filter((_, i) => i !== index);
                if (updatedBands.length === 0) {
                    updatedBands.push({ band_id: { S: '' }, bandname: { S: '' } }); // 全て削除された場合は新しい空フィールドを追加
                }
            } else if (index === selectedBands.length - 1 && selectedBands.length < 10) {
                // 最後のフィールドに入力がある場合は新しい空フィールドを追加
                updatedBands.push({ band_id: { S: '' }, bandname: { S: '' } });
            }
            setSelectedBands(updatedBands);
            return updatedBands
        }
        subHandleBandChange().then((updatedBands) => {
            BandEditHistory(updatedBands);
        })
    };
    //タグの登録周り
    const [addedTags, setAddedTags] = useState([]);
    const [deletedTags, setDeletedTags] = useState([]);

    const TagEditHistory = () => {
        const newAddedTags = [];
        const newDeletedTags = [];
        initialTags.map((tag) => {
            if (!tags.includes(tag)) {
                newDeletedTags.push(tag);
            }
        });
        tags.map((tag) => {
            if (!initialTags.includes(tag)) {
                newAddedTags.push(tag);
            }
        });
        setAddedTags(newAddedTags);
        setDeletedTags(newDeletedTags);
        //console.log(`addedTags:${addedTags}, deletedTags:${deletedTags}`);
    }

    const handleTagChange = (index, event) => {
        const userInput = event.target.value;
        const newTags = [...tags];
        newTags[index] = userInput;
        /*
        if (userInput.length === 0) {
            // 入力が0文字の場合はその入力フィールドを削除
            newTags = newTags.filter((_, i) => i !== index);
            if (newTags.length === 0) {
                // 全ての入力フィールドが削除された場合、新しい空のフィールドを追加
                newTags.push('');
            }
        } else if (index === tags.length - 1 && tags.length < 10) {
            // 最後の入力フィールドに文字がある場合、新しい空のフィールドを追加（最大10個まで）
            newTags.push('');
        }*/
        setTags(newTags);
        TagEditHistory();
    };
    const handleTagBoxAdd = (event) => {
        event.preventDefault();
        const newTags = [...tags];
        if (tags.length < 10) {
            newTags.push('');
        }
        setTags(newTags);
        TagEditHistory();
    }

    const handleTagBoxDelete = (event, index) => {
        event.preventDefault();
        const newTags = tags.filter((tag, i) => i !== index);
        setTags(newTags);
        TagEditHistory();
    }

    //YouTube動画の登録周り
    const [addedYts, setAddedYts] = useState([]);
    const [deletedYts, setDeletedYts] = useState([]);
    const YtEditHistory = (currentYtArr) => {
        const newAddedYts = [];
        const newDeletedYts = [];
        initialYts.map((yt) => {
            if (!currentYtArr.includes(yt)) {
                newDeletedYts.push(yt);
            }
        });
        currentYtArr.map((yt) => {
            if (!initialYts.includes(yt)) {
                newAddedYts.push(yt);
            }
        });
        setAddedYts(newAddedYts);
        setDeletedYts(newDeletedYts);
        //console.log(`addedYts:${newAddedYts}, deletedYts:${newDeletedYts}`);
    }

    const handleYtChange = (index, event) => {

        const userInput = event.target.value;
        //console.log("userInput: ", userInput);
        const newYts = [...youtubeLinksArr];
        newYts[index] = userInput;
        //console.log("newYts, ");
        //console.log(newYts);
        /*
        if (userInput.length === 0) {
            // 入力が0文字の場合はその入力フィールドを削除
            newTags = newTags.filter((_, i) => i !== index);
            if (newTags.length === 0) {
                // 全ての入力フィールドが削除された場合、新しい空のフィールドを追加
                newTags.push('');
            }
        } else if (index === tags.length - 1 && tags.length < 10) {
            // 最後の入力フィールドに文字がある場合、新しい空のフィールドを追加（最大10個まで）
            newTags.push('');
        }*/
        setYoutubeLinksArr(newYts);
        YtEditHistory(newYts);
        //console.log(youtubeLinksArr);
        //console.log(`in handlechange, addedYts:${addedYts}, deletedYts:${deletedYts}`);
    };
    const handleYtBoxAdd = (event) => {
        event.preventDefault();
        const newYts = [...youtubeLinksArr];
        if (youtubeLinksArr.length < 3) {
            newYts.push('');
        } else {
            alert("登録できるのは3つまでです");
        }
        setYoutubeLinksArr(newYts);
        YtEditHistory(newYts);
    }

    const handleYtBoxDelete = (event, index) => {
        event.preventDefault();
        const newYts = youtubeLinksArr.filter((tag, i) => i !== index);
        setYoutubeLinksArr(newYts);

        const changedPropertyList_cp = changedPropertyList.filter((changedProperty) => changedProperty.name !== "演奏動画URL");
        if (selectedScoreMetadata.youtube_links !== newYts.join(",")) {
            setChangedPropertyList([...changedPropertyList_cp, {
                name: "演奏動画URL", dbname: "youtube_links", from: selectedScoreMetadata.youtube_links, to: newYts.join(",")
            }])
        } else {
            setChangedPropertyList(changedPropertyList_cp);
        }
        YtEditHistory(newYts);
    }

    const MustFieldFilled = () => {
        //必須項目を埋めているか確認
        //埋めてない項目を見つけたらそれをメッセージとして
        if (versionName.length === 0) { return "バージョン名は必須項目です"; }
        if (scoreName.length === 0) { return "曲名は必須項目です"; }
        //if(artistName.length == 0){return "アーティスト名は必須項目です";}
        if (difficulty === -1) { return "難易度設定は必須項目です"; }
        if (!(duration_minute >= 0)) { return "曲尺に不正な値が入力されています" }
        if (!(duration_second >= 0)) { return "曲尺に不正な値が入力されています" }
        if (!(duration_minute * 60 + duration_second > 0)) { return "曲尺を（だいたいの長さでもいいので）埋めてください"; }
        if (partMale === -1 || partMale === -1) { return "パート数を設定してください"; }
        if (Number(partMale) + Number(partFemale) < 2) { return "パート数が少なすぎます"; }
        if (tempo_str === "ng") { return "テンポを選択してください"; }

        return "";
    }
    //最初の登録ボタン押下
    const [unFilledFields, setUnFilledFields] = useState("まだ情報が登録されていません");
    const onConfirmButtonFirst = () => {
        //曲尺を計算
        const unfilled = MustFieldFilled();
        setUnFilledFields(unfilled);
        if (unfilled.length === 0) {
            setIsConfirmModalOpen(true);
            TagEditHistory();
        } else {
            console.error(unfilled);
            setIsUnFilledModalOpen(true);
        }

    };
    //「登録しますか？→はい」
    const [registrationFailed, setRegistrationFailed] = useState(false);
    const [registrationStatus, setRegistrationStatus] = useState([]);
    const onConfirmButtonLast = async () => {
        let isNext = false;
        let registration_failed = false;
        let registration_status = [];
        //const next_versionId = getNextVersion("_" + myScoreId_w_version.split("_")[1]);
        const next_versionId = await getNextVersion(myScoreId_w_version);
        //console.log("isFileUploaded:", isFileUploaded);
        if (isFileUploaded) {
            //ファイル更新されて版の更新パターン
            isNext = true;

            if (!next_versionId) {
                setIsErrorOcurred(true);//ここの処理いずれやる（モーダル出すとか）
                setErrorMsg("バージョンIDが取得できません");
                setIsSimpleErrorMsgModalOpen(true);
                return;
            }
            const scoreDataToCreate = {
                score_id: myScoreId,
                version_id: next_versionId,
                version_name: versionName,
                score_name: scoreName,
                artist_name: artistName,
                difficulty: Number(difficulty),
                duration: Number(duration_minute) * 60 + Number(duration_second),
                part_male: Number(partMale),
                part_female: Number(partFemale),
                tempo: tempo,
                tempo_str: tempo_str,
                is_changeLead: isChangeLead,
                have_vp: haveVp,
                need_vp: needVp,
                is_zentaikyoku: isZeintaikyoku,
                voice_ranges: voiceRanges,
                accesibility: Number(accesibility),
                parent_scoreid: parentScoreid,
                child_scoreid: null,
                youtube_links: youtubeLinksArr.join(","), //youtubeLinks, //ユーザーを信じてベタ入れベタだし　修正するなら→yt_link_preprocess(youtubeLinks),
                description: description,
                registered_userid: registered_userid
            };
            //ファイルアップロード
            const formData = new FormData();
            formData.append("fileId", fileId); // フォームデータにfileIdを追加
            formData.append("scoreId", myScoreId + next_versionId);
            //formData.append("scoreId", myScoreId_w_version); // フォームデータにscoreIdを追加
            //formData.append("versionId", "_001"); // フォームデータにversionIdを追加
            axios
                .post(`${API_PREFIX}/api/file_register`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => {
                    //console.log(res.data);
                })
                .catch((err) => {
                    console.error(err);
                    registration_failed = true;
                    registration_status.push("ファイルアップロードに失敗しました");
                });
            try {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(scoreDataToCreate)
                };
                const response = await fetch(`${API_PREFIX}/api/score`, requestOptions);
                if (response.status === 200) {
                    const data = await response.json();
                    setMyScoreId(data.scoreId);
                } else {
                    console.error('楽譜登録に失敗');
                }
            } catch (error) {
                console.error('There was an error!', error);
                registration_failed = true;
                registration_status.push("楽譜情報登録に失敗しました");
            }
        } else {
            //ファイルの更新はなく、メタデータ編集のみ（楽譜ID変わらず）
            //楽譜情報
            //changedPropertyListは[{name:<属性名>, dbname: <dynamodbの属性名>, from:旧データの値 ,to:更新したい値},]のリスト
            //dbnameが"ignore"のものは登録しない
            const changedInfoDict = {}; //変更点を表すリストchangedPropertyListからAPI用のdict形式へ
            changedPropertyList.forEach(change => {
                if (change.dbname !== "ignore") {
                    changedInfoDict[change.dbname] = change.to;
                }
            });
            //console.log(changedPropertyList);
            try {

                const requestOptions = {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(changedInfoDict)
                };
                const response = await fetch(`${API_PREFIX}/api/score/${myScoreId_w_version}`, requestOptions);

                if (!response.ok) {
                    throw new Error(`楽譜情報の更新に失敗しました: ${response.statusText}`);
                }
            } catch (error) {
                console.error("楽譜情報更新中のエラー: ", error);
                registration_failed = true;
                registration_status.push("楽譜情報更新に失敗しました");
            }
        }
        //アレンジャー、演奏バンド、タグをrelのDBへ
        //バージョン更新にしろバージョン内編集にしろ共通して行う
        //アレンジャー
        let myId;
        if (isNext) {
            myId = myScoreId + next_versionId;
        } else {
            myId = myScoreId_w_version;
        }
        if (arrangerId !== '') {
            //アレンジャーが指定されてる場合
            try {
                const requestOptions = {
                    method: 'PUT'
                };
                //console.log("arrangerId:",arrangerId);
                
                const response = await fetch(`${API_PREFIX}/api/arranged/user/${arrangerId}/score/${myId}`, requestOptions);
                if (!response.ok) {
                    throw new Error(`アレンジャーID ${arrangerId} の送信に失敗しました: ${response.statusText}`);
                } //else {
                    //console.log("アレンジャーID登録成功");
                //}
            

            } catch (error) {
                console.error('アレンジャー登録送信中のエラー:', error);
                registration_failed = true;
                registration_status.push("アレンジャー情報登録に失敗しました");
            }
        }else{
            //アレンジャーが指定されてない場合
            try {
                const requestOptions = {
                    method: 'DELETE'
                };
                //console.log("arrangerId:",arrangerId);
                
                const response = await fetch(`${API_PREFIX}/api/arranged/user/score/${myId}`, requestOptions);
                if (!response.ok) {
                    throw new Error(`アレンジャーの削除に失敗しました: ${response.statusText}`);
                } //else {
                    //console.log("アレンジャーID登録成功");
                //}
            

            } catch (error) {
                console.error('アレンジャー削除送信中のエラー:', error);
                registration_failed = true;
                registration_status.push("アレンジャー情報削除に失敗しました");
            }
        }

        //演奏バンド
        try {
            const requestOptionsDelete = {
                method: 'DELETE'  // メソッドをDELETEに指定
            };
            const requestOptions = {
                method: 'POST'
            };
            // 既存の登録を削除
            const deleteResponse = await fetch(`${API_PREFIX}/api/performed/score/${myId}`, requestOptionsDelete);
            if (!deleteResponse.ok) {
                throw new Error(`既存の演奏バンド登録の削除に失敗しました: ${deleteResponse.statusText}`);
            }
            //新規で演奏バンドを登録し直す
            const setSelectedBands = new Set(selectedBands)
            try {
                for (const band of setSelectedBands) {
                    const curr_bandid = band?.band_id?.S || "";
                    if (curr_bandid.length > 0) {
                        const response = await fetch(`${API_PREFIX}/api/performed/band/${curr_bandid}/score/${myId}`, requestOptions);

                        if (!response.ok) {
                            throw new Error(`バンドID ${curr_bandid} の送信に失敗しました: ${response.statusText}`);
                        }
                    }
                }
            } catch (error) {
                console.error("エラーが発生しました:", error);
                // エラーハンドリングのコードをここに追加
            }
        } catch (error) {
            console.error('バンドパフォーマンス送信中のエラー:', error);
            registration_failed = true;
            registration_status.push("バンド情報登録に失敗しました");
        }
        //タグ
        try {
            // 既存の登録を削除
            const requestOptionsDelete = {
                method: 'DELETE'  // メソッドをDELETEに指定
            };
            const deleteResponse = await fetch(`${API_PREFIX}/api/tag/score/${myId}`, requestOptionsDelete);
            if (!deleteResponse.ok) {
                throw new Error(`既存のタグの削除に失敗しました: ${deleteResponse.statusText}`);
            }
            for (const tag of tags) {
                if (tag !== '') {
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ content: tag })
                    };
                    const response = await fetch(`${API_PREFIX}/api/score/${myId}/tag`, requestOptions);

                    if (!response.ok) {
                        throw new Error(`タグ ${tag} の送信に失敗しました: ${response.statusText}`);
                    }
                }
            }
        } catch (error) {
            console.error('タグ送信中のエラー:', error);
            registration_failed = true;
            registration_status.push("タグ情報登録に失敗しました");
        }

        setConfirmOkPressed(true);
        if(registration_failed){
            setRegistrationFailed(true);
            setRegistrationStatus(registration_status);
        }else{
            setRegistrationFailed(false);
            setRegistrationStatus([]);
        }
    };
    //「登録しますか？→いいえ」
    const onConfirmButtonLastCancel = () => {
        setIsConfirmModalOpen(false);
    };
    //登録しました！の状態でトップページへ戻る
    const buttonReturnToTopPushed = () => {
        navigate('/home');
    };
    const buttonToUploadAnotherFile = () => {
        setErrorMsg("");
        setIsUploadErrorMsgModalOpen(false);
        setIsFileUploadModalOpen(true);
    };
    const buttonReturnToScoreDetail = () => {
        navigate(`/score/${score_id}`);
    };
    const buttonPressOk = () => {
        setIsUnFilledModalOpen(false);
        setIsSimpleErrorMsgModalOpen(false);
    }


    //pdfプレビュー
    const onPreviewPdf = () => {
        if (previewPdfLink) {
            window.open(previewPdfLink, '_blank');
        } else {
            console.error("PDF URL is not available");
        }
    };

    //youtubeのリンクを所定フォーマットに
    const yt_link_preprocess = (raw_input) => {
        // 区切り文字としてカンマや全角カンマ、スペース、タブなどを考慮します
        const separators = /[,\、\s]+/;

        // ユーザーの入力を分割して配列にします
        let links = raw_input.split(separators).filter(link => link.trim().length > 0);

        // リンク数が3個を超える場合はエラーメッセージを返します
        if (links.length > 3) {
            throw new Error("Error: You cannot enter more than 3 links.");
        }

        // リンクを連結
        let normalized_input = links.join(',');

        return normalized_input;
    };

    useEffect(() => {
        // ページ読み込み時
        const scoreId_base = score_id.split("_")[0];
        const versionId = "_" + score_id.split("_")[1];
        setMyScoreId(scoreId_base);
        setMyScoreId_w_version(score_id); //score_idでええやろ
        getUserList();
        getScoreInfo();
        getMyUserId();
        getTagList();
        getTags();


    }, [score_id]);

    // 難易度のstring取得
    const difficulty_str = [
        "☆1 (入門)", "☆2 (簡単)", "☆3 (普通)", "☆4 (やや難)", "☆5 (難しい)"
    ];

    //文字数制限アラート用

    const maxLengthCheck = async (str, length) => {
        if(str?.length > length){
            alert(`文字数制限${length}を超えています`);
        }
    }

    useEffect(()=>{
        maxLengthCheck(scoreName, scoreNameMaxLength);
    },[scoreName])

    useEffect(()=>{
        maxLengthCheck(versionName, versionNameMaxLength);
    },[versionName])

    useEffect(()=>{
        maxLengthCheck(artistName, artistNameMaxLength);
    },[artistName])

    useEffect(()=>{
        maxLengthCheck(description, descriptionMaxLength);
    },[description])

    return (
        <div>
            {/*<div className={styles.responsiveBox}>*/}

            <div>
                <Button
                    type='primary3'
                    child='ファイルの差し替えはこちら'
                    onClick={() => setIsFileUploadModalOpen(true)}
                />

            </div>
            {/*
            <p>バージョン（選択）</p>
            <select value={Version} onChange={e => setVersion(e.target.value)}>
                <option value="option1">既存</option>
                <option value="option2">新規</option>
            </select>
            */}
            <p>ステータス： {statusMsgMap[statusMessage] ? statusMsgMap[statusMessage] : statusMessage}.</p>
            {previewPdfLink ? (
                <Button
                    type='normal'
                    child='PDFを確認'
                    onClick={onPreviewPdf}
                />) : (<p></p>)}
            <h2>楽曲情報</h2>
            <div className={styles.info}>
                <form method="" action="">
                    <table>
                        <tbody>
                            <tr>
                                <th>このバージョンの名前*</th>
                                <td><input
                                    type="text"
                                    placeholder="バージョンの名前"
                                    value={versionName}
                                    onChange={(e) => {
                                        const changedPropertyList_cp = changedPropertyList.filter((changedProperty) => changedProperty.name !== "バージョンの名前");
                                        setVersionName(e.target.value);
                                        if (initialVersionName !== e.target.value) {
                                            setChangedPropertyList([...changedPropertyList_cp, {
                                                name: "バージョンの名前", dbname: "version_name", from: initialVersionName, to: e.target.value
                                            }])
                                        } else {
                                            setChangedPropertyList(changedPropertyList_cp);
                                        }
                                    }
                                    } 
                                    maxLength={versionNameMaxLength}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>曲名*</th>
                                <td>{infoReceived ? (<input
                                    type="text"
                                    placeholder="ex) 光るなら"
                                    value={scoreName}
                                    disabled={true}
                                    onChange={(e) => {
                                        setScoreName(e.target.value);
                                        const changedPropertyList_cp = changedPropertyList.filter((changedProperty) => changedProperty.name !== "曲名");
                                        if (selectedScoreMetadata.score_name !== e.target.value) {
                                            setChangedPropertyList([...changedPropertyList_cp, {
                                                name: "曲名", dbname: "score_name", from: selectedScoreMetadata.score_name, to: e.target.value
                                            }])
                                        } else {
                                            setChangedPropertyList(changedPropertyList_cp);
                                        }
                                    }}
                                    maxLength={scoreNameMaxLength}
                                />) :
                                    (<LoadingInRegistration
                                        onloading={!infoReceived}
                                        loading_object_name={"曲名"}
                                    />)}
                                </td>
                            </tr>
                            <tr>
                                <th>アーティスト</th>
                                <td><input
                                    type="text"
                                    placeholder="ex) Goose house"
                                    value={artistName}
                                    onChange={(e) => {
                                        setArtistName(e.target.value);
                                        const changedPropertyList_cp = changedPropertyList.filter((changedProperty) => changedProperty.name !== "アーティスト");
                                        if (selectedScoreMetadata.artist_name !== e.target.value) {
                                            setChangedPropertyList([...changedPropertyList_cp, {
                                                name: "アーティスト", dbname: "artist_name", from: selectedScoreMetadata.artist_name, to: e.target.value
                                            }])
                                        } else {
                                            setChangedPropertyList(changedPropertyList_cp);
                                        }
                                    }}
                                    maxLength={artistNameMaxLength}
                                /></td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>



            <h2>編曲情報</h2>
            <div className={styles.info}>
                <form method="" action="">
                    <table>
                        <tbody>
                            <tr>
                                <th>編曲者</th>
                                <td>
                                    <div>
                                        <DropDownForUserList selectKey={0} defaultValue={arrangerId} useritems={useritems} onChange={arrangerChange} />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>全体曲</th>
                                <td><input
                                    type="checkbox"
                                    checked={isZeintaikyoku}
                                    onChange={e => {
                                        setIsZeintaikyoku(e.target.checked);
                                        const changedPropertyList_cp = changedPropertyList.filter((changedProperty) => changedProperty.name !== "全体曲");
                                        if (selectedScoreMetadata.is_zentaikyoku !== `${e.target.checked}`) {
                                            setChangedPropertyList([...changedPropertyList_cp, {
                                                name: "全体曲", dbname: "is_zentaikyoku", from: selectedScoreMetadata.is_zentaikyoku, to: `${e.target.checked}`
                                            }])
                                        } else {
                                            setChangedPropertyList(changedPropertyList_cp);
                                        }
                                    }}
                                /></td>
                            </tr>
                            <tr>
                                <th>男女構成(VP以外)*</th>
                                <td>{infoReceived ? (
                                    <div>
                                        <select value={partMale} onChange={e => {
                                            setPartMale(e.target.value);
                                            const changedPropertyList_cp = changedPropertyList.filter((changedProperty) => changedProperty.name !== "男声パート数");
                                            if (Number(selectedScoreMetadata.part_male) !== Number(e.target.value)) {
                                                setChangedPropertyList([...changedPropertyList_cp, {
                                                    name: "男声パート数", dbname: "part_male", from: Number(selectedScoreMetadata.part_male), to: Number(e.target.value)
                                                }])
                                            } else {
                                                setChangedPropertyList(changedPropertyList_cp);
                                            }
                                        }} disabled={isZeintaikyoku}>
                                            <option value={0}>男0</option>
                                            <option value={1}>男1</option>
                                            <option value={2}>男2</option>
                                            <option value={3}>男3</option>
                                            <option value={4}>男4</option>
                                            <option value={5}>男5</option>
                                        </select>
                                        <select value={partFemale} onChange={e => {
                                            setPartFemale(e.target.value);
                                            const changedPropertyList_cp = changedPropertyList.filter((changedProperty) => changedProperty.name !== "女声パート数");
                                            if (Number(selectedScoreMetadata.part_female) !== Number(e.target.value)) {
                                                setChangedPropertyList([...changedPropertyList_cp, {
                                                    name: "女声パート数", dbname: "part_female", from: Number(selectedScoreMetadata.part_female), to: Number(e.target.value)
                                                }])
                                            } else {
                                                setChangedPropertyList(changedPropertyList_cp);
                                            }

                                        }} disabled={isZeintaikyoku}>
                                            <option value={0}>女0</option>
                                            <option value={1}>女1</option>
                                            <option value={2}>女2</option>
                                            <option value={3}>女3</option>
                                            <option value={4}>女4</option>
                                            <option value={5}>女5</option>
                                        </select>
                                    </div>) : (<LoadingInRegistration
                                        onloading={!infoReceived}
                                        loading_object_name={"男女構成"}
                                    />)
                                }
                                </td>
                            </tr>
                            <tr>
                                {/*
                                <th>VP想定*</th>
                                <td>
                                    <div>
                                        <select value={needVp} onChange={e => {
                                            setNeedVp(e.target.value);
                                            const changedPropertyList_cp = changedPropertyList.filter((changedProperty) => changedProperty.name !== "VP想定");
                                            if (selectedScoreMetadata.need_vp !== e.target.value) {
                                                setChangedPropertyList([...changedPropertyList_cp, {
                                                    name: "VP想定", dbname: "need_vp", from: selectedScoreMetadata.need_vp, to: e.target.value
                                                }])
                                            } else {
                                                setChangedPropertyList(changedPropertyList_cp);
                                            }

                                        }}>
                                            <option value={true}>有り</option>
                                            <option value={false}>無し</option>
                                        </select></div></td>
                            </tr>
                            <tr>
                                <th>VP記載*</th>
                                <td><div><select value={haveVp} onChange={e => {
                                    setHaveVp(e.target.value);
                                    const changedPropertyList_cp = changedPropertyList.filter((changedProperty) => changedProperty.name !== "VP記載");
                                    if (selectedScoreMetadata.have_vp !== e.target.value) {
                                        setChangedPropertyList([...changedPropertyList_cp, {
                                            name: "VP記載", dbname: "have_vp", from: selectedScoreMetadata.have_vp, to: e.target.value
                                        }])
                                    } else {
                                        setChangedPropertyList(changedPropertyList_cp);
                                    }

                                }} disabled={needVp.toString() !== "true"}>
                                    <option value={true}>有り</option>
                                    <option value={false}>無し</option>
                                </select></div></td>
                                */}
                                <th>VP有無*</th>
                                <td>
                                    <select value={haveVp ? 0 : needVp ? 1 : 2} onChange={e => {
                                        setHaveNeedVp(Number(e.target.value));
                                        const changedPropertyList_cp = changedPropertyList.filter((changedProperty) => changedProperty.name !== "VP記載" || changedProperty.name !== "VP想定" || changedProperty.name !== "VP有無");

                                        if ((selectedScoreMetadata.have_vp === "true") !== (Number(e.target.value) === 0)) {
                                            changedPropertyList_cp.push({
                                                name: "VP記載",
                                                dbname: "have_vp",
                                                from: selectedScoreMetadata.have_vp,
                                                to: Number(e.target.value) === 0 ? "true" : "false"
                                            });
                                        }
                                        if ((selectedScoreMetadata.need_vp === "true") !== (Number(e.target.value) !== 2)) {
                                            changedPropertyList_cp.push({
                                                name: "VP想定",
                                                dbname: "need_vp",
                                                from: selectedScoreMetadata.need_vp,
                                                to: Number(e.target.value) <= 1 ? "true" : "false"
                                            })
                                        }
                                        if ((selectedScoreMetadata.have_vp === "true") !== (Number(e.target.value) === 0) || (selectedScoreMetadata.need_vp === "true") !== (Number(e.target.value) <= 1)) {
                                            changedPropertyList_cp.push({
                                                name: "VP有無",
                                                dbname: "ignore",
                                                from: selectedScoreMetadata.have_vp === "true" ? "VP譜あり" : selectedScoreMetadata.need_vp === "true" ? "VP譜なし(VPあり想定)" : "VP譜なし(VPなし想定)",
                                                to: Number(e.target.value) === 0 ? "VP譜あり" : Number(e.target.value) === 1 ? "VP譜なし(VPあり想定)" : "VP譜なし(VPなし想定)"
                                            })
                                        }
                                        setChangedPropertyList(changedPropertyList_cp);
                                    }}>
                                        <option value={0}>VP譜あり</option>
                                        <option value={1}>VP譜なし（VPあり想定）</option>
                                        <option value={2}>VP譜なし（VPなし想定）</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>曲尺*</th>
                                <td>{infoReceived ? (
                                    <div className={styles.inputDuration}>
                                        <input
                                            className={styles.inputDurationWidth}
                                            type="number"
                                            value={duration_minute}
                                            onChange={(e) => {
                                                setDuration_minute(e.target.value);
                                                const changedPropertyList_cp = changedPropertyList.filter((changedProperty) => changedProperty.name !== "曲尺");
                                                if (Number(selectedScoreMetadata.duration) !== Number(e.target.value) * 60 + Number(duration_second)) {
                                                    setChangedPropertyList([...changedPropertyList_cp, {
                                                        name: "曲尺", dbname: "duration", from: Number(selectedScoreMetadata.duration), to: Number(e.target.value) * 60 + Number(duration_second)
                                                    }])
                                                } else {
                                                    setChangedPropertyList(changedPropertyList_cp)
                                                }
                                            }}
                                        /><p>分</p>
                                        <input
                                            className={styles.inputDurationWidth}
                                            type="number"
                                            value={duration_second}
                                            onChange={(e) => {
                                                setDuration_second(e.target.value);
                                                const changedPropertyList_cp = changedPropertyList.filter((changedProperty) => changedProperty.name !== "曲尺");
                                                if (Number(selectedScoreMetadata.duration) !== Number(duration_minute) * 60 + Number(e.target.value)) {
                                                    setChangedPropertyList([...changedPropertyList_cp, {
                                                        name: "曲尺", dbname: "duration", from: Number(selectedScoreMetadata.duration), to: Number(duration_minute) * 60 + Number(e.target.value)
                                                    }])
                                                } else {
                                                    setChangedPropertyList(changedPropertyList_cp)
                                                }
                                            }}
                                        />
                                        <p>秒</p> </div>) : (<LoadingInRegistration
                                            onloading={!infoReceived}
                                            loading_object_name={"曲尺"}
                                        />)}</td>
                            </tr>
                            <tr>
                                <th>テンポ*</th>
                                <td>{infoReceived ? (
                                    <><select value={tempo_str} onChange={e => {
                                        setTempoStr(e.target.value);
                                        const changedPropertyList_cp = changedPropertyList.filter((changedProperty) => changedProperty.name !== "テンポ");
                                        if (selectedScoreMetadata.tempo_str !== e.target.value) {
                                            setChangedPropertyList([...changedPropertyList_cp, {
                                                name: "テンポ", dbname: "tempo_str", from: selectedScoreMetadata.tempo_str, to: e.target.value
                                            }])
                                        } else {
                                            setChangedPropertyList(changedPropertyList_cp);
                                        }

                                    }}>
                                        <option value={"ng"}>テンポを選択</option>
                                        <option value={"ローテンポ"}>ローテンポ</option>
                                        <option value={"ミドルテンポ"}>ミドルテンポ</option>
                                        <option value={"アップテンポ"}>アップテンポ</option>
                                    </select></>) : (<LoadingInRegistration
                                        onloading={!infoReceived}
                                        loading_object_name={"テンポ"}
                                    />)}</td>
                            </tr>
                            <tr>
                                <th>難易度*</th>
                                <td><div><select value={difficulty} onChange={e => {
                                    setDifficulty(e.target.value);
                                    const changedPropertyList_cp = changedPropertyList.filter((changedProperty) => changedProperty.name !== "難易度");
                                    if (Number(selectedScoreMetadata.difficulty) !== Number(e.target.value)) {
                                        setChangedPropertyList([...changedPropertyList_cp, {
                                            name: "難易度", dbname: "difficulty", from: Number(selectedScoreMetadata.difficulty), to: Number(e.target.value)
                                        }])
                                    } else {
                                        setChangedPropertyList(changedPropertyList_cp);
                                    }

                                }}>
                                    {
                                        difficulty_str.map((s, i) => {
                                            return <option value={i + 1}>{s}</option>
                                        })
                                    }
                                </select></div></td>
                            </tr>
                            <tr>
                                <th>リード回し</th>
                                <td><input
                                    type="checkbox"
                                    checked={isChangeLead}
                                    onChange={e => {
                                        setIsChangeLead(e.target.checked);
                                        const changedPropertyList_cp = changedPropertyList.filter((changedProperty) => changedProperty.name !== "リード回し");
                                        if (selectedScoreMetadata.is_changeLead !== `${e.target.checked}`) {
                                            setChangedPropertyList([...changedPropertyList_cp, {
                                                name: "リード回し", dbname: "is_changeLead", from: selectedScoreMetadata.is_changeLead, to: `${e.target.checked}`
                                            }])
                                        } else {
                                            setChangedPropertyList(changedPropertyList_cp);
                                        }
                                    }}
                                /></td>
                            </tr>
                            {/*{parentScoreid || (<tr><th>親楽譜</th><td>hoge</td></tr>)}*/}
                        </tbody>
                    </table>
                </form>
            </div>



            <h2>その他情報</h2>
            <div className={styles.info}>
                <form method="" action="">
                    <table>
                        <tbody>
                            <tr>
                                <th>公開範囲*</th>
                                <td><select value={accesibility} onChange={e => {
                                    setAccesibility(e.target.value);
                                    const changedPropertyList_cp = changedPropertyList.filter((changedProperty) => changedProperty.name !== "公開範囲");
                                    if (Number(selectedScoreMetadata.accesibility) !== Number(e.target.value)) {
                                        setChangedPropertyList([...changedPropertyList_cp, {
                                            name: "公開範囲", dbname: "accesibility", from: Number(selectedScoreMetadata.accesibility), to: Number(e.target.value)
                                        }])
                                    } else {
                                        setChangedPropertyList(changedPropertyList_cp);
                                    }

                                }}>
                                    <option value={0}>非公開</option>
                                    <option value={1}>限定公開</option>
                                    <option value={2}>全体公開</option>
                                </select></td>
                            </tr>
                            <tr>
                                <th>演奏バンド</th>
                                <td>{selectedBands.map((selectedBand, index) => (
                                    <DropDownForBandList
                                        key={index}
                                        selectKey={index.toString()}
                                        defaultValue={selectedBand.band_id.S}
                                        banditems={banditems}
                                        onChange={(value) => handleBandChange(index, value)}
                                    />
                                ))}
                                </td>
                            </tr>
                            <tr>
                                <th>演奏動画URL</th>
                                <td>
                                    {/*<input
                                    type="text"
                                    placeholder="https://www.youtube.com/watch?v=xxx, https://www.youtube.com/watch?v=yyy, https://www.youtube.com/watch?v=zzz"
                                    value={youtubeLinks}
                                    onChange={(e) => {
                                        setYoutubeLinks(e.target.value);
                                        const changedPropertyList_cp = changedPropertyList.filter((changedProperty) => changedProperty.name !== "演奏動画URL");
                                        if (selectedScoreMetadata.youtube_links !== e.target.value) {
                                            setChangedPropertyList([...changedPropertyList_cp, {
                                                name: "演奏動画URL", dbname: "youtube_links", from: selectedScoreMetadata.youtube_links, to: e.target.value
                                            }])
                                        } else {
                                            setChangedPropertyList(changedPropertyList_cp);
                                        }
                                    }}
                                />*/}
                                    <div className={styles.YtBox}>
                                        {youtubeLinksArr.map((ytlink, index) => (
                                            <div className={styles.YtInputBox}>
                                                <TextInput
                                                    key={"forYt" + index.toString()}
                                                    placeholder="https://www.youtube.com/watch?v=xxx"
                                                    value={ytlink}
                                                    onChange={(event) => {
                                                        // 新しいyoutubeLinksArrを作成
                                                        const newYoutubeLinksArr = [...youtubeLinksArr];
                                                        newYoutubeLinksArr[index] = event.target.value; // 値を変更
                                                        youtubeLinksArr[index] = event.target.value; // 値を変更
                                                        handleYtChange(index, event);
                                                        const changedPropertyList_cp = changedPropertyList.filter((changedProperty) => changedProperty.name !== "演奏動画URL");
                                                        if (selectedScoreMetadata.youtube_links !== youtubeLinksArr.join(",")) {
                                                            setChangedPropertyList([...changedPropertyList_cp, {
                                                                name: "演奏動画URL", dbname: "youtube_links", from: selectedScoreMetadata.youtube_links, to: newYoutubeLinksArr.join(",")
                                                            }])
                                                        } else {
                                                            setChangedPropertyList(changedPropertyList_cp);
                                                        }
                                                    }}
                                                    useAPI={false}
                                                    //suggestions={allTags}
                                                    width="L"
                                                />
                                                <button className={`${styles.tagDeleteButton} ${styles.YtBoxButton}`} onClick={(e) => handleYtBoxDelete(e, index)}><MdDeleteForever className={styles.icon} /></button>
                                            </div>
                                        ))}
                                    </div>
                                    <div className={styles.tagAddButtonWrapper}>
                                        <p>演奏動画URLを追加する</p>
                                        <button className={`${styles.tagAddButton} ${styles.tagBoxButton}`} onClick={handleYtBoxAdd}><FaPlusCircle className={styles.icon} /></button>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <th>説明文</th>
                                <td><textarea
                                    value={description}
                                    onChange={(e) => {
                                        setDescription(e.target.value);
                                        const changedPropertyList_cp = changedPropertyList.filter((changedProperty) => changedProperty.name !== "説明文");
                                        if (selectedScoreMetadata.description !== e.target.value) {
                                            setChangedPropertyList([...changedPropertyList_cp, {
                                                name: "説明文", dbname: "description", from: selectedScoreMetadata.description, to: e.target.value
                                            }])
                                        } else {
                                            setChangedPropertyList(changedPropertyList_cp);
                                        }
                                    }}
                                    maxlength={descriptionMaxLength}
                                    placeholder="入力してください" /></td>
                            </tr>
                            <tr>
                                <th>タグ</th>
                                <td><div className={styles.tagBoxWrapper}>
                                    <div className={styles.tagBox}>
                                        {/*placeholder, value, onChange, useAPI, suggestions, width */}
                                        {tags.map((tag, index) => (
                                            <div className={styles.tagInputBox}>
                                                <TextInput
                                                    key={"forTag" + index.toString()}
                                                    placeholder="タグ"
                                                    value={tag}
                                                    onChange={(event) => handleTagChange(index, event)}
                                                    useAPI={false}
                                                    suggestions={allTags}
                                                    width="M"
                                                    maxLength={tagStrMaxLength}
                                                />
                                                <button className={`${styles.tagDeleteButton} ${styles.tagBoxButton}`} onClick={(e) => handleTagBoxDelete(e, index)}><MdDeleteForever className={styles.icon} /></button>
                                            </div>
                                        ))}
                                    </div>
                                    <div className={styles.tagAddButtonWrapper}>
                                        <p>タグを追加する</p>
                                        <button className={`${styles.tagAddButton} ${styles.tagBoxButton}`} onClick={handleTagBoxAdd}><FaPlusCircle className={styles.icon} /></button>
                                    </div>
                                </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
            <div className={styles.buttonContainer}>
                <Button
                    type='primary3'
                    child='登録'
                    onClick={onConfirmButtonFirst}
                    disabled={(!pdfParsed) & (statusMessage !== 'waiting')}
                />
            </div>

            {/*最初に表示するモーダル */}
            {/*Fileの再アップロード時に使うかもしれないので放置*/}
            {isFileUploadModalOpen && (<div className={styles.uploadModalContent}>
                <Modal
                    isOpen={isFileUploadModalOpen}
                    contentLabel="Analysis Results"
                    onRequestClose={() => setIsFileUploadModalOpen(false)}
                    style={{
                        content: {
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            transform: 'translate(-50%, -50%)',
                            width: '80%', // またはコンテンツに合わせた具体的な値
                            maxHeight: '80vh', // コンテンツの高さに自動的に合わせる
                            overflow: 'auto', // コンテンツがオーバーフローする場合はスクロールを可能にする
                        }
                    }}
                >
                    <div className={styles.uploadContent}>
                        <FileDropzone onChange={onFileChange} />
                    </div>
                </Modal>
            </div>)}
            {/*登録時に表示するモーダル */}
            {isConfirmModalOpen && (
                <Modal
                    isOpen={isConfirmModalOpen}
                    onRequestClose={() => setIsConfirmModalOpen(false)}
                    style={{
                        content: {
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            transform: 'translate(-50%, -50%)',
                            width: '80%', // またはコンテンツに合わせた具体的な値
                            maxHeight: '80vh', // コンテンツの高さに自動的に合わせる
                            overflow: 'auto', // コンテンツがオーバーフローする場合はスクロールを可能にする
                        }
                    }}
                >
                    <div className={styles.confirmContainer}>
                        <div className={styles.changedProperty}>
                            <ul>
                                {changedPropertyList.map((changedProperty) => {
                                    if (changedProperty.name === "バージョンの名前" && changedProperty.from === "") {
                                        return <li>バージョンの名前が「{changedProperty.to}」に設定されました</li>
                                    } else if (changedProperty.name === "全体曲") {
                                        //console.log("changedProperty.to:",changedProperty.to);
                                        if (changedProperty.to === "true") {
                                            return <li>全体曲として設定されました</li>
                                        } else {
                                            return <li>全体曲としての設定が解除されました</li>
                                        }

                                    } else if (changedProperty.name === "VP想定" || changedProperty.name === "VP記載") {
                                        return
                                    } else if (changedProperty.name === "曲尺") {
                                        //console.log("changedProperty.to:",changedProperty.to);
                                        return <li>{changedProperty.name}が{Math.floor(changedProperty.from / 60)}分{changedProperty.from % 60}秒から{Math.floor(changedProperty.to / 60)}分{changedProperty.to % 60}秒に変更されました。</li>
                                    } else if (changedProperty.name === "リード回し") {
                                        if (changedProperty.to === "true") {
                                            return <li>リード回しのある曲として設定されました</li>
                                        } else {
                                            return <li>リード回しのない曲として設定されました</li>
                                        }
                                    } else if (changedProperty.name === "公開範囲") {
                                        return <li>{changedProperty.name}が「{
                                            changedProperty.from === 0 ? "非公開" : changedProperty.from === 1 ? "限定公開" : "全体公開"
                                        }」から「{
                                                changedProperty.to === 0 ? "非公開" : changedProperty.to === 1 ? "限定公開" : "全体公開"
                                            }」に変更されました。</li>
                                    } else if (changedProperty.name === "難易度") {
                                        return <li>{changedProperty.name}が「{difficulty_str[changedProperty.from - 1]}」から「{difficulty_str[changedProperty.to - 1]}」に変更されました。</li>
                                    } else if (changedProperty.name === "演奏動画URL") {
                                        return;
                                    }
                                    else {
                                        return <li>{changedProperty.name}が「{changedProperty.from}」から「{changedProperty.to}」に変更されました。</li>
                                    }
                                })}
                                {
                                    addedYts.length > 0 ?
                                        <li>
                                            以下のURLが追加されました。
                                            <ul>
                                                {addedYts.map((ytlink) => <li>{ytlink}</li>)}
                                            </ul>
                                        </li> : ""
                                }
                                {
                                    deletedYts.length > 0 ?
                                        <li>
                                            以下のURLが削除されました。
                                            <ul>
                                                {deletedYts.map((ytlink) => <li>{ytlink}</li>)}
                                            </ul>
                                        </li> : ""
                                }
                                {
                                    addedTags.length > 0 ?
                                        <li>
                                            以下のタグが追加されました。
                                            <ul>
                                                {addedTags.map((tag) => <li>{tag}</li>)}
                                            </ul>
                                        </li> : ""
                                }
                                {
                                    deletedTags.length > 0 ?
                                        <li>
                                            以下のタグが削除されました。
                                            <ul>
                                                {deletedTags.map((tag) => <li>{tag}</li>)}
                                            </ul>
                                        </li> : ""
                                }
                                {
                                    addedBands.length > 0 ?
                                        <li>
                                            以下のバンドが追加されました。
                                            <ul>
                                                {addedBands.map((band) => <li>{band.bandname.S}</li>)}
                                            </ul>
                                        </li> : ""
                                }
                                {
                                    deletedBands.length > 0 ?
                                        <li>
                                            以下のバンドが削除されました。
                                            <ul>
                                                {deletedBands.map((band) => <li>{band.bandname.S}</li>)}
                                            </ul>
                                        </li> : ""
                                }
                            </ul>
                        </div>
                        {!confirmOkPressed ? (
                            <>
                                <h1>登録します。よろしいですか？</h1>
                                <Button
                                    type='primary3'
                                    child='登録'
                                    onClick={onConfirmButtonLast}
                                />
                                <Button
                                    type='normal'
                                    child='やめる'
                                    onClick={onConfirmButtonLastCancel}
                                />
                            </>) : registrationFailed ? (
                                <>
                                    <h1>一部の情報登録に失敗しました</h1>
                                    <ul>
                                        {
                                            registrationStatus.map((status)=>{
                                                return <li>{status}</li>
                                            })
                                        }
                                    </ul>
                                    <p>お問い合わせ用の楽譜ID:{myScoreId_w_version}</p>
                                    <Button
                                        type='normal'
                                        child='トップへ戻る'
                                        onClick={buttonReturnToTopPushed}
                                    />
                                    <Button
                                        type='normal'
                                        child='楽譜詳細ページへ'
                                        onClick={buttonReturnToScoreDetail}
                                    />
                                </>
                                ):(
                            <>
                                <h1>登録しました！</h1>
                                <Button
                                    type='normal'
                                    child='トップへ戻る'
                                    onClick={buttonReturnToTopPushed}
                                />
                                <Button
                                    type='normal'
                                    child='楽譜詳細ページへ'
                                    onClick={buttonReturnToScoreDetail}
                                />
                            </>
                            )}
                    </div>
                </Modal>
            )}

            {/*アップロード失敗時に出るモーダル */}
            {isUploadErrorMsgModalOpen && (
                <div className={styles.errorMsgModalContent}>
                    <Modal
                        isOpen={isUploadErrorMsgModalOpen}
                        //onRequestClose={() => setIsConfirmModalOpen(false)}
                        onRequestClose={() => {
                            setIsUploadErrorMsgModalOpen(false);
                        }}
                        style={{
                            content: {
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                transform: 'translate(-50%, -50%)',
                                width: '60%', // またはコンテンツに合わせた具体的な値
                                maxHeight: '80vh', // コンテンツの高さに自動的に合わせる
                                overflow: 'auto', // コンテンツがオーバーフローする場合はスクロールを可能にする
                            }
                        }}
                    >
                        <div className={styles.errorMsgContent}>
                            <h1>アップロードに失敗しました</h1>
                            <div>
                                以下の項目を確認してみてください
                                <ul>
                                    <li>.mscz形式のファイルをアップロードしてください</li>
                                    <li>MuseScoreのバージョンは4.4.0以下のみ保障しております</li>
                                    {/*<li>ファイル名の拡張子以外の部分に'.'は入れないでください</li>*/}
                                </ul>

                                エラーメッセージ：{errorMsg.toString()}
                            </div>
                        </div>
                        <div className={styles.centering}>
                            <div>
                                <Button
                                    type='normal'
                                    child='OK'
                                    onClick={() => {
                                        buttonPressOk();
                                        setIsUploadErrorMsgModalOpen(false);
                                    }} />
                                <Button
                                    type='normal'
                                    child='違うファイルをアップロードする'
                                    onClick={buttonToUploadAnotherFile} />
                            </div>
                        </div>
                    </Modal>
                </div>
            )}

            {/* 必須項目が埋められていない場合に表示するモーダル */}
            {isUnFilledModalOpen && (
                <div className={styles.errorMsgModalContent}>
                    <Modal
                        isOpen={isUnFilledModalOpen}
                        //onRequestClose={() => setIsConfirmModalOpen(false)}
                        style={{
                            content: {
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                transform: 'translate(-50%, -50%)',
                                width: '60%', // またはコンテンツに合わせた具体的な値
                                maxHeight: '80vh', // コンテンツの高さに自動的に合わせる
                                overflow: 'auto', // コンテンツがオーバーフローする場合はスクロールを可能にする
                            }
                        }}
                    >
                        <div className={styles.errorMsgContent}>
                            {unFilledFields}
                        </div>
                        <div className={styles.centering}>
                            <div><Button
                                type='normal'
                                child='OK'
                                onClick={buttonPressOk} />
                            </div>
                        </div>
                    </Modal>
                </div>
            )}

            {/* その他エラー時に表示するモーダル */}
            {isSimpleErrorMsgModalOpen && (
                <div className={styles.errorMsgModalContent}>
                    <Modal
                        isOpen={isSimpleErrorMsgModalOpen}
                        //onRequestClose={() => setIsConfirmModalOpen(false)}
                        style={{
                            content: {
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                transform: 'translate(-50%, -50%)',
                                width: '60%', // またはコンテンツに合わせた具体的な値
                                maxHeight: '80vg', // コンテンツの高さに自動的に合わせる
                                overflow: 'auto', // コンテンツがオーバーフローする場合はスクロールを可能にする
                            }
                        }}
                    >
                        <div className={styles.errorMsgContent}>
                            {errorMsg.toString()}
                        </div>
                        <div className={styles.centering}>
                            <div><Button
                                type='normal'
                                child='OK'
                                onClick={buttonPressOk} />
                            </div>
                        </div>
                    </Modal>
                </div>
            )}
        </div>

    );
}

export default Dev;