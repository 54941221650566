
import {AWS_REGION, COGNITO_USERPOOL, COGNITO_USERPOOL_WEBCLIENT} from './config';

export const currentConfig = {
  region: AWS_REGION,
  userPoolId: COGNITO_USERPOOL,
  userPoolWebClientId: COGNITO_USERPOOL_WEBCLIENT,
  // OPTIONAL - Hosted UI configuration
  oauth: {
    domain: 'score-plorer.auth',
    scope: [
      'phone',
      'email',
      //'profile',
      'openid',
      //'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: 'http://localhost:3000/',
    redirectSignOut: 'http://localhost:3000/',
    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
};

