import React, { useState, useEffect } from 'react';
import PianoKeyboard from './pianokeyboard';

const VisualizeVoiceranges = ({ voiceranges }) => {
    const [parsedRanges, setParsedRanges] = useState(null);

    useEffect(() => {
        //console.log("voiceranges prop received:", voiceranges);
        if (!voiceranges || typeof voiceranges !== 'string') {
            //console.log("fast return");
            setParsedRanges(null);
            return;
        }

        let parsed;
        try {
            parsed = JSON.parse(voiceranges);
            //console.log("Parsed ranges:", parsed);
        } catch (e) {
            console.error("Error parsing voiceranges:", e);
            setParsedRanges(null);
            return;
        }

        if (!Array.isArray(parsed) || parsed.length === 0) {
            setParsedRanges(null);
        } else {
            setParsedRanges(parsed);
        }
    }, [voiceranges]);

    const parseRange = (range) => {
        //console.log("range = ", range);
        const lastSpaceIndex = range.lastIndexOf(' ');
        const part = range.slice(0, lastSpaceIndex);
        const notes = range.slice(lastSpaceIndex + 1);
        const [startNote, endNote] = notes.replace(/[()]/g, '').split('~');
        return { part, startNote, endNote };
    };

    if (parsedRanges === null) {
        return <p>Loading...</p>; // or return null if you prefer to show nothing
    }

    return (
        <div >
            {parsedRanges.map((range, index) => {
                const { part, startNote, endNote } = parseRange(range);

                return (
                    <div key={index} style={{ marginBottom: '20px' }}>
                        <h3>{part}</h3>
                        {startNote === "-" || endNote === "-" ? (
                            <p>No Data</p>
                        ) : (
                            <PianoKeyboard
                                startNote="A1"
                                endNote="C6"
                                highlightedRange={{ start: startNote, end: endNote }}
                            />
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default VisualizeVoiceranges;