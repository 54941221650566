import React, {useState, useRef, useEffect } from 'react';
//import debounce from 'lodash.debounce'; // lodashのデバウンス関数を使用
import styles from './TextInput.module.css'; // module.cssのインポート


const TextInput = ({ placeholder, value, onChange, onClick, useAPI, suggestions = [], width, disabled, maxLength=500 }) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const suggestionsRef = useRef(null);
  const enableSuggetion = suggestions.length > 0;

  const handleChange = (e) => {
    const userInput = e.target.value;
    onChange(e);
    
    if(enableSuggetion){
      const filtered = suggestions.filter(
        suggestion => suggestion.toLowerCase().indexOf(userInput.toLowerCase()) === 0
      );
      setFilteredSuggestions(filtered);

      setShowSuggestions(userInput.length > 0);
      setActiveSuggestionIndex(0);
    }
    
  };

  const handleKeyDown = (e) => {
    // 下矢印キーを押したとき
    if (e.keyCode === 40) {
      if (filteredSuggestions && activeSuggestionIndex < filteredSuggestions.length - 1) {
        setActiveSuggestionIndex(activeSuggestionIndex + 1);
      }
    }
    // 上矢印キーを押したとき
    else if (e.keyCode === 38) {
      if (activeSuggestionIndex > 0) {
        setActiveSuggestionIndex(activeSuggestionIndex - 1);
      }
    }
    // Enterキーを押したとき
    else if (e.keyCode === 13) {
      // 入力が空、またはサジェストのリストが表示されていない場合は何もしない
      if (value === '' || !showSuggestions) {
        e.preventDefault();
        return;
      }
      setShowSuggestions(false);
      onChange({ target: { value: filteredSuggestions[activeSuggestionIndex] } });
      setActiveSuggestionIndex(0);
    }
  };

  const handleMouseEnter = index => {
    setActiveSuggestionIndex(index);
  };
  const handleSuggestionClick = (suggestion) => {
    onChange({ target: { value: suggestion } });
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    setActiveSuggestionIndex(0);
  };

  useEffect(()=>{
    if(value?.length > maxLength) {
      alert(`文字数制限${maxLength}文字を超えています`);
    }
  },[value]);
  

  //大きさ制御
  const getWidthClass = (size) => {
    switch (size) {
      case 'S': return styles['width-S'];
      case 'M': return styles['width-M'];
      case 'L': return styles['width-L'];
      default: return styles['width-S'];
    }
  };
  return (
    <div>
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        className={`${styles.textInput} ${getWidthClass(width)}`}
        disabled={disabled}
        maxLength={maxLength}
      />
      {showSuggestions && (
        <ul className={`${styles.suggestions}`} ref={suggestionsRef}>
          {filteredSuggestions.map((suggestion, index) => (
            <li
              key={index}
              className={index === activeSuggestionIndex ? styles.activeSuggestion : ''}
              onClick={() => handleSuggestionClick(suggestion)}
              onMouseEnter={() => handleMouseEnter(index)}
            >
              {suggestion}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default TextInput;