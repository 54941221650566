import React, { useState, useEffect } from 'react';
import styles from './SearchFilter.module.css';
import CheckBoxs from '../Ui/CheckBoxs/CheckBoxs';
import RadioButtons from '../Ui/RadioButtons/RadioButtons';
import DropDown from '../Ui/DropDown/DropDown';
import TextInput from '../Ui/TextInput/TextInput';
import Button from '../Button/Button';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import DropDownForUserList from '../Ui/DropDown/DropDownForUserList';
import DropDownForBandList from '../Ui/DropDown/DropDownForBandList';
import DropDownForTagList from '../Ui/DropDown/DropDownForTagList';

const API_PREFIX = process.env.REACT_APP_API_PREFIX;
const SerarchFilter = ({closeModal, 
                        queryFields,
                        queryFieldsInitialize,
                        setQuery, 
                        setData, 
                        setLoadingScores, 
                        useritems, 
                        useritemChange, 
                        banditems,
                        banditemChange,
                        tagitems,
                        tagChange,
                        artist_opts,
                        handleSearchSubmit
                    }) => {

    //checkboxの値更新用
    const updateOptionsWithCheckedState = (options, checkedState) => {
        //console.log(options, checkedState);
        return options.map(option => ({
            ...option,
            isChecked: checkedState[option.value] || false
        }));
    };

    //男声パートの選択肢
    const part_male_opt = [
        { label: '0', value: 0 },
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 },
        { label: '6', value: 6 },
        { label: '7', value: 7 },
    ];

    //女声パートの選択肢
    const part_female_opt = [
        { label: '0', value: 0 },
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 },
        { label: '6', value: 6 },
        { label: '7', value: 7 },
    ];

    const [vp_need_opt, set_vp_need_opt] = useState([
        { label: 'VPあり想定', value: 'true' },
        { label: 'VPなし想定', value: 'false' },
    ]);
    const [vp_have_opt, set_vp_have_opt] = useState([
        { label: 'VP記譜あり', value: 'true' },
        { label: 'VP記譜なし', value: 'false' },
    ]);
    const [difficulty_opt, set_difficulty_opt] = useState([
        { label: '入門', value: 1 },
        { label: '簡単', value: 2 },
        { label: '普通', value: 3 },
        { label: 'やや難', value: 4 },
        { label: '難しい', value: 5 },
    ]);
    const [isChangeLead_opt, set_isChangeLead_opt] = useState([
        { label: 'リード回し', value: 'true', isChecked: false },
        { label: 'リード固定', value: 'false', isChecked: false },
    ]);
    const tempo_opt = [
        { label: '指定なし', value: -1},
        { label: 'ローテンポ', value: 'ローテンポ' },
        { label: 'ミドルテンポ', value: 'ミドルテンポ' },
        { label: 'アップテンポ', value: 'アップテンポ' },
    ];


    /*
    変更する場合用
    const duration_opt_min = [
        { label: '指定なし', value: 1 },
        { label: '1分', value: 60 },
        { label: '1.5分', value: 90 },
        { label: '2分', value: 120 },
        { label: '2.5分', value: 150 },
        { label: '3分', value: 180 },
        { label: '3.5分', value: 210 },
        { label: '4分', value: 240 },
        { label: '4.5分', value: 270 },
        { label: '5分', value: 300 },
        { label: '10分', value: 600 },
    ];
    */
    const duration_opt_min = [
        { label: '指定なし', value: 1 },
        { label: '1分', value: 60 },
        { label: '1分30秒', value: 90 },
        { label: '2分', value: 120 },
        { label: '2分30秒', value: 150 },
        { label: '3分', value: 180 },
        { label: '3分30秒', value: 210 },
        { label: '4分', value: 240 },
        { label: '4分30秒', value: 270 },
        { label: '5分', value: 300 },
        { label: '10分', value: 600 },
    ];
    const duration_opt_max = [
        { label: '1分', value: 60 },
        { label: '1分30秒', value: 90 },
        { label: '2分', value: 120 },
        { label: '2分30秒', value: 150 },
        { label: '3分', value: 180 },
        { label: '3分30秒', value: 210 },
        { label: '4分', value: 240 },
        { label: '4分30秒', value: 270 },
        { label: '5分', value: 300 },
        { label: '10分', value: 600 },
        { label: '指定なし', value: 99999999 },
    ];


    const [isZentaikyoku, setIsZentaikyoku] = useState([{label : '全体曲' , value: 'true'}]);

    useEffect(()=>{
        //console.log("loaded queryFields:",queryFields);
        //checkbox
        setIsZentaikyoku(updateOptionsWithCheckedState(isZentaikyoku,queryFields.s_is_zentaikyoku || false));
        set_vp_have_opt(updateOptionsWithCheckedState(vp_have_opt,queryFields.s_have_vp || false));
        set_vp_need_opt(updateOptionsWithCheckedState(vp_need_opt,queryFields.s_need_vp || false));
        set_difficulty_opt(updateOptionsWithCheckedState(difficulty_opt, queryFields.s_difficulty || false));
        set_isChangeLead_opt(updateOptionsWithCheckedState(isChangeLead_opt,queryFields.s_is_changeLead || false));
    },[queryFields]);


    return(
    <div className={styles.frame}>
        <Button type='primary2' child="検索" onClick={() => handleSearchSubmit()}/>
        <Button type='primary2' child="条件リセット" onClick={queryFieldsInitialize}/>
        <div className={styles.group}>
            <h3 className={styles.subheading}>楽曲情報</h3>
            <TextInput placeholder="楽曲名" 
                value={queryFields.s_score_name}
                width="L"
                onChange={(event) => setQuery("s_score_name", event.target.value)}/>
            <TextInput placeholder="アーティスト名" 
                value={queryFields.s_artist_name}
                width="L"
                suggestions={artist_opts}
                onChange={(event) => setQuery("s_artist_name", event.target.value)}/>
        </div>
        
        <div className={styles.group}>
            
            <h3 className={styles.subheading}>演奏メンバー情報</h3>
            <CheckBoxs options = {isZentaikyoku} 
                        onChange={(event) => setQuery("s_is_zentaikyoku", event)}/>
            <div className={styles.searchColumnText}>男声の数</div>
            <DropDown 
                options={part_male_opt}
                onChange={(event) => setQuery("s_part_male_n", event)}
                initSelectedValue={queryFields.s_part_male_n}
            />
            <div className={styles.searchColumnText}>女声の数</div>
            <DropDown 
                options={part_female_opt}
                onChange={(event) => setQuery("s_part_female_n", event)}
                initSelectedValue={queryFields.s_part_female_n}
            />
            <div className={styles.searchColumnText}>VPについて</div>
            <CheckBoxs options = {vp_need_opt} 
                        onChange={(event) => setQuery("s_need_vp", event)}/>
            <CheckBoxs options = {vp_have_opt} 
                        onChange={(event) => setQuery("s_have_vp", event)}/>
        </div>

        <div className={styles.group}>
            <h3 className={styles.subheading}>演奏難易度</h3>
            <CheckBoxs options={difficulty_opt} 
                        onChange={(event) => setQuery("s_difficulty", event)}/>
        </div>

        <div className={styles.group}>
            <h3 className={styles.subheading}>アレンジ情報</h3>
            <div className={styles.searchColumnText}>リード回し</div>
            <CheckBoxs options={isChangeLead_opt} 
                        onChange={(event) => setQuery("s_is_changeLead", event)}/>
            <div className={styles.searchColumnText}>曲の尺</div>
            <DropDown 
                options={duration_opt_min}
                initSelectedValue={queryFields.s_duration_min}
                onChange={(event) => setQuery("s_duration_min", event)}/>
            <div>秒から</div>
            <DropDown 
                options={duration_opt_max}
                initSelectedValue={queryFields.s_duration_max}
                onChange={(event) => setQuery("s_duration_max", event)}/>
            <div>秒まで</div>

            <div className={styles.searchColumnText}>テンポ</div>
            <DropDown 
                options={tempo_opt}
                onChange={(event) => setQuery("s_tempo", event)}
                initSelectedValue={queryFields.s_tempo}
            />

            <div className={styles.searchColumnText}>アレンジャー名</div>
            <DropDownForUserList 
                selectKey={0} 
                defaultValue={queryFields.s_arranger_id}
                useritems={useritems} 
                onChange={useritemChange} 
            />
            <div className={styles.searchColumnText}>演奏バンド</div>
            <DropDownForBandList 
                selectKey={0} 
                defaultValue={queryFields.s_band_id}
                banditems={banditems.sort((a,b)=>a.bandname.S.localeCompare(b.bandname.S, 'ja'))} 
                onChange={banditemChange} 
            />
            <div className={styles.searchColumnText}>タグ</div>
            <DropDownForTagList
                selectKey={0}
                defaultValue={queryFields.s_tag_id}
                tagitems={tagitems.sort((a,b) => a.content.localeCompare(b.content, 'ja'))}
                onChange={tagChange}
            />
        </div>

        

        <Button type='primary2' child="検索" onClick={() => handleSearchSubmit()}/>
        <Button type='primary2' child="条件リセット" onClick={queryFieldsInitialize}/>

    </div>
    )
}

export default SerarchFilter;