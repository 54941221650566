import React, { useState, useEffect } from 'react';
import stylesBase from './Play00BaseCss.module.css';
import Card from './Play09Card';

function Play09ApiFetch() {
    const [items, setItems] = useState([]);
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);  // 新しく追加
    const [errorStatus, setErrorStatus] = useState(-1);
    const [value, setValue] = useState(10000);

    /*
    無限スクロールを使用したデータの読み込みをしています。
    */
    useEffect(() => {
        const handleScroll = () => {
            if (hasMore && !loading && window.innerHeight + window.scrollY >= document.body.offsetHeight) {
                fetchItems();
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastEvaluatedKey, loading, hasMore]);  // 依存配列にloadingとhasMoreを追加

    const fetchItems = async () => {
        if (loading || !hasMore) return; // hasMoreをチェック
        
        setLoading(true);
        let url = 'http://localhost:3001/dbitem_get5_with_error';

        if (lastEvaluatedKey) {
            const encodedKey = encodeURIComponent(JSON.stringify(lastEvaluatedKey));
            url += `?LastEvaluatedKey=${encodedKey}`;
        }
        try {
            const response = await fetch(url);
            setErrorStatus(response.status);
            if(response.status!==200){
                throw new Error("Responce error "+errorStatus.toString());
            }
            const data = await response.json();
            setItems([...items, ...data.items]);// データを連結
            setLastEvaluatedKey(data.lastEvaluatedKey);

            if (data.lastEvaluatedKey === null) {
                setHasMore(false); // データがなくなったので、hasMoreをfalseに
            }
        } catch (error) {
            console.error('Error fetching items:', error);
        } finally {
            setLoading(false);
        }
    };

    const changedValue = (number) => {
        setValue(Number(number));
    }

    return (
        <div>
            <h1 className={stylesBase.header}>APIを用いたコンポーネント</h1>
            <input type="text" onChange={e => changedValue(e.target.value)} placeholder='input number'></input>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {items.filter((item) => item.value.N < value).map((item, index) => (
                    <Card
                        key={index}
                        title={item.fruits.S}
                        description={`価格: ${item.value.N}円`}
                    />
                ))}
            </div>
            {errorStatus!==200 && <p>Catch the responce error. Please rescroll! </p>}
            {loading && <p>Loading...</p>}
            {!hasMore && <p>No more items to load</p>}
        
            {/*課題1. エラーハンドリングをしてください。
                    dbitem_get5の代わりにdbitem_get5_with_errorのAPIを使用すると、ランダムでエラーが起こります。
                    このエラーをフロントエンド側で対処してください。
                    ・エラーのステータスコードを受け取った時に適切なメッセージを表示してください
                    ・ヒント：39行目のresponceにはstatusという属性があります。 */}
            
            {/*課題2.  データのフィルタリングを実装してください。
                    ・価格を受け取るテキストフィールドを作成し、入力値未満の値段のデータのみを表示されるようにしてください
                    ・フィルタの結果、対象データが0件の場合には0件であることがわかりやすくしてください。*/}
        </div>
    );
}

export default Play09ApiFetch;
