import React, {useState, useEffect} from 'react';
import stylesBase from './Play00BaseCss.module.css';
import Loading from '../Utils/Loading/Loading';
import ScoreCard from '../Utils/ScoreCard/ScoreCard';
import PageScoreList from '../../pages/PageScoreList';
import PageTop from '../../pages/PageTop';

function PlayMySelf() {

    //Read(全部の楽譜のID)
    const [listedScoreIds, setListedScoreIds] = useState([]);
    const getScoreIds = async () => {
        const requestOptions = {
        method: 'GET',
        };
        try {
        const response = await fetch(`http://localhost:3001/api/scores/list`, requestOptions);
        if (response.status === 200) {
            //console.error('Successfully get scores');
            const data = await response.json();
            setListedScoreIds(data); // IDのリストを状態にセット
        } else {
            console.error('Failed to get score item.');
        }
        } catch (error) {
        console.error('There was an error!', error);
        }
    }

    useEffect(()=>{
        getScoreIds();
        //console.log(selectedScoreMetadata);
        /*
        selectedScoreMetadata.band_ids.array.forEach( band_id => {
          setSelectedBandIdToGet(band_id);
          getBandInfo()
        });
        */
      },listedScoreIds);

    return (
        <div>
            <Loading onloading={true} loading_object_name={"あじわいの楽譜"}/>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {
                    listedScoreIds.map((score_id) => (
                        <ScoreCard score_id={score_id}/>
                    ))
                }
            </div>
            <div>
                <h2>トップページのサンプルです</h2>
                <PageTop/>
                <h2>楽譜一覧ページのサンプルです</h2>
                <PageScoreList/>
            </div>
        </div>
    )
}
export default PlayMySelf;