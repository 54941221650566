import React, { useState, useEffect, useCallback } from 'react';
import Loading from '../components/Utils/Loading/Loading';
import ScoreCard from '../components/Utils/ScoreCard/ScoreCard';
import CommonHeader from '../components/Utils/CommonHeader/CommonHeader';
import styles from './PageScoreList.module.css';
import Button from '../components/Utils/Button/Button'
import { useLocation, Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import DropDown from '../components/Utils/Ui/DropDown/DropDownForVersionList';


const API_PREFIX = process.env.REACT_APP_API_PREFIX;
export const PageScoreList = () => {
	//ページ遷移関係
	const location = useLocation();
	//Create


	// 検索結果を格納するための状態変数
	const [searchResults, setSearchResults] = useState([]);
	const [loadingScores, setLoadingScores] = useState(true);
	const [filteredResults, setFilteredResults] = useState([]);
	const [filteredLatestResults, setFilteredFinalResults] = useState([]);
	const [filteredScoreData, setFilteredScoreData] = useState(null);
	const [sortedScoreData, setSortedScoreData] = useState([]);

	const resetData = useCallback(async () => {
		setLoadingScores(true);
		setSearchResults([]);
		setSortedScoreData([]);　//これがないと前段階の遅延ローディングが追加で行われてしまう
		setsortedScoreDataSliced([]);
		setFilteredFinalResults([]);
		setFilteredResults([]);
		setFilteredScoreData(null);
		return true
	},[]);

	//ログインしてるユーザーのID
	const [myUserId, setMyUserId] = useState(null);
	useEffect(() => {
		const fetchUserData = async () => {
			try {
				// ログイン中のユーザー情報を取得
				const currentUserInfo = await Auth.currentAuthenticatedUser();
				const cognitoId = currentUserInfo.signInUserSession.idToken.payload.sub;

				// Cognito IDからユーザーIDを取得
				const userIdResponse = await axios.get(`${API_PREFIX}/api/user/whoami/${cognitoId}`);
				const userId = userIdResponse.data.userId;
				setMyUserId(userId);
			} catch (error) {
				console.error('Error fetching user data:', error);
			}
		};
		resetData();
		fetchUserData();
	}, []);


	//他からの遷移を検知し、新しいデータで更新する
	const [CommonHeaderInitSearchTerm, setCommonHeaderInitSearchTerm] = useState("");
	const [CommonHeaderQueryFields, setCommonHeaderQueryFields] = useState({});
	const queryFieldsInit = {
		s_score_name: "", //曲名
		s_artist_name: "", //アーティスト名
		s_difficulty: {},   //難易度　1(入門)~5(難しい)
		s_duration_min: 1, //曲尺の最低値（単位：秒）
		s_duration_max: 99999999, //曲尺の最大値 (単位：秒)
		s_is_changeLead: {}, //リード回し。 -1->選択なし true->リード回し false->リード固定
		s_tempo: -1, //テンポ。-1=指定なし、1=ローテンポ、2=ミドルテンポ、3=アップテンポ
		s_part_male_n: -1, //男声パート数
		s_part_female_n: -1,//女声パート数
		s_have_vp: {},//VP記載　
		s_need_vp: {},//VP想定　
		s_is_zentaikyoku: {}, //全体曲フラグ
		s_arranger_id: "", //アレンジャーID
		s_band_id: "",   //演奏バンドID （複数指定禁止）
		s_tag_id: "", //タグID（複数指定禁止）
	};
	useEffect(() => {
		if (myUserId !== null)
			setLoadingScores(true);

		//handlerToSearchScores();
		//console.log("PageScoreList location:",location.state.intersectionArray);
		//console.log("location:", location);

		resetData().then((result)=>{
			if (location.state && location.state.intersectionArray) {
				setSearchResults(location.state.intersectionArray);
				//setLoadingScores(true);
			} else {
				setLoadingScores(true);
			}
			if (location.state && location.state.searchTerm) {
				setCommonHeaderInitSearchTerm(location.state.searchTerm);
			}
			if (location.state && location.state.queryFields) {
				setCommonHeaderQueryFields(location.state.queryFields);
			} else {
				setCommonHeaderQueryFields(queryFieldsInit);
			}
		})
		//console.log(searchResults, loadingScores, CommonHeaderQueryFields);
	}, [location.state]);  // 依存配列にlocation.stateを指定

	const [listedBookmark, setListedBookmark] = useState([]);
	const [listedBookmarkIds, setListedBookmarkIds] = useState([]);
	const getListedBookmark = async (user_id) => {
		const requestOptions = {
			method: 'GET',
		};
		try {
			//const _bookmark = await fetchUserId();
			const response = await fetch(`${API_PREFIX}/api/user/${user_id}/bookmarks`, requestOptions);
			if (response.status === 200) {
				//console.error('Successfully get scores');
				const data = await response.json();
				//console.log("listedBookmark:", data.bookmarks);
				setListedBookmarkIds(data.bookmarks);
			} else {
				console.error('Failed to get score item.');
			}
		} catch (error) {
			console.error('There was an error!', error);
			setListedBookmark([]);
			return false;
		} finally {
			return true;
		}
	}

	//loadingScoresが作用しているかチェック用
	//useEffect(() => {
		//console.log("loadingScores:", loadingScores);
	//}, [loadingScores]);


	useEffect(() => {
		setLoadingScores(true);
		//console.log("searchResults:", searchResults);
		//if (myUserId !== null && searchResults.length > 0) {
		if (myUserId !== null && searchResults?.length > 0) {
			processScores(searchResults, myUserId)
				.then(filteredLatestResults => {
					setFilteredFinalResults(filteredLatestResults);
					getListedBookmark(myUserId);
				})
				.catch(error => {
					console.error('Error processing scores:', error);
				})
		}else if(searchResults?.length == 0){
			setFilteredFinalResults([]);
		}
		//console.log("filteredLatestResults:", filteredLatestResults);
	}, [myUserId, searchResults]);

	//楽譜の情報を一括で問い合わせ
	useEffect(() => {
		//console.log("filtered result changed", filteredLatestResults);
		setLoadingScores(true);
		const getScoreData = async () => {
			if (filteredLatestResults.length === 0) {
				// filteredLatestResultsが空の場合、APIリクエストをスキップ
				setFilteredScoreData(null);
				setSortedScoreData([]);
				setLoadingScores(false);
				return;
			}else{
				axios.post(`${API_PREFIX}/api/scores`, { scoreIds: filteredLatestResults })
				.then(response => {
					setFilteredScoreData(response.data);
				})
				.catch(error => {
					console.error('Error:', error);//たいていの場合filteredLatestResultsが空だった場合
					setFilteredScoreData(null);
				})
			}
		};
		getScoreData();
	}, [filteredLatestResults]);

	const isCanbeSeenScore = async (score_id) => {
		// 閲覧権限があるものならtrue、そうでなければfalse
		// 楽譜のaccesibilityの対応： 0（非公開)、1（限定公開）、2(全体公開)
		// 1.楽譜に対して情報取得する
		let myaccesibility = -1;
		let scoreData = null;
		//console.log("scoreid");
		//console.log(score_id);
		const requestOptions = {
			method: 'GET',
		};
		try {
			const API_PREFIX = process.env.REACT_APP_API_PREFIX;
			const response = await fetch(`${API_PREFIX}/api/score/${score_id}`, requestOptions);
			const data = await response.json();
			scoreData = data;
			myaccesibility = Number(scoreData.accesibility);
			//console.log(myaccesibility);
			//console.log("data.tag_ids:",data.tags);
			//selectedScoreMetadata = data;
		} catch (error) {
			console.error('Failed to get accesibility of score item.', error, score_id);
			//console.error(error,score_id);
		}
		// 2. accesibilityによって分岐
		if (myaccesibility === 0) {
			//console.log("0", myUserId, scoreData.registered_userid);
			//console.log(myUserId);
			//console.log(scoreData.registered_userid);
			// 2-1. 非公開のとき -> registed_useridと自分のuserIdを突き合わせ
			if (myUserId === scoreData.registered_userid) {
				return true;
			} else {
				return false;
			}
		} else if (myaccesibility === 1) {
			//console.log("1");
			// 2-2. 限定公開のとき -> registed_useridとの突き合わせ & アレンジャーと演奏バンドも問い合わせ
			// 2-2-1. 登録者
			if (myUserId === scoreData.registered_userid) {
				return true;
			}
			// 2-2-2. アレンジャー
			try {
				// アレンジャーの情報を取得
				const requestOptions = {
					method: 'GET',
				};
				const arrangersResponse = await fetch(`${API_PREFIX}/api/arranged/score/${score_id}/users`, requestOptions);
				const arrangersData = await arrangersResponse.json();
				if (arrangersData.length > 0) {
					const arranger_id = arrangersData[0];
					if (myUserId === arranger_id) {
						return true;
					}
				}
			} catch (error) {
				console.error('Failed to get arranger of score item.', error, score_id);
			}
			// 2-2-3. 演奏バンド
			try {
				// 演奏バンドの情報を取得
				const requestOptions = {
					method: 'GET',
				};
				const bandsResponse = await fetch(`${API_PREFIX}/api/performed/score/${score_id}/bands`, requestOptions);
				const bandsData = await bandsResponse.json();
				// バンドデータを適切な形式に変換してからステートにセット
				for (let i = 0; i < bandsData.length; i++) {
					const curr_band_id = bandsData[i];
					const requestOptions_b = {
						method: 'GET',
					};
					const currentBandResponse = await fetch(`${API_PREFIX}/api/band/${curr_band_id}`, requestOptions_b);
					const currentBandData = await currentBandResponse.json();
					const bandmaster = currentBandData.bandmaster.S;
					const bandmembers = currentBandData.bandmembers.SS;
					if (myUserId === bandmaster) {
						return true;
					}
					for (let j = 0; j < bandmembers.length; j++) {
						if (myUserId === bandmembers[j]) {
							return true;
						}
					}
				}
			} catch (error) {
				console.error('Failed to get band of score item.', error, score_id);
			}
			// ここまで来てもreturn trueされてない→アウト
			return false;
		} else if (myaccesibility === 2) {
			//console.log("2");
			// 2-3. 全体公開 -> 無条件でtrue
			return true;
		}
	};
	// 権限チェックとバージョンフィルタリングを行う関数
	/*
	const processScores = async (scores, userId) => {
		const allowedResults = [];
		for (const scoreId of scores) {
			const canBeSeen = await isCanbeSeenScore(scoreId, userId);
			if (canBeSeen) {
				allowedResults.push(scoreId);
			}
		}

		return allowedResults;
	}
	*/
	/*
	const processScores = async (scores, userId) => {
		// 各スコアに対する権限チェックを非同期に実行し、その結果を並行処理する
		const permissionChecks = scores.map(async (scoreId) => {
			const canBeSeen = await isCanbeSeenScore(scoreId, userId);
			return canBeSeen ? scoreId : null; // 権限がある場合のみscoreIdを返す
		});
	
		// すべてのチェックが完了するのを待つ
		const results = await Promise.all(permissionChecks);
	
		// `null` でないものだけをフィルタリングして返す
		return results.filter(scoreId => scoreId !== null);
	}
	*/
	// フロントエンドの processScores 関数を新しいAPIを利用して書き直し
	const processScores = async (scores, userId) => {
		try {
			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ scoreIds: scores, userId })
			};

			// 新しいAPIを使用して権限チェック
			//console.log("Sending permission check request", { requestOptions });
			const permissionResponse = await fetch(`${API_PREFIX}/api/scores/checkPermissions`, requestOptions);
			const permissionData = await permissionResponse.json();

			if (!permissionData.allowedScoreIds) {
				console.warn("No allowed scores found for user.");
				return [];
			}

			// 許可されたスコアIDを取得
			const allowedResults = permissionData.allowedScoreIds;
			//console.log("Allowed scores received", { allowedResults });
			return allowedResults;
		} catch (error) {
			console.error("Error processing scores for user", { userId, error });
			return [];
		}
	};

	// IDに基づいて最新のバージョンのみを返す関数
	function filterLatestVersions(results) {
		const latestVersions = results.reduce((acc, result) => {
			const [id, version] = result.split('_');
			if (!acc[id] || acc[id].version < version) {
				acc[id] = { fullId: result, version: version };
			}
			return acc;
		}, {});

		return Object.values(latestVersions).map(item => item.fullId);
	}

	//表示結果ソート
	const sort_options = [
		{ label: '登録日時が新しい順', value: 1 },
		{ label: '登録日時が古い順', value: 2 },
		{ label: '演奏時間が短い順', value: 3 },
		{ label: '演奏時間が長い順', value: 4 }
	];
	const initSelectedOpt = 1;
	const [selectedSortOpt, setSelectedSortOpt] = useState(initSelectedOpt); //ソートオプションの初期値設定
	const sort_opt_changed = (event) => {
		setSelectedSortOpt(event);
	};
	useEffect(() => {
		setLoadingScores(true);
		if (filteredScoreData?.length > 0) {
			const sortedData = [...filteredScoreData];

			if (sortedData.length > 1) {
				switch (Number(selectedSortOpt)) {
					case 1:
						sortedData.sort((a, b) => b.data.create_timestamp.localeCompare(a.data.create_timestamp));
						break;
					case 2:
						sortedData.sort((a, b) => a.data.create_timestamp.localeCompare(b.data.create_timestamp));
						break;
					case 3:
						sortedData.sort((a, b) => Number(a.data.duration) - Number(b.data.duration));
						break;
					case 4:
						sortedData.sort((a, b) => Number(b.data.duration) - Number(a.data.duration));
						break;
					default:
						break;
				}
			}
			setSortedScoreData(sortedData);
			//console.log("sortedScoreData:",sortedData);
		}
		setLoadingScores(false);

	}, [selectedSortOpt, filteredScoreData]);


    const [sortedScoreDataSliced, setsortedScoreDataSliced] = useState([]);
    const [loadingSlice, setLoadingSlice] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const itemNumToLoad = 20;
    const loadDelay = 30;

	useEffect(() => {
		//console.log("sortedScoreData:",sortedScoreData);
        if (sortedScoreData.length > 0) {
			const initLoadedItems = sortedScoreData.slice(0, Math.min(itemNumToLoad,sortedScoreData.length));
			//console.log("initLoadedItems:",initLoadedItems);
            setsortedScoreDataSliced(initLoadedItems);
            setHasMore(sortedScoreData.length > itemNumToLoad);
        }
    }, [sortedScoreData]);

	const loadMoreItems = useCallback(() => {
        //console.log("loadMoreItems");
        if (loadingSlice || !hasMore) return;
        setLoadingSlice(true);
        setTimeout(() => {
            const currentLength = sortedScoreDataSliced.length;
            const nextLength = Math.min(currentLength + itemNumToLoad, sortedScoreData.length);
            setsortedScoreDataSliced(prevSliced => [...prevSliced, ...sortedScoreData.slice(currentLength, nextLength)]);
            setHasMore(nextLength < sortedScoreData.length);
            setLoadingSlice(false);
        }, loadDelay);
    }, [loadingSlice, hasMore, sortedScoreDataSliced, sortedScoreData]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 100) {
                loadMoreItems();
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [loadMoreItems]);


	return (
		<div className={styles.basic}>
			<CommonHeader key="pageScoreList" setData={setSearchResults} setLoadingScores={setLoadingScores} initSearchTerm={CommonHeaderInitSearchTerm} savedQueryFields={CommonHeaderQueryFields} resetData={resetData} />
			<main>
				<div><DropDown
					options={sort_options}
					initSelectedValue={initSelectedOpt}
					onChange={(event) => sort_opt_changed(event)} /></div>
				<div>

					<h2 className={styles.pageText}>検索結果:{sortedScoreData.length}件</h2>

				</div>

				<div key="score_card_list" className={styles.scoreCard}>
					{loadingScores ? <Loading key='loading_icon' onloading={loadingScores} loading_object_name={"検索結果"} /> :
						sortedScoreData?.length > 0 ? sortedScoreDataSliced.map((result, index) => (
							<div className={styles.scoreCardWrapper}>
							<ScoreCard key={result.scoreId} score_id={result.scoreId} scoreData={result.data} myBookmarkList={listedBookmarkIds} resetData={resetData} />
							</div>
						))
							: <p>該当する楽譜が見つかりませんでした</p>
					}
				</div>
				<div className={styles.scoreCard}>
                    {loadingSlice && !loadingScores ? (
                        <Loading onloading={loadingSlice} loading_object_name={"検索結果"} />
                    ) : (
                        !loadingScores && !loadingSlice && !hasMore && <p>全件をロードしました</p>
                    )}
                </div>
			</main>
		</div>
	)

}

export default PageScoreList;
//2024/1/24 カード群の中央寄せ
//遅延ロード形式にする？