import React, { useState, useEffect } from 'react';
import styles from './ScoreCard.module.css';
//import mikeIcon from './karaoke_mike.png';
import earth from './img/earth.png';
import keyOpen from './img/key-open.png';
import keyClosed from './img/key-closed.png';
import comment from './img/comment.png';
import { MdStar, MdStarBorder, MdBoy, MdGirl, MdOutlineDisabledByDefault } from "react-icons/md";
import { FaCircle, FaComment, FaBookmark, FaRegBookmark } from "react-icons/fa6";
import { IoMdLock, IoMdPeople } from "react-icons/io";
import { GiEarthAsiaOceania } from "react-icons/gi";
import { API } from 'aws-amplify';
import { Link, useLocation, useNavigate, } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import LoadingScoreCard from '../Loading/LoadingScoreCard';
const API_PREFIX = process.env.REACT_APP_API_PREFIX;

function ScoreCard({ score_id, scoreData, myBookmarkList, resetData }) {
	/*しーちゃん向け　scoreData.difficultyみたいにアクセス可 */

	/*
	Score オブジェクトのフィールド
  
	const fieldInfo = [
	  { key: "version_name", type: "文字列", change:"版", description: "msczもしくはpdfファイルの更新タイミングでバージョンを変化。v1.0という文字列がデフォルトだが形式含め自由に変更可能" },
	  { key: "score_name", type: "文字列",  change:"楽譜",description: "楽譜の曲名" },
	  { key: "artist_name", type: "文字列", change:"楽譜", description: "楽曲のアーティスト名。" },
	  { key: "difficulty", type: "数値", change:"版", description: "ユーザに設定してもらう難易度。1(入門), 2(簡単), 3(普通), 4(やや難), 5(難しい)" },
	  { key: "part_male", type: "数値",  change:"版",description: "男声想定が何パートあるか" },
	  { key: "part_female", type: "数値",  change:"版",description: "女声想定が何パートあるか" },
	  { key: "is_zentaikyoku", type: "真偽値",  change:"楽譜",description: "全体曲か否か" },
	  { key: "have_vp", type: "真偽値",  change:"版",description: "VP譜があるか。" },
	  { key: "need_vp", type: "真偽値",  change:"楽譜",description: "VP譜が書いてあるかどうかに問わず、VPを想定した楽譜かどうか" },
	  { key: "duration", type: "数値",  change:"版",description: "演奏時間（秒）。自動パースできればバージョンごとに切り分け" },
	  { key: "voice_ranges", type: "パート名？と音域（文字列or数値2つ）のdict",  change:"版",description: "各パートの音域" },
	  { key: "youtube_links", type: "文字列",  change:"楽譜",description: "演奏動画の埋め込み。版との対応は登録時に推奨する（動画埋め込みにバージョン情報を付随できるように" },
	  { key: "s3_id", type: "dict {練習音源:{v1:aaa,v2:bbb},mscz:{v1:xxx},pdf:{v1:wxy}}",  change:"版",description: "練習音源や楽譜ファイルの実態があるs3へのキーの一覧を保持する" },
	  { key: "band_ids", type: "band_id(のリスト)",  change:"楽譜",description: "演奏する/したバンド。" },
	  { key: "arranger_ids", type: "user_id",  change:"楽譜",description: "アレンジャー。理屈上は版ごとにアレンジャー違いは起こりうるがレアケースとして対処しない方針。共著はどうする？？？" },
	  { key: "comments", type: "comment_idのlist", change:"楽譜", description: "youtubeみたいなコメント。" },
	  { key: "tags", type: "tag_idのlist",  change:"楽譜",description: "ニコ動みたいに自由に設定できるタグ。" },
	  { key: "accesibility", type: "数値", change:"楽譜", description: "楽譜の公開レベル。0:非公開、1:限定公開,2:全体公開" },
	  { key: "child_scoreid", type: "文字列", change:"楽譜", description: "その楽譜を元に改変した楽譜。楽譜のページに対して「これを元に更にアレンジした楽譜を登録」ボタンを導入" },
	  { key: "parent_scoreid", type: "文字列", change:"楽譜", description: "アレンジ元がある楽譜のアレンジ元を指す" },
	  
	  // 他のフィールド情報...
	];
	*/

	//ページ遷移関係のおまじない
	const location = useLocation();
	const navigate = useNavigate();

	//userID取得
	//cognitoID用
	const [userId, setUserId] = useState('');
	const [loading, setLoading] = useState(false);
	const [myCognito, setMyCognito] = useState('init');
	//const [userItem, setUseritem] = useState([]);
	const [bookmarkscores, setBookmarkscores] = useState([]);
	const [isBookmarked, setIsBookmarked] = useState(false);
	const fetchUserIdBookmark = async () => {
		setLoading(true);
		const requestOptions = {
			method: 'GET',
		};
		try {
			// ログイン中のユーザー情報を取得
			const currentUserInfo = await Auth.currentAuthenticatedUser();
			const cognitoId = currentUserInfo.signInUserSession.idToken.payload.sub;
			setMyCognito(cognitoId);

			// APIエンドポイントにクエリパラメータとしてcognitoidを付けてリクエストを送る
			const response = await fetch(`${API_PREFIX}/api/user/whoami/${cognitoId}`);
			if (!response.ok) {
				throw new Error('Failed to fetch user ID');
			}
			const data = await response.json();
			setUserId(data.userId);
			///api/user/{userId}/bookmarks
			if (myBookmarkList) {
				setBookmarkscores(myBookmarkList);
				if (myBookmarkList.includes(score_id)) {
					setIsBookmarked(true);
				}
			} else {
				const response_bookmarks = await fetch(`${API_PREFIX}/api/user/${data.userId}/bookmarks`, requestOptions);
				if (response_bookmarks.status === 200) {
					const data_bookmarks = await response_bookmarks.json(); // APIからのレスポンスデータをJSONとしてパース
					setBookmarkscores(data_bookmarks.bookmarks); // パースしたデータを状態にセット
					if (data_bookmarks.bookmarks.includes(score_id)) {
						setIsBookmarked(true);
					}
					//console.log("bookmark data:", data_bookmarks);
					//console.error('Item successfully got.');
				} else {
					console.error('Failed to get item.');
				}
			}

		} catch (error) {
			console.error('Error fetching user ID:', error);
			setUserId('Error fetching user ID');
			return false;
		} finally {
			setLoading(false);
			return true;
		}
	};

	const difficultyStrDict = {
		1: "入門",
		2: "簡単",
		3: "普通",
		4: "やや難",
		5: "難しい"
	}

	//Read (特定アイテム)
	const [selectedScoreMetadata, setSelectedScoreMetadata] = useState([]);
	//const selectedScoreMetadata = [];
	const [arrangerName, setArrangerName] = useState("");
	const getArrangerInfo = async (score_id) => {
		const requestOptions = {
			method: 'GET',
		};
		try {
			//アレンジャーのuser_idのリストが取得される
			const response = await fetch(`${API_PREFIX}/api/arranged/score/${score_id}/users`, requestOptions);
			const data = await response.json();
			//console.log("arranger data:", data);
			if (data && data[0]) {
				const response_useritem = await fetch(`${API_PREFIX}/api/user/${data[0]}`, requestOptions);
				const data_useritem = await response_useritem.json();
				setArrangerName(data_useritem.nickname.S);
				//selectedScoreMetadata = data;
			} else {
				//console.error(selectedScoreMetadata.score_name, "のアレンジャーが指定されていません");
				setArrangerName("未登録");
			}
		} catch (error) {
			console.error(error + ',failed to get arranger name.:' + selectedScoreMetadata.score_name);
			//console.error(error);
		}
	}

	//const [tagStrList, setTagStrList] = useState([]); // タグの中身の配列
	const [tagIdStrList, setTagIdStrList] = useState([]); //タグのIDと中身のペアのタプルの配列
	const getTagIdStrFromId = async (tag_id) => {
		const requestOptions = {
			method: 'GET',
		};
		try {
			const response = await fetch(`${API_PREFIX}/api/tags/${tag_id}`, requestOptions);
			const data = await response.json();
			//console.log("getTagIdStrFromId:", data);
			return { "id": data.tagId, "content": data.content };
		} catch (error) {
			console.error("Cannot get tag string from ", tag_id);
		}
	}



	const getTagStrFromIdWrapper = async (tag_id_list) => {
		//console.log("tag_id_list.length:", tag_id_list.length);
		const tagIdStrList_init = [];
		for (var i = 0; i < tag_id_list.length; i++) {
			const tag_id_str = await getTagIdStrFromId(tag_id_list[i]);
			//console.log("tag_str:", tag_id_str);
			tagIdStrList_init.push(tag_id_str);
		}
		/* 使っていなさそうなので一旦コメントアウト　
		String.prototype.bytes = function () {
			return (encodeURIComponent(this).replace(/%../g, "x").length);
		}
		*/
		const getStringLength = (str) => {
			return [...str].reduce((acc, char) => {
				const code = char.charCodeAt(0);
				// Consider a character as full-width if its code is greater than 255
				return acc + (code > 255 ? 2 : 1);
			}, 0);
		};
		const tagIdStrList_sorted = tagIdStrList_init.sort((a, b) => getStringLength(a.content) - getStringLength(b.content));
		setTagIdStrList(tagIdStrList_sorted);
		//console.log("tagidstr:", tagIdStrList_sorted);
	}

	const getTagInfo = async (score_id) => {
		const requestOptions = {
			method: 'GET',
		};
		try {
			const response = await fetch(`${API_PREFIX}/api/score/${score_id}/tags`, requestOptions);
			const data = await response.json();
			//console.log("tag info:", data);
			getTagStrFromIdWrapper(data);
		} catch (error) {
			console.error(error, ",failed to get tag string from id:", score_id);
		}
	}

	const [commentNum, setCommentNum] = useState(0);
	const getCommentNum = async (score_id) => {
		const requestOptions = {
			method: 'GET',
		};
		try {
			const response = await fetch(`${API_PREFIX}/api/score/${score_id}/comments`, requestOptions);
			const data = await response.json();
			//console.log("comment info:", data);
			//const tagStrList_cp = [...tagStrList];
			//tagStrList_cp.push(data);
			if (data.length <= 99) {
				setCommentNum(data.length);
			} else {
				setCommentNum(99);
			}

		} catch (error) {
			console.error(error, ",failed to get comment num from id:", score_id);
		}
	}

	const getScoreInfo = async () => {
		const requestOptions = {
			method: 'GET',
		};
		try {
			if (scoreData) {
				setSelectedScoreMetadata(scoreData);
			} else {
				const response = await fetch(`${API_PREFIX}/api/score/${score_id}`, requestOptions);
				const data = await response.json();
				setSelectedScoreMetadata(data);
			}
			//console.log("data.tag_ids:",data.tags);
			getArrangerInfo(score_id);
			/*
			if (data.tags !== undefined && data.tags.length > 0) {
				data.tags.forEach(tag_id => {
					getTagInfo(tag_id);
				});
			}*/
			getTagInfo(score_id);
			getCommentNum(score_id);
			//selectedScoreMetadata = data;
		} catch (error) {
			console.error('Failed to get score item.', error);
			//console.error(error);
		}
	}

	// NUM=値 LEN=桁数
	function zeroPadding(NUM, LEN) {
		return (Array(LEN).join('0') + NUM).slice(-LEN);
	}

	//ブックマーク関連、Copied from ScoreDetail
	const [myVersionId, setMyVersionId] = useState(null);
	//ブックマーク登録
	const Bookmark = async () => {
		try {
			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
			};
			const response = await fetch(`${API_PREFIX}/api/bookmark/${userId}/score/${score_id.split("_")[0] + "_" + myVersionId}`, requestOptions);

			if (response.ok) {
				setIsBookmarked(true);
			} else {
				throw new Error(`ブックマークの登録に失敗しました: ${response.statusText}, score_id:${score_id}`);
			}
		} catch (error) {
			console.error('ブックマーク登録中のエラー:', error);
		}
	}
	//ブックマーク解除
	const UnBookmark = async () => {
		try {
			const requestOptions = {
				method: 'DELETE'
			};
			const response = await fetch(`${API_PREFIX}/api/bookmark/${userId}/score/${score_id.split("_")[0] + "_" + myVersionId}`, requestOptions);
			if (response.ok) {
				setIsBookmarked(false);
			} else {
				throw new Error(`ブックマークの解除に失敗しました: ${response.statusText}, score_id:${score_id}`);
			}
		} catch (error) {
			console.error('ブックマーク解除中のエラー:', error);
		}
	}

	useEffect(() => {
		setLoading(true);
		const getAllInfo = async () => {
			fetchUserIdBookmark().then(() => {
				if (bookmarkscores.includes(score_id)) {
					setIsBookmarked(true);
				}
			});
			getScoreInfo();
			setMyVersionId(score_id.split("_")[1]);
		}
		getAllInfo().then(() => {
			setLoading(false);
		})
	}, []);

	//タグがクリックされたときの挙動
	const tagClicked = async (tag_obj) => {
		// タグIDを含む楽譜ID取得
		try {
			const fetch_s_tag = await fetch(`${API_PREFIX}/api/tag/${tag_obj["id"]}/scores`, { method: 'GET' });
			const result_ids = await fetch_s_tag.json();
			if(typeof resetData === 'function'){
				await resetData();
			}
			navigate('/search', { state: { intersectionArray: result_ids }, replace: true });
		} catch (error) {
			console.error('There was an error!', error);
		}

	};

	return (
		<div>
			{
				loading ? <div className={styles.card}><LoadingScoreCard key='loading_icon' onloading={loading} loading_object_name={"楽譜データ"} /></div> :
					<div className={styles.card}>
						<div className={`${styles.titleBox}`}>
							<Link to={`/score/${score_id}`} className={`${styles.titleBoxStr} ${styles.LinkToDetail}`}>
								<h1 className={styles.title}>{selectedScoreMetadata.score_name ? selectedScoreMetadata.score_name : "no named score"}</h1>
							</Link>
							<Link to={`/score/${score_id}`} className={`${styles.artistBoxStr} ${styles.LinkToDetail}`}>
								<h2 className={styles.artist}>{selectedScoreMetadata.artist_name ? selectedScoreMetadata.artist_name : "no named artist"}</h2>
							</Link>
							<div className={styles.bookmark}>
								<div className={styles.bookmarkIcon}>{
									isBookmarked ? <button className={styles.bookmarkButton} onClick={UnBookmark}><FaBookmark color='#ffffff' /></button> : <button className={styles.bookmarkButton} onClick={Bookmark}> <FaRegBookmark color='#ffffff' /> </button>
								}</div>
							</div>
						</div>
						<div className={styles.subItems}>
							{/* 編成、曲尺、曲調、難易度、編曲だけにする */}
							<table className={styles.infoTable}>
								<tbody>
									<tr>
										<th><p>編成</p></th>
										<td>
											<div className={styles.part}>
												<div className={styles.partPerSexNumber}><p>男{selectedScoreMetadata.part_male ? Number(selectedScoreMetadata.part_male) : 0}</p></div>
												<div className={styles.partPerSexNumber}><p>女{selectedScoreMetadata.part_female ? Number(selectedScoreMetadata.part_female) : 0}</p></div>
												<div className={styles.partPerSexNumber}>
													{/*<p className={styles.description}>{selectedScoreMetadata.have_vp ? "VP譜あり" : ""}</p>*/}
													<p>{selectedScoreMetadata.have_vp === 'true' ? "VP(譜あり)" :
														selectedScoreMetadata.need_vp === 'true' ? "VP(譜なし)" : "VPなし"}</p>
												</div>
											</div>
										</td>
									</tr>
									<tr>
										<th><p>曲尺</p></th>
										<td>
											<div className={styles.durationWrapper}>
												<p className={styles.duration}>{selectedScoreMetadata.duration ? zeroPadding(Math.floor(Number(selectedScoreMetadata.duration) / 60), 2) : "00"}:{selectedScoreMetadata.duration ? zeroPadding(Number(selectedScoreMetadata.duration % 60), 2) : "00"}</p>
											</div>
										</td>
									</tr>
									<tr>
										<th><p>曲調</p></th>
										<td>
											<div className={styles.tempoWrapper}>
												<p className={styles.tempo}>{selectedScoreMetadata.tempo_str ? selectedScoreMetadata.tempo_str : "ミドルテンポ"}</p>
											</div>
										</td>
									</tr>
									<tr>
										<th><p>難易度</p></th>
										<td>
											<div className={styles.difficultyWrapper}>
												<p className={styles.difficulty}>{difficultyStrDict[Number(selectedScoreMetadata.difficulty)]}</p>
											</div>
										</td>
									</tr>
									<tr>
										<th><p>編曲</p></th>
										<td>
											<div className={styles.arrangerWrapper}>
												<p className={styles.arranger}>{arrangerName ? arrangerName : "未登録"}</p>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
							{/*
				<Link to={`/score/${score_id}`} className={`${styles.part} ${styles.LinkToDetail}`}>
					<div className={styles.partPerSex}>
						<div className={styles.partPerSexIcon}>{<FaCircle color='rgb(0,160,233)' />}</div>
						<div className={styles.partPerSexNumber}><p>{selectedScoreMetadata.part_male ? Number(selectedScoreMetadata.part_male) : 0}</p></div>
						<div className={styles.partPerSexIcon}>{<FaCircle color='rgb(243,170,214)' />}</div>
						<div className={styles.partPerSexNumber}><p>{selectedScoreMetadata.part_female ? Number(selectedScoreMetadata.part_female) : 0}</p></div>
					</div>
					<div className={styles.vpWrapper}>
						{selectedScoreMetadata.have_vp === 'true' ? <img src={VP_ari} alt="VP_ari" className={styles.VPIcon} /> :
							selectedScoreMetadata.need_vp === 'true' ? <img src={VP_soutei} alt="VP_soutei" className={styles.VPIcon} /> :
								<img src={VP_nashi} alt="VP_nashi" className={styles.VPIcon} />}
					</div>
				</Link>
				<Link to={`/score/${score_id}`} className={`${styles.part2} ${styles.LinkToDetail}`}>
					<div className={styles.duration}>
						<p>{selectedScoreMetadata.duration ? zeroPadding(Math.floor(Number(selectedScoreMetadata.duration) / 60), 2) : "00"}:{selectedScoreMetadata.duration ? zeroPadding(Number(selectedScoreMetadata.duration % 60), 2) : "00"}</p>
					</div>
					<div className={styles.tempo}>
						<p>{selectedScoreMetadata.tempo_str ? selectedScoreMetadata.tempo_str.slice(0, selectedScoreMetadata.tempo_str.length - 3) : "ミドル"}</p>
					</div>
				</Link>
				<Link to={`/score/${score_id}`} className={`${styles.part3} ${styles.LinkToDetail}`}>
					{
						(() => {
							const icons = [];

							for (var i = 0; i < 5; i++) {
								if (i < Number(selectedScoreMetadata.difficulty)) {
									icons.push(<div key={i} className={styles.star}><MdStar /></div>);
								} else {
									icons.push(<div key={i} className={styles.star}><MdStarBorder /></div>);
								}
							}
							return icons;
						})()
					}
				</Link>
				<Link to={`/score/${score_id}`} className={`${styles.arrangerWrapper} ${styles.LinkToDetail}`}>
					<p className={styles.arranger}>{arrangerName ? arrangerName : "編曲者不明"}</p>
				</Link>
				*/}

							<div className={styles.footer}>
								{/* タグ、コメント数、アクセシビリティ、ただしスマホのときは下ではなく右に表示するようにする */}
								<div className={styles.leftBottom}>
									<div className={styles.tagWrapper}>
										{
											tagIdStrList ? tagIdStrList.map(tag_obj => {
												return <button key={tag_obj.id} onClick={() => tagClicked(tag_obj)} className={styles.tag}><p>{'#' + tag_obj["content"]}</p></button>
											}) : <div className={styles.tag}><p> </p></div>
										}
									</div>
									<div className={styles.version}><p>version : {selectedScoreMetadata.version_name}</p></div>
								</div>
								<div className={styles.rightBottom}>
									<div className={styles.commentWrapper}>
										<div className={styles.comment}>
											<div className={styles.commentNum}>{commentNum}</div>
											<div className={styles.commentIcon}><img src={comment} className={styles.icon} alt="icon-comment" /></div>
										</div>
									</div>
									<div className={styles.accesibility}>
										<div className={styles.accessibilityIconWrapper}>
											{Number(selectedScoreMetadata.accesibility) === 0 ? <img src={keyClosed} className={styles.icon} alt="icon-key-closed" />
												: Number(selectedScoreMetadata.accesibility) === 1 ? <img src={keyOpen} className={styles.icon} alt="icon-key-open" />
													: <img src={earth} className={styles.icon} alt="icon-earth" />}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>}
		</div>
	);
}

export default ScoreCard;

//2024/1/20 文字数制限かけて（10~20文字くらい、カラオケの曲名とか見て）、それ以上の文字は...で略す
//ヘッダーをmainタグで囲む