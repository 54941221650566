import React, { useState, useEffect } from 'react';
//import { Link } from 'react-router-dom';
//import Loading from '../components/Utils/Loading/Loading';
//import ScoreCard from '../components/Utils/ScoreCard/ScoreCard';
import CommonHeader from '../components/Utils/CommonHeader/CommonHeader';
import Button from '../components/Utils/Button/Button';
//import DropDown from '../components/Utils/Ui/DropDown/DropDown';
import DropDownForUserList from '../components/Utils/Ui/DropDown/DropDownForUserList';
import TextInput from '../components/Utils/Ui/TextInput/TextInput';

import styles from './PageBandEdit.module.css'

//import { useCallback } from 'react';
import { useNavigate } from 'react-router';
//import { MouseEvent } from 'react';

const API_PREFIX = process.env.REACT_APP_API_PREFIX;

export const PageBandCreate = () => {

	//所属バンド情報取得
	const [banditems, setBanditems] = useState([]);

	const getBandList = async () => {
		const requestOptions = {
			method: 'GET',
		};
		try {
			const API_PREFIX = process.env.REACT_APP_API_PREFIX;
			const response = await fetch(`${API_PREFIX}/api/band/bandlist`, requestOptions);
			if (response.status === 200) {
				const data = await response.json(); // APIからのレスポンスデータをJSONとしてパース
				setBanditems(data); // パースしたデータを状態にセット
				//console.error('band Item successfully got.');
			} else {
				console.error('Failed to get band item.');
			}
		} catch (error) {
			console.error('There was an error!', error);
		}
		return;
	}

	//新規バンド
	const [bandname, setBandname] = useState("");
	const [bandmaster, setBandmaster] = useState("");
	//const [bandmembers, setBandmembers] = useState(["", ""]);
	const [bandmembers, setBandmembers] = useState([]);

	const handleSelectChange = (index, value) => {
		const newBandMembers = [...bandmembers];
		newBandMembers[index] = value;

		// 最後の選択が空でない場合、新しい空の選択を追加
		if (index > 0 && index === bandmembers.length - 1 && value) {
			newBandMembers.push("");
		}

		setBandmembers(newBandMembers);
	};

	const BandMasterChange = (value) => {
        const bandmembers_cp = bandmembers;
        if(!bandmembers_cp.includes(bandmaster) && bandmaster.length > 0){
            bandmembers_cp.push(bandmaster)
        }
        const bandmembers_cp2 = bandmembers_cp.filter(member => (member.length > 0) && (member!==value));
        //console.log(bandmembers_cp2,value);
        setBandmaster(value);
        setBandmembers(bandmembers_cp2);
    }


	const createBand = async () => {
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ bandname: bandname, bandmaster: bandmaster, bandmembers: bandmembers })
		};
		try {
			const response = await fetch(`${API_PREFIX}/api/band`, requestOptions);
			if (response.status === 200) {
				console.error('Successfully added band');
				navigate('/band_admin'); //reload
			} else {
				alert("バンド登録に失敗しました。ほぼ同タイミングで同名バンドを作成したか、バンド管理DBへのアクセスが一時的に失敗しています。");
				console.error(response.text());
				console.error('Failed to add item.');
			}
		} catch (error) {
			console.error('There was an error!', error);
		}
	}

	//メンバー情報取得
	const [useritems, setUseritems] = useState([]);
	const [userkiList, setUserkiList] = useState([]);

	const getUserList = async () => {
		const requestOptions = {
			method: 'GET',
		};
		try {
			const response = await fetch(`${API_PREFIX}/api/user/userlist`, requestOptions);
			if (response.status === 200) {
				const data = await response.json(); // APIからのレスポンスデータをJSONとしてパース
				setUseritems(data); // パースしたデータを状態にセット
				//console.error('Item successfully got.');
				data.map((item)=>{
					if(!userkiList.includes(item.ki.N)){
						userkiList.push(item.ki.N);
					}
				})
				setUserkiList(userkiList.sort((a,b)=>Number(a)-Number(b)))
			} else {
				console.error('Failed to get user item.');
			}
		} catch (error) {
			console.error('There was an error!', error);
		}
		return;
	}

	//checkbox用
    const getNewMembers = (userId, checked) => {
        if (checked) {
            return [...bandmembers.filter(id => id !== userId), userId]
        } else {
            if(bandmaster === userId){
                setBandmaster('');
            }
            return bandmembers.filter(id => id !== userId)
        }
    }
	const handleCheckboxChange = (userId, checked) => {
		// checkboxが変更されたときの処理をここに追加する
		const newBandMembers = getNewMembers(userId, checked);
		//console.log(newBandMembers, checked);
		setBandmembers(newBandMembers);
	};

	//バンド管理ページ遷移用
	const navigate = useNavigate();

	const handleToBandAdmin = () => {
        navigate("/band_admin");
    }
	
	useEffect(() => {
		getUserList();
		getBandList();
		//console.log(selectedScoreMetadata);
	}, []);

	const [bandmemberNum, setbandmemberNum] = useState(0);
    useEffect(()=>{
        if(bandmaster==="" || bandmembers.includes(bandmaster)){
            setbandmemberNum(bandmembers.length);
        }else{
            setbandmemberNum(bandmembers.length+1);
        }
    }, [bandmaster,bandmembers]);

	//重複管理
	const [isBandNameTaken, setIsBandNameTaken] = useState(false);



  	// バンド名が変更されるたびに重複チェックを実行
	useEffect(() => {
		const isTaken = banditems.some((item) => item.bandname.S === bandname);
		setIsBandNameTaken(isTaken);
	}, [bandname, banditems]); // bandnameまたはbanditemsが変わるたびにチェック

	return (
		<div>
			<CommonHeader />
			<main>
				<div className="container">
				<div>
					<Button type="normal" child="バンド管理ページに戻る" onClick={handleToBandAdmin}/>
					<h2>新規バンド登録</h2>
					<div className='input-container'>
						<div>
							<h3>バンド名</h3>
							<TextInput
								placeholder="バンド名を入力してください"
								value={bandname}
								onChange={(e) => setBandname(e.target.value)}
								useAPI=""
								suggestions={[]}
								width="M"
								maxLength={50}
							/>
							{isBandNameTaken ? <p className={styles.bandname_console.error}>このバンド名は既に使われています</p> : <p className={styles.bandname_console.error}></p>}
						</div>

						<br></br>

						<div>
							<h3>バンドメンバー登録({bandmemberNum}人)</h3>

							<div>
								{userkiList.map((ki)=>(
									<div>
										<h3>{ki}期</h3>
										{useritems.filter((user) => user.ki.N === ki).sort((a,b) => a.nickname.S.localeCompare(b.nickname.S, 'ja')).map((user) => (
												<div key={user.user_id.S}>
													<input
														key={user.user_id.S}
														type="checkbox"
														id={user.user_id.S}
														onChange={(e) => handleCheckboxChange(user.user_id.S, e.target.checked)}
													/>
													<label htmlFor={user.user_id.S}>{user.nickname.S}</label>
												</div>
											)
										)}
									</div>
									)
								)}
							</div>
						</div>


						<br></br>
						<div>
							<h3>バンドマスター登録</h3>
							<DropDownForUserList selectKey={0} defaultValue='' useritems={useritems.filter(item => bandmembers.includes(item.user_id.S) || bandmaster===item.user_id.S)} onChange={BandMasterChange} />
							<br></br>
						</div>


						<Button type="primary3" child="バンド作成" disabled={(isBandNameTaken) || (bandname.length == 0) || (bandmaster.length == 0)} onClick={createBand} />
						<br></br>
					</div>
				</div>
				</div>
			</main>


		</div>
	)

}

export default PageBandCreate;