import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import styles from './PageScoreDetails.module.css';
import Modal from 'react-modal';
import YouTube from 'react-youtube';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

import CommonHeader from '../components/Utils/CommonHeader/CommonHeader';
import DropDown from '../components/Utils/Ui/DropDown/DropDownForVersionList';
import Button from '../components/Utils/Button/Button';
import CommentCard from '../components/CommentCard/CommentCard';
import TextInput from '../components/Utils/Ui/TextInput/TextInput';
import VisualizeVoiceranges from '../components/PianoKeyboard/voicerange';
import LineShare from '../components/Utils/LineShare/LineShare';
import { IconContext } from 'react-icons'
import { FaBookmark, FaRegBookmark } from "react-icons/fa6";
import { Document, Page, pdfjs } from 'react-pdf';
import { pdfjsLib } from "pdfjs-dist";
import { useParams } from 'react-router-dom';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import YoutubeContainer from '../components/Utils/YoutubeContainer/YoutubeContainer';

import icon_public from '../img/detail_public.png';
import icon_onlyband from '../img/detail_onlyband.png';
import icon_onlyme from '../img/detail_onlyme.png';
import icon_comment from '../img/detail_comment.png';
import icon_bookmark from '../img/detail_bookmark.png';
import icon_edit from '../img/detail_edit.png';
import icon_cantedit from '../img/detail_cantedit.png';
import icon_delete from '../img/detail_delete.png';
import icon_cantdelete from '../img/detail_cantdelete.png';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const API_PREFIX = process.env.REACT_APP_API_PREFIX;
export const PageScoreDetail = () => {
  let { score_id } = useParams();

  const location = useLocation();
  const navigate = useNavigate();

  //const [myScoreId, setMyScoreId] = useState('');
  //const [myScoreId_w_version, setMyScoreId_w_version] = useState('');
  //const [versionId, setVersionId] = useState('');
  const [scoreName, setScoreName] = useState('');
  const [artistName, setArtistName] = useState('');
  const [difficulty, setDifficulty] = useState(1);
  const [duration, setDuration] = useState(0);
  const [tempo, setTempo] = useState(-1);
  const [tempo_str, setTempoStr] = useState("");
  const [partMale, setPartMale] = useState(0);
  const [partFemale, setPartFemale] = useState(0);
  const [haveVp, setHaveVp] = useState("true");
  const [needVp, setNeedVp] = useState("false");
  const [isZentaikyoku, setIsZentaikyoku] = useState(false);
  const [isChangeLead, setIsChangeLead] = useState(false);
  const [voiceRanges, setVoiceRanges] = useState(null);
  const [accesibility, setAccesibility] = useState(0);

  const [parentScoreid, setParentScoreid] = useState(null);
  const [childScoreid, setChildScoreid] = useState('');
  const [youtubeLinks, setYoutubeLinks] = useState('');
  const [versionNames, setVersionNames] = useState('');
  const [description, setDescription] = useState('');
  const [registered_userid, setRegisterdUserId] = useState('');

  const accesibility_arr = ["非公開", "限定公開", "全体公開"];
  const accesibilityImages = [icon_onlyme, icon_onlyband, icon_public]; //上のアイコン版
  const difficulty_arr = ["入門", "簡単", "普通", "やや難", "難しい"]; //1(入門), 2(簡単), 3(普通), 4(やや難), 5(難しい)"
  const need_vp_dict = { true: "あり", false: "なし" };
  const have_vp_dict = { true: "あり", false: "なし" };
  //バージョン関係
  const [versionDropDownOptions, setVersionDropDownOptions] = useState([]);
  const [gotVersions, setGotVersions] = useState(null);
  const [myVersionId, setMyVersionId] = useState(null);

  //pdf
  const pdfContainer = useRef(null);
  const [scale, setScale] = useState(0.9);

  //編集ボタン
  const [editpolicy, setEditpolicy] = useState(false);

  //line共有ボタン
  const baseUrl = "https://ajy-addlib.com/score/";

  const getVersions = async () => {
    try {
      const response = await axios.get(`${API_PREFIX}/api/score/versions/${score_id}`);
      //console.log('versions content got:', response.data);
      setGotVersions(JSON.stringify(response.data));
      // 取得したデータをドロップダウンのオプション形式に変換
      const versionOptions = response.data.map(item => ({
        label: item.versionName,
        value: item.versionId
      }));
      // stateに設定
      setVersionDropDownOptions(versionOptions);
    } catch (error) {
      console.error('Error getting version:', error.response ? error.response.data : error.message);
      // エラー時の処理をここに記述
    }
  }
  const handleVersionDropDownChange = (value) => {
    const nexturl = "/score/" + score_id.split("_")[0] + "_" + value;
    setMyVersionId(value);
    navigate(nexturl);
  };

  //youtubeレスポンシブ用
  const useYt_Opts = () => {
    const [yt_opts, setYt_Opts] = useState({
      playerVars: {
        autoplay: 0, // 自動再生
        mute: 0, // 音声をミュート
        playsinline: 1, // インライン再生
        loop: 0, // 動画を繰り返し再生
      }
    });


    const updateScale = () => {
      if (pdfContainer.current) {
        const containerWidth = pdfContainer.current.clientWidth;
        setScale(containerWidth / 595); // 595はPDFの標準的な幅（pt単位）
      }
    };

    useLayoutEffect(() => {
      const adjustScale = () => {
        if (pdfContainer.current) {
          const containerWidth = pdfContainer.current.clientWidth;
          setScale(containerWidth / 595); // 595はPDFの標準的な幅（pt単位）
        }
      };

      window.addEventListener('resize', adjustScale);
      adjustScale(); // コンポーネントマウント時にもサイズ調整

      return () => {
        window.removeEventListener('resize', adjustScale);
      };
    }, []);
    /*
    useEffect(() => {
      const adjustScale = () => {
        // nullチェックをイベントハンドラ内で実行
        if (pdfContainer.current) {
          const containerWidth = pdfContainer.current.clientWidth;
          setScale(containerWidth / 595); // 595はPDFの標準的な幅（pt単位）
        }
      };
    
      window.addEventListener('resize', adjustScale);
      adjustScale(); // コンポーネントマウント時にも調整
    
      return () => {
        window.removeEventListener('resize', adjustScale);
      };
    }, []); // 依存配列は空にしておきます
    */

    useLayoutEffect(() => {

      const handleResize = () => {
        const yt_opts_cp = { ...yt_opts };
        yt_opts_cp.width = Math.min(window.innerWidth * 0.8, 350 / 9 * 16);
        yt_opts_cp.height = Math.min(window.innerWidth * 0.8 / 16 * 9, 350);
        setYt_Opts(yt_opts_cp);
      };
      window.addEventListener('resize', handleResize);

      handleResize();


      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    return yt_opts;
  };
  const yt_opts = useYt_Opts();

  //閲覧記録
  const isWatch = async (userId) => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      };
      if (score_id && userId) {
        const response = await fetch(`${API_PREFIX}/api/recent/${userId}/score/${score_id}`, requestOptions);
        //console.log(`閲覧記録を更新しました`);
        if (!response.ok) {
          throw new Error(`閲覧記録の更新に失敗しました: ${response.statusText}`);
        }
      } else {
        console.error("score_idなし");
      }
    } catch (error) {
      console.error('閲覧記録更新中のエラー:', error);
    }
  };

  //ユーザー情報
  const fetchUserData = async () => {
    try {
      // ログイン中のユーザー情報を取得
      const currentUserInfo = await Auth.currentAuthenticatedUser();
      const cognitoId = currentUserInfo.signInUserSession.idToken.payload.sub;

      // Cognito IDからユーザーIDを取得
      const userIdResponse = await axios.get(`${API_PREFIX}/api/user/whoami/${cognitoId}`);
      const userId = userIdResponse.data.userId;
      setMyUserId(userId);
      //isWatch(userId);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  //ユーザー関連情報
  const [myUserId, setMyUserId] = useState(null);

  const isCanbeEditedScore = async (score_id) => {
    // 編集権限があるものならtrue、そうでなければfalse
    // 楽譜のaccesibilityの対応： 0（非公開)、1（限定公開）、2(全体公開)
    // 1.楽譜に対して情報取得する
    let scoreData = null;
    //console.log("scoreid");
    //console.log(score_id);
    const requestOptions = {
      method: 'GET',
    };
    try {
      const API_PREFIX = process.env.REACT_APP_API_PREFIX;
      const response = await fetch(`${API_PREFIX}/api/score/${score_id}`, requestOptions);
      const data = await response.json();
      scoreData = data;
      //console.log("data.tag_ids:",data.tags);
      //selectedScoreMetadata = data;
    } catch (error) {
      console.error('Failed to get score item.', error, score_id);
    }

    //console.log("1");
    // 2-2. 限定公開のとき -> registed_useridとの突き合わせ & アレンジャーと演奏バンドも問い合わせ
    // 2-2-1. 登録者
    if (myUserId === scoreData.registered_userid) {
      setEditpolicy(true);
      return;
    }
    // 2-2-2. アレンジャー
    try {
      // アレンジャーの情報を取得
      const requestOptions = {
        method: 'GET',
      };
      const arrangersResponse = await fetch(`${API_PREFIX}/api/arranged/score/${score_id}/users`, requestOptions);
      const arrangersData = await arrangersResponse.json();
      if (arrangersData.length > 0) {
        const arranger_id = arrangersData[0];
        if (myUserId === arranger_id) {
          setEditpolicy(true);
          return;
        }
      }
    } catch (error) {
      console.error('Failed to get score item.', error);
    }
    // 2-2-3. 演奏バンド
    try {
      // 演奏バンドの情報を取得
      const requestOptions = {
        method: 'GET',
      };
      const bandsResponse = await fetch(`${API_PREFIX}/api/performed/score/${score_id}/bands`, requestOptions);
      const bandsData = await bandsResponse.json();
      // バンドデータを適切な形式に変換してからステートにセット
      for (let i = 0; i < bandsData.length; i++) {
        const curr_band_id = bandsData[i];
        const requestOptions_b = {
          method: 'GET',
        };
        const currentBandResponse = await fetch(`${API_PREFIX}/api/band/${curr_band_id}`, requestOptions_b);
        const currentBandData = await currentBandResponse.json();
        const bandmaster = currentBandData.bandmaster.S;
        const bandmembers = currentBandData.bandmembers.SS;
        if (myUserId === bandmaster) {
          setEditpolicy(true);
          return;
        }
        for (let j = 0; j < bandmembers.length; j++) {
          if (myUserId === bandmembers[j]) {
            setEditpolicy(true);
            return;
          }
        }
      }
    } catch (error) {
      console.error('Failed to get score item.', error);
    }
    // ここまで来てもreturn trueされてない→アウト
    return;
  };


  //let youtube_link = "BeU_ycswILY";
  //youtubeリンク整形
  function extractYouTubeId(url) {
    try {
      let id = '';
      const regex = /(?:\/|v=)([A-Za-z0-9_-]{11})(?:\?|&|$)/;
      const match = url.match(regex);
      if (match) {
        id = match[1];
      }
      return id;
    } catch (error) {
      return '';
    }
  }

  //APIから情報取得
  //楽譜情報取得
  const [scoreMetadata, setScoreMetadata] = useState({});
  const getScoreInfo = async () => {
    const requestOptions = {
      method: 'GET',
    };
    try {
      const API_PREFIX = process.env.REACT_APP_API_PREFIX;
      const response = await fetch(`${API_PREFIX}/api/score/${score_id}`, requestOptions);
      const data = await response.json();
      //console.log("meta data:", data);
      setScoreMetadata(data);
      setScoreName(data.score_name);
      setArtistName(data.artist_name);
      setDifficulty(Number(data.difficulty));
      setPartMale(Number(data.part_male));
      setPartFemale(Number(data.part_female));
      setIsZentaikyoku(data.is_zentaikyoku === 'true');
      setHaveVp(data.have_vp === 'true');
      setNeedVp(data.need_vp === 'true');
      setDuration(data.duration);
      setYoutubeLinks(data.youtube_links);
      setAccesibility(Number(data.accesibility));
      setDescription(data.description);
      setIsChangeLead(data.is_changeLead === 'true');
      setTempo(Number(data.tempo) || -1);
      setVoiceRanges(data.voice_ranges || null);
      setTempoStr(data.tempo_str);
      setVersionNames(data.version_name);
    } catch (error) {
      console.error('Failed to get score item.');
    }
  }
  //アレンジャー情報取得
  const [arrangerName, setArrangerName] = useState("");
  const [arrangerId, setArrangerId] = useState(null);
  const getArrangerInfo = async () => {
    const requestOptions = {
      method: 'GET',
    };
    try {
      if (score_id !== undefined) {
        const API_PREFIX = process.env.REACT_APP_API_PREFIX;
        //id取得
        const response_id = await fetch(`${API_PREFIX}/api/arranged/score/${score_id}/users`, requestOptions);
        const data_id = await response_id.json();
        //console.log("arranger id:", data_id);
        //名前取得
        const user_id = data_id[0];
        setArrangerId(user_id);
        const response_name = await fetch(`${API_PREFIX}/api/user/${user_id}`, requestOptions);
        const data_name = await response_name.json();
        //console.log("arranger data:", data_name);
        setArrangerName(data_name.nickname.S);
      } else {
        //console.log(scoreMetadata.score_name, "のアレンジャーが指定されていません");
        setArrangerName("未登録");

      }
    } catch (error) {
      console.error(error + ',failed to get arranger name.:' + scoreMetadata.score_name);
    }
  }
  //演奏バンド情報取得
  const [bandNameAndId, setBandNameAndId] = useState([]);
  const getBandName = async (bandId) => {
    const requestOptions = {
      method: 'GET',
    };
    try {
      if (score_id !== undefined) {
        const API_PREFIX = process.env.REACT_APP_API_PREFIX;
        const response = await fetch(`${API_PREFIX}/api/band/${bandId}`, requestOptions);
        const data = await response.json();
        /*
        setBandName((item) => [...item, data.bandname.S]);
        */
        setBandNameAndId((prevBandObjects) => {
          // someを使用してオブジェクトの中身を比較
          if (!prevBandObjects.some(item => item.id === bandId && item.bandname === data.bandname.S)) {
            return [...prevBandObjects, { id: bandId, bandname: data.bandname.S }];
          }
          //console.log("prevBandObjects:",prevBandObjects);
          return prevBandObjects;
        });
      }
    } catch (error) {
      console.error(error + ',failed to get band name.:' + scoreMetadata.score_name);
    }
  }

  const getBandInfo = async () => {
    const requestOptions = {
      method: 'GET',
    };
    try {
      if (score_id !== undefined) {
        const API_PREFIX = process.env.REACT_APP_API_PREFIX;
        const response_idList = await fetch(`${API_PREFIX}/api/performed/score/${score_id}/bands`, requestOptions);
        const data_idList = await response_idList.json();
        //console.log("band list data:", data_idList);
        setBandNameAndId([]);
        data_idList.map((bandId) => {
          getBandName(bandId);
        });
      }
    } catch (error) {
      console.error(error + ',failed to get band id.:' + scoreMetadata.score_name);
    }
  }
  //ブックマーク数取得
  const [bookmarkNum, setBookmarkNum] = useState(0);
  const getBookmarkNum = async () => {
    const requestOptions = {
      method: 'GET',
    };
    try {
      const API_PREFIX = process.env.REACT_APP_API_PREFIX;
      const response = await fetch(`${API_PREFIX}/api/score/${score_id}/bookmarks/count`, requestOptions);
      const data = await response.json();
      //console.log("bookmark info:", data);
      setBookmarkNum(data.bookmarksCount);
    } catch (error) {
      console.error(error, ",failed to get bookmark num from id:", score_id);
    }
  }
  //タグ取得
  const [tagStrList, setTagStrList] = useState([]);
  const getTagInfo = async (tagId) => {
    const requestOptions = {
      method: 'GET',
    };
    try {
      const API_PREFIX = process.env.REACT_APP_API_PREFIX;
      const response = await fetch(`${API_PREFIX}/api/tags/${tagId}`, requestOptions);
      const data = await response.json();
      //console.log("tag info:", data);
      // 重複チェック
      setTagStrList((prevTagStrList) => {
        if (!prevTagStrList.some(tag => tag.content === data.content)) {
          return [...prevTagStrList, data];
        }
        return prevTagStrList;
      });
      //setTagStrList((tags) => [...tags, data]);
    } catch (error) {
      console.error(error, ",failed to get tag from id:", score_id);
    }
  }
  const getTag = async () => {
    const requestOptions = {
      method: 'GET',
    };
    try {
      const API_PREFIX = process.env.REACT_APP_API_PREFIX;
      const response = await fetch(`${API_PREFIX}/api/score/${score_id}/tags`, requestOptions);
      const data = await response.json();
      setTagStrList([]);//初期化
      //console.log("tag id:", data);
      data.map((tagId) => {
        getTagInfo(tagId);
      })
    } catch (error) {
      console.error(error, ",failed to get tag infomation:", score_id);
    }
  }
  //表示用PDF取得
  const [pdfUrl, setPdfUrf] = useState('');
  const getPdfUrl = async () => {
    const requestOptions = {
      method: 'GET',
    };
    try {
      const API_PREFIX = process.env.REACT_APP_API_PREFIX;
      const response = await fetch(`${API_PREFIX}/api/score/pdf/${score_id}`, requestOptions);
      if(response.status == 500){
        throw new Error();
      }
      const data = await response.text();
      //console.log("pdf link:", data);
      setPdfUrf(data);
    } catch (error) {
      console.error(error, ",failed to get pdf link:", score_id);
      navigate('/not_found');
    }
  }
  //DL用PDF取得
  const [dlPdfUrl, setDlPdfUrf] = useState('');
  const getDlPdfUrl = async () => {
    const requestOptions = {
      method: 'GET',
    };
    try {
      const API_PREFIX = process.env.REACT_APP_API_PREFIX;
      const response = await fetch(`${API_PREFIX}/api/score/pdfdl/${score_id}`, requestOptions);
      const data = await response.text();
      //console.log("DLpdf link:", data);
      setDlPdfUrf(data);
    } catch (error) {
      console.error(error, ",failed to get DLpdf link:", score_id);
    }
  }
  //.mscz取得
  const [msczUrl, setMsczUrf] = useState('');
  const getMsczUrl = async () => {
    const requestOptions = {
      method: 'GET',
    };
    try {
      const API_PREFIX = process.env.REACT_APP_API_PREFIX;
      const response = await fetch(`${API_PREFIX}/api/score/mscz/${score_id}`, requestOptions);
      const data = await response.text();
      //console.log("mscz link:", data);
      setMsczUrf(data);
    } catch (error) {
      console.error(error, ",failed to get mscz link:", score_id);
    }
  }
  //.mid取得
  const [midiUrl, setMidiUrf] = useState('');
  const getMidiUrl = async () => {
    const requestOptions = {
      method: 'GET',
    };
    try {
      const API_PREFIX = process.env.REACT_APP_API_PREFIX;
      const response = await fetch(`${API_PREFIX}/api/score/midi/${score_id}`, requestOptions);
      const data = await response.text();
      //console.log("midi link:", data);
      setMidiUrf(data);
    } catch (error) {
      console.error(error, ",failed to get midi link:", score_id);
    }
  }
  //コメント取得
  const [comments, setComments] = useState([]);
  const [commentNum, setCommentNum] = useState(0);
  const getCommentInfo = async (commentId) => {
    const requestOptions = {
      method: 'GET',
    };
    try {
      const API_PREFIX = process.env.REACT_APP_API_PREFIX;
      const response = await fetch(`${API_PREFIX}/api/comments/${commentId}`, requestOptions);
      const data = await response.json();
      //名前取得
      const user_id = data.posted_user_id;
      const response_name = await fetch(`${API_PREFIX}/api/user/${user_id}`, requestOptions);
      const data_name = await response_name.json();
      data.posted_user_name = data_name.nickname.S;
      //console.log("comment writer:", data_name);
      //console.log("comment info:", data);
      setComments((comments) => [...comments, data]);

    } catch (error) {
      console.error(error, ",failed to get comment from id:", score_id);
    }
  }
  const getComment = async () => {
    const requestOptions = {
      method: 'GET',
    };
    try {
      const API_PREFIX = process.env.REACT_APP_API_PREFIX;
      const response = await fetch(`${API_PREFIX}/api/score/${score_id}/comments`, requestOptions);
      const data = await response.json();
      //console.log("comment id:", data);
      setComments([]);//初期化
      setCommentNum(data.length);
      data.map((commentId) => {
        getCommentInfo(commentId);
      })
    } catch (error) {
      console.error(error, ",failed to get comment infomation:", score_id);
    }
  }

  //コメント入力フォーム
  const [commentInputValue, setCommentInputValue] = useState('');
  const handleCommentInputChange = (e) => {
    setCommentInputValue(e.target.value);
  };
  //コメント登録
  /*
  const console.errorcomment = () => {
    //console.log('コメント内容', commentInputValue);
    setCommentInputValue('');
  }
  */
  const [newComment, setNewComment] = useState('');
  const postComment = async () => {
    try {
      if (commentInputValue !== '') {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ content: commentInputValue })
        };
        const response = await fetch(`${API_PREFIX}/api/comments/post/${myUserId}/score/${score_id}`, requestOptions);
        if (!response.ok) {
          throw new Error(`コメント ${commentInputValue} の送信に失敗しました: ${response.statusText}`);
        }
        //console.log('コメント内容', commentInputValue);
        setNewComment(commentInputValue);
        setCommentInputValue('');
      }
    } catch (error) {
      console.error('コメント送信中のエラー:', error);
    }
  }
  //読み込み時にバージョンを変数に設定
  useEffect(() => {
    fetchUserData();
    getVersions();
    setMyVersionId(score_id.split("_")[1]);
  }, []);


  useEffect(() => {
    isCanbeEditedScore(score_id);
  }, [myUserId]);

  //バージョンが変更されるごとに再読み込み
  useEffect(() => {
    getScoreInfo();
    getArrangerInfo();
    getBandInfo();
    getTag();
    getPdfUrl();
    getDlPdfUrl();
    getMsczUrl();
    getMidiUrl();
  }, [myVersionId]);

  useEffect(() => {
    isWatch(myUserId);
  },[myVersionId, myUserId]);

  useEffect(() => {
    getBandInfo();
  }, [isZentaikyoku]);

  //コメントが更新されたら再描画
  useEffect(() => {
    getComment();
  }, [newComment])

  //ブックマーク
  const [isBookmarked, setIsBookmarked] = useState(false);
  //ブックマーク登録
  const Bookmark = async () => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      };
      const response = await fetch(`${API_PREFIX}/api/bookmark/${myUserId}/score/${score_id.split("_")[0] + "_" + myVersionId}`, requestOptions);

      if (response.ok) {
        setIsBookmarked(true);
      } else {
        throw new Error(`ブックマークの登録に失敗しました: ${response.statusText}`);
      }
    } catch (error) {
      console.error('ブックマーク登録中のエラー:', error);
    }
  }
  //ブックマーク解除
  const UnBookmark = async () => {
    try {
      const requestOptions = {
        method: 'DELETE'
      };
      const response = await fetch(`${API_PREFIX}/api/bookmark/${myUserId}/score/${score_id.split("_")[0] + "_" + myVersionId}`, requestOptions);
      if (response.ok) {
        setIsBookmarked(false);
      } else {
        throw new Error(`ブックマークの解除に失敗しました: ${response.statusText}`);
      }
    } catch (error) {
      console.error('ブックマーク解除中のエラー:', error);
    }
  }
  //ブックマークリスト取得
  const [bookmarkscores, setBookmarkscores] = useState([]);
  useEffect(() => {
    //console.log("START BOOKMARK!");
    const BookmarkList = async () => {
      const requestOptions = {
        method: 'GET',
      };
      try {
        const response = await fetch(`${API_PREFIX}/api/user/${myUserId}/bookmarks`, requestOptions);
        if (response.status === 200) {
          const data = await response.json();
          const bookmarksList = data.bookmarks;
          if (bookmarksList.length > 0) {
            setBookmarkscores(bookmarksList);
            if (bookmarksList.includes(score_id)) {
              setIsBookmarked(true);
            } else {
              setIsBookmarked(false);
            }
          } else {
            setBookmarkscores([]);
          }


          //console.log("bookmark data:", data);
        } else {
          console.error('ブックマーク情報が取得できませんでした');
        }
      } catch (error) {
        console.error('ブックマーク情報取得中のエラー:', error);
      }
    }
    BookmarkList();
  }, [myUserId, myVersionId]);

  useEffect(() => {
    getBookmarkNum();
  }, [bookmarkscores, isBookmarked]);

  //改行用
  const MultiLineBody = ({ body }) => {
    try {
      const texts = body.split('\n').map((item, index) => {
        return (
          <React.Fragment key={index}>
            {item}
            <br />
          </React.Fragment>
        );
      });
      return <div>{texts}</div>;
    } catch (error) {
      return <div>{body}</div>;
    }
  };

  //プルダウン用
  const [selectedValue, setSelectedValue] = useState({ label: '1.0', value: '1.0' });
  // プルダウンの選択肢
  const dropDownOptions = [
    { label: '1.0', value: '1.0' },
    { label: '歌詞ミス修正', value: '2.0' },
    { label: 'フル尺', value: '3.0' }
  ];
  // プルダウンの値が変更された時の処理
  const handleDropDownChange = (value) => {
    setSelectedValue(value);
  };


  //const width = useWindowWidth();

  /*
  const scale = width >= 1393 ? 0.9:
                width >= 1183 ? width/1700:
                width >= 900 ? 0.7:
                0.5
  */


  function onDocumentLoadSuccess({ numPages, pdf }) {
    // ドキュメントが読み込まれたときに、ページのスケールを更新
    //console.log("loaded");
  }

  function editButtonOnClick({ }) {
    //console.log("edit button clicked");
  }

  //タグがクリックされたときの挙動
  const tagClicked = async (tag_obj) => {
    // タグIDを含む楽譜ID取得
    try {
      const fetch_s_tag = await fetch(`${API_PREFIX}/api/tag/${tag_obj["tagId"]}/scores`, { method: 'GET' });
      const result_ids = await fetch_s_tag.json();
      navigate('/search', { state: { intersectionArray: result_ids }, replace: true });
    } catch (error) {
      console.error('There was an error!', error);
    }
  };
  //アレンジャーがクリックされたときの挙動
  const arrangerClicked = async () => {
    // タグIDを含む楽譜ID取得
    try {
      const fetch_s_arn = await fetch(`${API_PREFIX}/api/arranged/user/${arrangerId}/scores`, { method: 'GET' });
      const result_ids = await fetch_s_arn.json();
      navigate('/search', { state: { intersectionArray: result_ids }, replace: true });
    } catch (error) {
      console.error('There was an error!', error);
    }
  };
  //バンドがクリックされたときの挙動
  const bandClicked = async (band_id) => {
    // タグIDを含む楽譜ID取得
    try {
      const fetch_s_pfm = await fetch(`${API_PREFIX}/api/performed/band/${band_id}/scores`, { method: 'GET' });
      const result_ids = await fetch_s_pfm.json();
      navigate('/search', { state: { intersectionArray: result_ids }, replace: true });
    } catch (error) {
      console.error('There was an error!', error);
    }
  };



  //<YouTube videoId={youtube_link} className={yt_style.iframe} containerClassName={yt_style.youtube} opts={yt_opts}/>
  //<iframe src={pdfUrl} frameborder="0"></iframe>
  //<YouTube videoId={youtube_link} className={yt_style.iframe} containerClassName={yt_style.youtube} opts={yt_opts}/>

  /*
  <a href={pdfUrl} target="_blank" style={{ textDecoration: 'none' }}>
              <button>PDFを開く</button>
            </a>
  */
  //const pdfurl = 'http://localhost:3001/static/スノーマジックファンタジー3.pdf';
  const handleEditClick = () => {
    navigate(`/score_edit/${score_id}`);
  };

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isDeleteSuccessful, setIsDeleteSuccessful] = useState(false);
  // モーダルの開閉に伴うスクロールの制御
  useEffect(() => {
    if (isConfirmModalOpen) {
      // モーダルが開いている間、背景のスクロールを無効にする
      document.body.style.overflow = 'hidden';
    } else {
      // モーダルが閉じたときにスクロールを再び有効にする
      document.body.style.overflow = 'auto';
    }

    // クリーンアップ関数でスクロールを再度有効に
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isConfirmModalOpen]);
  //削除ボタンが押されたとき

  const handleDeleteClick = () => {
    setIsConfirmModalOpen(true);
  };
  //削除しますか？→OK
  const handleDeleteOkClick = async () => {
    //削除
    try {
      const response = await fetch(`${API_PREFIX}/api/score/${score_id.split("_")[0] + "_" + myVersionId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        //console.log("Score successfully deleted.");
        setIsDeleteSuccessful(true); // 削除成功
        // 必要に応じて、削除後の追加処理をここに追加
      } else {
        console.error("Failed to delete score.");
        // エラーハンドリングをここに追加
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // エラーハンドリングをここに追加
    }
  };
  //削除しますか？→NG
  const handleDeleteNgClick = (event) => {
    setIsConfirmModalOpen(false);
  };
  //削除しますか？→OK→完了しました
  const handleDeleteOkEndClick = (event) => {
    navigate('/home');
  };

  //タグ文字数順ソート用
  const getStringLength = (str) => {
    return [...str].reduce((acc, char) => {
      const code = char.charCodeAt(0);
      // Consider a character as full-width if its code is greater than 255
      return acc + (code > 255 ? 2 : 1);
    }, 0);
  };

  return (
    <div>
      <CommonHeader />

      <main className={styles.main}>
        {/*<p>scoreid : {score_id}</p>
        <p>userid : {myUserId}</p>*/}



        <div className={styles.songInfo}>
          <div className={styles.songInfoTop}>
            {myVersionId ? (<div className={styles.shareLine}><LineShare url={`${baseUrl}${score_id.split('_')[0]}_${myVersionId}`} msg={artistName ? `${scoreName} / ${artistName}` : scoreName} /></div>) : (<p>LINE共有ボタン作成中…</p>)}
            <div className={styles.title}>{scoreName}</div>
            <div className={styles.myBookmark}>
              {isBookmarked ? <button className={styles.bookmark} onClick={UnBookmark}><IconContext.Provider value={{ size: '40px' }}><FaBookmark /></IconContext.Provider></button>
                : <button className={styles.bookmark} onClick={Bookmark}><IconContext.Provider value={{ size: '40px' }}><FaRegBookmark /></IconContext.Provider></button>}
            </div>
          </div>
          <div className={styles.artist}>{artistName}</div>
        </div>
        <div className={styles.statusContainerWrapper}>
          <div className={styles.statusContainer}>
            <div className={styles.commentWrapper}>
              <div className={styles.commentIconWrapper}>
                <div className={styles.commentIcon}><img className={styles.icon_accesibility} src={accesibilityImages[accesibility]} /></div>
              </div>
              <div className={styles.commentText}>{accesibility_arr[accesibility]}</div>
            </div>
            <div className={styles.commentWrapper}>
              <div className={styles.commentStatus}>
                <div className={styles.commentIconWrapper}>
                  <div className={styles.commentNum}>{commentNum}</div>
                  <div className={styles.commentIcon}><img src={icon_comment} className={styles.icon_comment} alt="icon-comment" /></div>
                </div>
              </div>
              <div className={styles.commentText}>コメント</div>
            </div>
            <div className={styles.commentWrapper}> {/*制御構造はコメントと同様なのでスタイルも使いまわし */}
              <div className={styles.comment}>
                <div className={styles.commentIconWrapper}>
                  <div className={styles.bookmarkNum}>{bookmarkNum}</div>
                  <div className={styles.commentIcon}><img src={icon_bookmark} className={styles.icon_bookmark} alt="bookmark-comment" /></div>
                </div>
              </div>
              <div className={styles.commentText}>ブックマーク</div>
            </div>
          </div>
        </div>

        <div className={styles.versionAndButton}>
          <div className={styles.version}>
            <DropDown
              options={versionDropDownOptions}
              onChange={handleVersionDropDownChange}
              initSelectedValue={myVersionId}
            />
          </div>
          <div className={styles.topButtons}>
            {editpolicy ?
              <div className={`${styles.editButton} ${styles.ableButton}`}> 
                <img src={icon_edit} onClick={handleEditClick}/> 
              </div> :
              <div className={styles.editButton}>
                <img src={icon_cantedit}/>
              </div>
            }
            {editpolicy ? 
              <div className={`${styles.deleteButton} ${styles.ableButton}`}>
                <img src={icon_delete} onClick={handleDeleteClick}/>
              </div> :
              <div className={styles.deleteButton}>
                <img src={icon_cantdelete}/>
              </div>
            }
          </div>
        </div>


        <div className={styles.container}>
          <div className={styles.dataArea}>
            <div className={styles.SamuneContainerWrapper}>
              <div className={styles.SamuneContainer}>
                <a href={pdfUrl} target="_blank" style={{ textDecoration: 'none' }}>
                  <div ref={pdfContainer} className={styles.fixedSizeDiv}>
                    <Document file={pdfUrl} //file={pdfUrl}
                      onLoadSuccess={onDocumentLoadSuccess}>
                      <Page pageNumber={1} className={styles.pdfPage} scale={scale} />
                    </Document>
                  </div>
                </a>
              </div>
            </div>
            <div className={styles.DlButtonContainerWrapper}>
              <div className={styles.DlButtonContainer}>

                <div className={styles.DlButton}>
                  <a href={msczUrl}>
                    <Button
                      type='primary2'
                      child='.mscz'
                      onClick={editButtonOnClick}
                    />
                  </a>
                </div>
                <div className={styles.DlButton}>
                  <a href={midiUrl}>
                    <Button
                      type='primary2'
                      child='.mid'
                      onClick={editButtonOnClick}
                    />
                  </a>
                </div>
                <div className={styles.DlButton}>
                  <a href={dlPdfUrl}>
                    <Button
                      type='primary2'
                      child='.pdf'
                      onClick={editButtonOnClick}
                    />
                  </a>
                </div>

              </div>
            </div>
          </div>
          <div className={styles.tableArea}>
            <div className={styles.tableContainer}>
              <table className={styles.table}>
                <tbody>
                  <tr>
                    <td className={styles.key} colSpan={2}></td>
                    <td className={styles.value}></td>
                  </tr>
                  <tr>
                    <td className={styles.key1} colSpan={2}>曲尺</td>
                    <td className={styles.value1}>{('00' + Math.floor(duration / 60)).slice(-2)}:{('00' + duration % 60).slice(-2)}</td>
                  </tr>
                  <tr>
                    <td className={styles.key} colSpan={2}>難易度</td>
                    <td className={styles.value} colSpan={2}>{difficulty}({difficulty_arr[difficulty - 1]})</td>
                  </tr>
                  <tr>
                    <td className={styles.key1} colSpan={2}>アレンジャー</td>
                    <td className={styles.value1}>
                      {arrangerName ? <button onClick={() => arrangerClicked()} className={styles.tag}><p className={styles.tagText}>{arrangerName}</p></button>
                      : ('未登録')}
                    </td>
                  </tr>
                  {/*<tr>
                    <td className={styles.key} colSpan={2}>全体曲</td>
                    <td className={styles.value}>{isZentaikyoku ? "〇" : "×"}</td>
                  </tr>*/}
                  <tr>
                    <td className={styles.key} colSpan={2}>リード回し</td>
                    <td className={styles.value}>{isChangeLead ? "あり" : "なし"}</td>
                  </tr>
                  <tr>
                    <td className={styles.key1} colSpan={2}>演奏バンド</td>
                    <td className={styles.value1}>{isZentaikyoku ? "全体曲" :
                      bandNameAndId.length ? (
                        bandNameAndId.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <button onClick={() => bandClicked(item.id)} className={styles.tag}>
                                <p className={styles.tagText}>{item.bandname}</p>
                              </button>
                              <br />
                            </React.Fragment>
                          )
                        })) :
                        ('未登録')}
                    </td>

                    {/**
                    <td className={styles.value}>{bandInfos.length ? (
                      bandInfos.map((item, index) => {
                        return(
                          <React.Fragment key={index}>
                            {item.bandname.S}
                            <br />
                          </React.Fragment>
                      )})) :
                      ('演奏バンドが登録されていません')}
                    </td>
                     */}
                  </tr>
                  <tr>
                    <td className={styles.key} colSpan={2}>テンポ</td>
                    <td className={styles.value}> {tempo === -1 ? tempo_str : `${tempo_str} (${tempo})`}</td>
                  </tr>
                  <tr>
                    <td className={styles.key1} colSpan={2}>男声パート</td>
                    <td className={styles.value1}>{partMale}</td>
                  </tr>
                  <tr>
                    <td className={styles.key} colSpan={2}>女声パート</td>
                    <td className={styles.value}>{partFemale}</td>
                  </tr>
                  <tr>
                    <td className={styles.key1} colSpan={2}>VP</td>
                    <td className={styles.value1}>{need_vp_dict[needVp]}</td>
                  </tr>
                  <tr>
                    <td className={styles.key} colSpan={2}>VP譜</td>
                    <td className={styles.value}>{have_vp_dict[haveVp]}</td>
                  </tr>
                  <tr>
                    <td className={styles.key1} colSpan={2}>説明</td>
                    <td className={styles.value1}> {description ?
                      <MultiLineBody body={description} /> :
                      ('説明文がありません')}
                    </td>
                  </tr>
                  <tr>
                    <td className={styles.key} colSpan={2}>タグ</td>
                    <td className={`${styles.value} ${styles.tag}`} colSpan={2}> {tagStrList.length ?
                      (tagStrList.sort((a,b)=>getStringLength(a.content) - getStringLength(b.content)).map(tag_obj => { return <div key={tag_obj.id} className={styles.tagItem}><button key={tag_obj.id} onClick={() => tagClicked(tag_obj)} className={styles.tagButton}><p className={styles.tagText}>{'#' + tag_obj["content"]}</p></button></div> })) :
                      ('タグが登録されていません')}
                    </td>
                  </tr>
                  {/*
                  <tr>
                    <td className={styles.key} colSpan={2}></td>
                    <td className={styles.value}></td>
                  </tr>*/}
                  {/* 他の行を追加 */}
                </tbody>
              </table>
            </div>
          </div>

        </div>

        {/*YouTubeを複数表示 */}
        <div className={styles.videoGridItem}>
          {youtubeLinks ? (
            <YoutubeContainer ids={youtubeLinks} />
          ) : (
            <div className={styles.novideo}>演奏動画なし</div>
          )}
        </div>

        {voiceRanges ? (
          <div className={styles.voicerangeContainer}>
            <VisualizeVoiceranges
              voiceranges={voiceRanges} />
          </div>
        ) : (
          <div className={styles.voicerangeContainer}></div>
        )}
        <div className={styles.commentEditButton}>
          <div className={styles.commentEdit}>
            <TextInput
              placeholder={"コメントを入力"}
              value={commentInputValue}
              onChange={handleCommentInputChange}
              useAPI={false}
              width="L"
              maxLength={300} 
            />
          </div>
          <div className={styles.commentButton}>
            <Button
              type='primary2'
              child='登録'
              onClick={postComment} />
          </div>
        </div>

        <div className={styles.comment}>
          {comments.sort((a, b) => { return a.createdAt > b.createdAt ? -1 : 1 }).map((comment, index) => (
            <CommentCard key={index} comment={comment} />
          ))}
        </div>

        {isConfirmModalOpen && (
          <div
            className={styles.modalOverlay}
            onClick={(e) => e.stopPropagation()} // 背景クリックを無効化
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 999, // 背景レイヤー
            }}
          >
            <div
              className={styles.deleteModal}
              onClick={(e) => e.stopPropagation()}
              style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                height: 'auto',
                overflow: 'auto',
                zIndex: 1000, // モーダル本体のレイヤー
              }}
            >
              {isDeleteSuccessful ? (
                <>
                  <h1>削除が成功しました。</h1>
                  <Button
                    type='normal'
                    child='トップへ戻る'
                    onClick={handleDeleteOkEndClick} />
                </>
              ) : (
                <>
                  <h1>この楽譜を削除します。よろしいですか？</h1>
                  <div className={styles.buttonGroup}>
                    <Button
                      type='primary3'
                      child='削除する'
                      onClick={handleDeleteOkClick} />
                    <Button
                      type='normal'
                      child='いいえ'
                      onClick={handleDeleteNgClick} />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </main>
    </div>
  );
}

export default PageScoreDetail;