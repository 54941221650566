import React from 'react';
import {BallTriangle} from 'react-loader-spinner'; 
import styles from './Loading.module.css'
//npm i react-loader-spinner
//documentation : https://mhnpd.github.io/react-loader-spinner/docs/intro/

const LoadingInRegistration = ({ onloading, loading_object_name }) => {
  return (
      <div className={styles.Icon}>{(()=>{
        if(onloading){
          return (
            <div className={styles.hatakeContainer}>
              <BallTriangle
                height={20}
                width={20}
                radius={5}
                color="#F39800"
                ariaLabel="ball-triangle-loading"
                wrapperClass=""
                wrapperStyle=""
                visible={true}
              />
              <p>{loading_object_name}をロード中・・・</p>
              
            </div>)
        }
      })()}
      </div>
  );
};

export default LoadingInRegistration;