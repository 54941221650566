import React from 'react';
import stylesBase from './Play00BaseCss.module.css';
import Button from '../Utils/Button/Button';

function buttonClickEvent(){
    console.error('Button clicked!');
  }

function Dev() {
    return(
        <Button
                type = "N/A"
                child="test"
                onClick = {buttonClickEvent}

            />
    );
}

export default Dev;