import React, { useState, useEffect } from 'react';
import styles from './DropDown.module.css'; // CSSモジュールのインポート

const DropDownForUserList = ({ selectKey, defaultValue, useritems, onChange, disabled}) => {

  const [selectedValue, setSelectedValue] = useState("");

  // ページ読み込み時に特定のユーザIDを選択状態にする
  useEffect(() => {
    //console.log("defaultValue changed:",defaultValue);
    setSelectedValue(defaultValue || "");
  }, [defaultValue]);  // defaultValueが変わった時にのみ効果を再実行

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
  };
  return (
    <select
      key={selectKey}
      className={styles.dropDown}
      value={selectedValue}
      onChange={handleChange}
      disabled={disabled}
    >
      <option value="">
        ユーザを選択
      </option>
      {useritems.sort((a,b)=>{
        if(a.ki?.N === b.ki?.N){
          return a.nickname?.S.localeCompare(b.nickname?.S);
        }else{
          return a.ki?.N - b.ki?.N;
        }
      }).map((item) => (
        <option key={item.user_id?.S} value={item.user_id?.S}>
          {item.ki?.N}期_{item.nickname?.S}
        </option>
      ))}
    </select>
  );
};

export default DropDownForUserList;