import React, { useState, useEffect } from 'react';
import styles from './DropDown.module.css'; // CSSモジュールのインポート

const DropDown = ({ options, onChange, initSelectedValue }) => {
  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    // initSelectedValueが変更された場合にselectedValueを更新
    setSelectedValue(initSelectedValue || "");
  }, [initSelectedValue]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <select
      className={styles.dropDown}
      value={selectedValue}
      onChange={handleChange}
    >
      {!initSelectedValue && <option value="">選択してください</option>}
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default DropDown;