import React from 'react';
import styles from './pianokeyboard.module.css';

const NOTE_NAMES = [
'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'
];

const FLAT_TO_SHARP = {
    'Db': 'C#',
    'Eb': 'D#',
    'Gb': 'F#',
    'Ab': 'G#',
    'Bb': 'A#',
    'D♭': 'C#',
    'E♭': 'D#',
    'G♭': 'F#',
    'A♭': 'G#',
    'B♭': 'A#'
};


// E#をFに、B#をCに変換する処理を追加
const normalizeNoteName = (noteName) => {
    if (noteName === 'E#') return 'F';
    if (noteName === 'B#') return 'C';
    return noteName;
};

const getNoteName = (midiNumber) => {
    const octave = Math.floor(midiNumber / 12) - 1;
    const note = NOTE_NAMES[midiNumber % 12];
    return `${note}${octave}`;
};

const getNoteMidiNumber = (note) => {
    const noteName = normalizeNoteName(note.slice(0, -1));
    const octave = parseInt(note.slice(-1));
    const sharpNoteName = FLAT_TO_SHARP[noteName] || noteName;
    return (octave + 1) * 12 + NOTE_NAMES.indexOf(sharpNoteName);
};
const PianoKeyboard = ({ startNote, endNote, highlightedRange = { start: "", end: "" } }) => {
const startMidi = getNoteMidiNumber(startNote);
const endMidi = getNoteMidiNumber(endNote);
const highlightedStartMidi = getNoteMidiNumber(highlightedRange.start);
const highlightedEndMidi = getNoteMidiNumber(highlightedRange.end);

const whiteKeys = [];
const blackKeys = [];
let whiteKeyIndex = 0;

for (let i = startMidi; i <= endMidi; i++) {
    const noteName = getNoteName(i);
    const isBlackKey = noteName.includes('#');
    const isHighlighted = i >= highlightedStartMidi && i <= highlightedEndMidi;
    const isMiddleC = noteName === 'C4';

    if (isBlackKey) {
    blackKeys.push(
        <div
        key={i}
        className={`${styles.key} ${styles.blackKey} ${isHighlighted ? styles.highlightedBlack : ''}`}
        style={{ left: `${(whiteKeyIndex - 0.25) * (100 / 31)}%` }} // 注意！定義音域に応じて調整が必要（引数のstartNoteとendNote依存）。現状A1~C6想定
        />
    );
    } else {
    whiteKeys.push(
        <div
        key={i}
        className={`${styles.key} ${styles.whiteKey} ${isHighlighted ? styles.highlightedWhite : ''} ${isMiddleC ? styles.middleC : ''}`}
        style={{ width: `${100 / 7}%` }}
        >
        {isMiddleC && <span className={styles.middleCLabel}>C4</span>}
        </div>
    );
    whiteKeyIndex++;
    }
}

return (
    <div className={styles.keyboard}>
    {whiteKeys}
    {blackKeys}
    </div>
);
};

export default PianoKeyboard;
