import React, { useCallback, useRef, useState } from 'react';
import style from './FileUploader.module.css';

/**
 * ファイルドロップコンポーネント
 */
function FileDropzone({ onChange, multiple }) {
  const [dragging, setDragging] = useState(false)
  const refFileElement = useRef(null)

  /**
   * ドロップ時の処理
   */
  const handleDrop = useCallback(
    async (e) => {
      e.stopPropagation();
      e.preventDefault();

      if (e.dataTransfer.files.length > 0 && refFileElement.current) {
        if (multiple) {
          refFileElement.current.files = e.dataTransfer.files;
        } else {
          const dt = new DataTransfer();
          dt.items.add(e.dataTransfer.files[0]);
          refFileElement.current.files = dt.files;
        }
        onChange(refFileElement.current.files);
      }
      setDragging(false);
    }, 
  [onChange]);

  /**
   * ファイル選択(input type="file"からの選択）
   */
  const handleChange = useCallback(
    async (e) => {
      if (e.target.files) {
        onChange(e.target.files);
      }
    },
  [onChange]);

  /**
   * Dragover or Dragleave
   * @param e 
   */
  const handleDragoverOrLeave = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    setDragging(e.type === "dragover");
  }, []);

  return (
    <div
      className={`${style.dragzone} ${dragging ? style.dragging : ""}`}
      onDragOver={handleDragoverOrLeave}
      onDragLeave={handleDragoverOrLeave}
      onDrop={handleDrop}>
      ここにファイルをドロップ<br />
      または<br />
      <label className={style.buttonWrapper}>
        ファイルを選択してください
      <input 
        ref={refFileElement} 
        onChange={handleChange} 
        type="file" 
        multiple={multiple}
        accept=".pdf,.mscz" // この行を追加
        />
      </label>
    </div>
  )
}

export default FileDropzone;
