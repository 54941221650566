import {
    FacebookShareButton,
    HatenaShareButton,
    LineShareButton,
    TwitterShareButton,
    FacebookIcon,
    HatenaIcon,
    LineIcon,
    TwitterIcon,
} from "react-share";
import LineShareIcon from './line.png';
import styles from './LineShare.module.css';

//const URL = 'https://example.com/';
//const QUOTE = '共有するときのメッセージ';
//https://neko-note.org/react-share/628
const CustomLineShareButton = ({ url, title }) => {
    const handleLineShare = (e) => {
        e.preventDefault();

        const encodedText = encodeURIComponent(title);
        const encodedUrl = encodeURIComponent(url);

        // モバイルデバイス用のLINEアプリスキーム
        const lineAppUrl = `line://msg/text/${encodedText}%0A${encodedUrl}`;

        // PCブラウザ用のLINE共有URL
        const lineBrowserUrl = `https://social-plugins.line.me/lineit/share?url=${encodedUrl}&text=${encodedText}`;

        // モバイルデバイスかどうかを判定
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

        if (isMobile) {
            // モバイルデバイスの場合、まずLINEアプリを開こうとする
            window.location.href = lineAppUrl;

            // 少し遅延を入れてからブラウザ版を開く（アプリが見つからない場合のフォールバック）
            setTimeout(() => {
                window.location.href = lineBrowserUrl;
            }, 500);
        } else {
            // PCの場合は直接ブラウザ版を開く
            window.open(lineBrowserUrl, '_blank');
        }
    };

    return (
        <button onClick={handleLineShare} style={{
            border: 'none',
            background: 'transparent',
            cursor: 'pointer',
            padding: '0'
        }}>
            <div className={styles.iconWrapper}>
                <img className={styles.icon} src={LineShareIcon} />
            </div>
        </button>
    );
};

const LineShare = ({ url, msg }) => {
    return (
        <CustomLineShareButton url={url} title={msg} />
    )
}
export default LineShare;